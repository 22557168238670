import { Form, Text } from 'informed';
import * as React from 'react';

import ErrorConstants from '../../constants/error.constants';
import GeneralConstants from '../../constants/general.constants';
import ProjectFormTextInput from '../project-form-elements/project-form-text-input/project-form-text-input.component';

const ProjectNewClient = ({ handleAddNewClient, handleCreateNewClient, serverError }) => {
	return (
		<div className='add-new-client-overlay'>
			<Form onSubmit={(values) => handleAddNewClient(values)}>
				<div className='local-grid local-grid--col-2'>
					<ProjectFormTextInput
						name='title'
						label={GeneralConstants['client_name_overlay']}
						serverError={
							serverError && serverError.body && serverError.body.title
								? ErrorConstants[serverError.body.title]
								: ''
						}
					/>

					<label className='grid-item'>
						<span className='field-description'>{GeneralConstants['email']}</span>
						<Text
							field='email'
							type='email'
							className={serverError && serverError.body && serverError.body.email ? 'error-field' : ''}
						/>
						{serverError && serverError.body && serverError.body.email ? (
							<span className='error-inline'>{ErrorConstants[serverError.body.email]}</span>
						) : null}
					</label>
				</div>
				<div className='local-grid local-grid--col-2'>
					<ProjectFormTextInput
						name='phone'
						label={GeneralConstants['phone_nr']}
						serverError={
							serverError && serverError.body && serverError.body.phone
								? ErrorConstants[serverError.body.phone]
								: ''
						}
					/>
				</div>
				<div className='btn-container btn-container--center'>
					<button className='btn small light-green right save--dark'>
						{GeneralConstants['add_new_client']}
					</button>
					<button className='btn small close close--small' type='button' onClick={handleCreateNewClient}>
						{GeneralConstants['close_overlay']}
					</button>
				</div>
			</Form>
		</div>
	);
};

export default ProjectNewClient;
