import UserActionTypes from './user.types';

const INITIAL_STATE = {
	currentUser: null,
	error: null,
	userRoles: [],
	activeRole: null
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UserActionTypes.SIGN_IN_SUCCESS:
			return {
				...state,
				currentUser: action.payload,
				error: null
			};
		case UserActionTypes.SIGN_OUT_SUCCESS:
			return {
				...state,
				currentUser: null,
				userRoles: [],
				activeRole: null,
				error: null
			};
		case UserActionTypes.FETCH_USER_ROLES_SUCCESS:
			return {
				...state,
				error: null,
				userRoles: action.payload
			};
		case UserActionTypes.CHANGE_USER_ROLE_SUCCESS:
			return {
				...state,
				error: null,
				activeRole: action.payload
			};
		case UserActionTypes.FETCH_USER_ACTIVE_ROLE_SUCCESS:
			return {
				...state,
				error: null,
				activeRole: action.payload
			};
		case UserActionTypes.SIGN_IN_FAILURE:
		case UserActionTypes.SIGN_OUT_FAILURE:
		case UserActionTypes.FETCH_USER_ROLES_FAILURE:
		case UserActionTypes.FETCH_USER_ACTIVE_ROLE_FAILURE:
		case UserActionTypes.CHANGE_USER_ROLE_FAILURE:
			return {
				...state,
				error: action.payload
			};
		default:
			return state;
	}
};

export default userReducer;
