import { Text } from 'informed';
import * as React from 'react';

import CommentFormDisplay from '../../comments/comment-form-display.component';
import ProjectAddCommentButton from '../project-add-comment-button/project-add-comment-button.component';

const ButtonAndInputContainer = ({
	handleShowComments,
	targetId,
	group,
	comments,
	label,
	inputName,
	inputNameInitialValue
}) => (
	<>
		<fieldset className='grid-item button-and-input-container'>
			<ProjectAddCommentButton
				handleShowComments={handleShowComments}
				extraClass
				targetId={targetId}
				group={group}
				comments={comments}
				label={label}
			/>
			<label className='button-and-input-wrapper'>
				<span className='input-description'>{label}</span>
				<Text type='text' field={inputName} initialValue={inputNameInitialValue} allowEmptyString />
			</label>
		</fieldset>
		<CommentFormDisplay targetId={targetId} />
	</>
);

export default ButtonAndInputContainer;
