import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
	changeUserRoleFailure,
	changeUserRoleSuccess,
	fetchUserActiveRoleFailure,
	fetchUserActiveRoleSuccess,
	fetchUserRoleFailure,
	fetchUserRoleSuccess,
	signInFailure,
	signInSuccess,
	signOutFailure,
	signOutSuccess
} from './user.actions';
import UserActionTypes from './user.types';

const api = window.api;

export function* onUserSignInStart() {
	yield takeLatest(UserActionTypes.SIGN_IN_START, signInWithUser);
}

export function* signInWithUser({ payload: { user, password } }) {
	try {
		const userTokenAndData = yield api.users.login(user, password);
		if (userTokenAndData) {
			const { user } = userTokenAndData;
			yield put(signInSuccess(user));
		}
	} catch (error) {
		yield put(signInFailure(error));
	}
}

export function* onSignOutStart() {
	yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* signOut() {
	try {
		const { status } = yield api.users.logout();
		if (!status) return;
		yield put(signOutSuccess());
	} catch (error) {
		yield put(signOutFailure(error));
	}
}

export function* onCheckUserSession() {
	yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* isUserAuthenticated() {
	try {
		const response = yield api.users.getStorageUser();
		if (!response) return;
		const { user } = response;
		yield put(signInSuccess(user));
	} catch (error) {
		yield put(signInFailure(error));
	}
}

export function* onUserRolesStart() {
	yield takeLatest(UserActionTypes.FETCH_USER_ROLES_START, fetchUserRoles);
}

export function* fetchUserRoles({ payload: { id } }) {
	try {
		const response = yield api.users.get(`${id}/availableroles`);
		yield put(fetchUserRoleSuccess(response));
	} catch (error) {
		yield put(fetchUserRoleFailure(error));
	}
}

export function* onFetchUserActiveRoleStart() {
	yield takeLatest(UserActionTypes.FETCH_USER_ACTIVE_ROLE_START, fetchUserActiveRole);
}

export function* fetchUserActiveRole({ payload: { id } }) {
	try {
		const response = yield api.users.get(`${id}/currentrole`);
		yield put(fetchUserActiveRoleSuccess(response));
	} catch (error) {
		yield put(fetchUserActiveRoleFailure(error));
	}
}

export function* onChangeUserRoleStart() {
	yield takeLatest(UserActionTypes.CHANGE_USER_ROLE_START, changeUserRole);
}

export function* changeUserRole({ payload: { id, label } }) {
	try {
		const response = yield api.users.put(`${id}/currentrole`, { label });
		yield put(changeUserRoleSuccess(response));
	} catch (error) {
		yield put(changeUserRoleFailure(error));
	}
}

export function* userSagas() {
	yield all([
		call(onUserSignInStart),
		call(onSignOutStart),
		call(onCheckUserSession),
		call(onUserRolesStart),
		call(onChangeUserRoleStart),
		call(onFetchUserActiveRoleStart)
	]);
}
