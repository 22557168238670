import { Document } from '@react-pdf/renderer';
import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';
import {
	CheckText,
	FormDataContainer,
	FormDataContainerWrapper,
	FormDataContent,
	FormDataContentContainer,
	FormDataTitle,
	FormDataTitleContainer,
	ImgContainer,
	PageContainer,
	PromoImg,
	RadioChecked,
	RadioContainer,
	RadioNotChecked,
	RadioWrapper
} from './styles';

const PdfKnapphusWardrobe = ({ orderType, activeFormData }) => {
	const { knapphus } = orderType;

	return (
		<Document>
			<PageContainer size='A4'>
				<ImgContainer>
					<PromoImg source='../logo.png' />
				</ImgContainer>
				<FormDataContainer>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{GeneralConstants['project_title']}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{activeFormData.title}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.order_number.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.order_number.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.wardrobe_model.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.wardrobe_model.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.color_code.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.color_code.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.height_self.label}</FormDataTitle>
						</FormDataTitleContainer>
						<RadioWrapper>
							{knapphus.height_self.standard_type.options.map((item) => {
								if (item.sys_name === knapphus.height_self.standard_type.value) {
									return (
										<RadioContainer>
											<RadioChecked />
											<CheckText>{item.label}</CheckText>
										</RadioContainer>
									);
								} else {
									return (
										<RadioContainer>
											<RadioNotChecked />
											<CheckText>{item.label}</CheckText>
										</RadioContainer>
									);
								}
							})}
						</RadioWrapper>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.height_self.special.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.height_self.special.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.pole_meter.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.pole_meter.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.roof_height.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.roof_height.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.roof_angle.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.roof_angle.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.basket_section.label}</FormDataTitle>
						</FormDataTitleContainer>
						<RadioWrapper>
							{knapphus.basket_section.options.map((item) => {
								if (item.sys_name === knapphus.basket_section.value) {
									return (
										<RadioContainer>
											<RadioChecked />
											<CheckText>{item.label}</CheckText>
										</RadioContainer>
									);
								} else {
									return (
										<RadioContainer>
											<RadioNotChecked />
											<CheckText>{item.label}</CheckText>
										</RadioContainer>
									);
								}
							})}
						</RadioWrapper>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.comment.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.comment.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
				</FormDataContainer>
			</PageContainer>
		</Document>
	);
};

export default PdfKnapphusWardrobe;
