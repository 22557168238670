import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';
import StatusConstants from '../../constants/status.constants';
import ErrorMessage from '../error/error.component';
import Spinner from '../spinner/spinner.component';
import SuccessMessage from '../success/success.component';

const api = window.api;

class ProjectStatus extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.project.status,
			id: this.props.project.id,
			user_ids_assembly: this.props.project.user_ids_assembly,
			user_ids_production: this.props.project.user_ids_production,
			user_ids_project_manager: this.props.project.user_ids_project_manager,
			user_ids_transport: this.props.project.user_ids_transport,
			isFetching: false,
			error: null,
			successMessage: false,
			errorMessage: false
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.setState({ value: event.target.value }, this.updateProjectStatus);
	}

	updateProjectStatus = () => {
		this.setState({ isFetching: true });
		api.projects
			.put(`status`, {
				id: this.state.id,
				status: `${this.state.value}`
			})
			.then((res) => {
				if (res.status === 'invoiced') {
					this.props.handleProjectStatusOrder();
				}
				return this.setState({ successMessage: true, isFetching: false });
			})
			.catch((error) => this.handleSetServerError(error));
		setTimeout(() => this.setState({ successMessage: false }), 1000);
	};

	handleSetServerError = (error) => {
		this.setState((prevState) => ({
			...prevState,
			isFetching: false,
			error,
			successMessage: false,
			errorMessage: true
		}));
		setTimeout(() => this.setState({ errorMessage: false }), 3000);
	};

	render() {
		const { isFetching, successMessage, errorMessage } = this.state;

		return (
			<>
				{isFetching && <Spinner />}
				{successMessage && <SuccessMessage message={GeneralConstants['success']} />}
				{errorMessage && (
					<ErrorMessage
						errorID={
							this?.state?.error?.id
								? this.state.error.id
								: this?.state?.error?.status
								? this.state.error.status
								: ''
						}
					/>
				)}
				<form className='status-container'>
					<select
						name='status'
						className={`status-select status-select--${this.state.value}`}
						value={this.state.value}
						disabled={this.props.disabled}
						onChange={this.handleChange}
					>
						<option value='draft'>{StatusConstants['draft']}</option>
						<option value='order'>{StatusConstants['order']}</option>
						<option value='confirmed'>{StatusConstants['confirmed']}</option>
						<option value='ready_for_rest'>{StatusConstants['ready_for_rest']}</option>
						<option value='ready_for_assembly'>{StatusConstants['ready_for_assembly']}</option>
						<option value='delivered'>{StatusConstants['delivered']}</option>
						<option value='assembled'>{StatusConstants['assembled']}</option>
						<option value='complaints'>{StatusConstants['complaints']}</option>
						<option value='ready'>{StatusConstants['ready']}</option>
						<option value='ready_to_invoice'>{StatusConstants['ready_to_invoice']}</option>

						{this.props.canSetArchiveMode ? (
							<>
								<option value='invoiced'>{StatusConstants['invoiced']}</option>
								<option value='archived'>{StatusConstants['archived']}</option>
							</>
						) : (
							<>
								<option value='invoiced' disabled>
									{StatusConstants['invoiced']}
								</option>
								<option value='archived' disabled>
									{StatusConstants['archived']}
								</option>
							</>
						)}
					</select>
				</form>
			</>
		);
	}
}

export default ProjectStatus;
