import * as React from 'react';

import ProjectList from '../../components/project-list/project-list.component';
import ProjectNavigation from '../../components/project-navigation/project-navigation.component';

const activeProjectTypes = [
	'draft',
	'order',
	'confirmed',
	'ready_for_rest',
	'ready_for_assembly',
	'delivered',
	'assembled',
	'complaints',
	'ready',
	'ready_to_invoice',
	'invoiced'
];

const ProjectActive = (props) => (
	<>
		<ProjectNavigation />
		<ProjectList {...props} projectTypes={activeProjectTypes} />
	</>
);

export default ProjectActive;
