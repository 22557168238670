import { Text } from 'informed';
import * as React from 'react';

import ErrorConstants from '../../../constants/error.constants';

const ProjectFormEmailInput = ({ activeFormData, label, name, serverError, disabledField }) => (
	<label className='grid-item' key='key'>
		<span className='field-description'>{label}</span>
		{disabledField ? (
			<Text
				field={name}
				type='email'
				initialValue={activeFormData}
				disabled
				className={`${serverError ? 'error-field' : ''}`}
			/>
		) : (
			<Text
				field={name}
				type='email'
				initialValue={activeFormData}
				className={`${serverError ? 'error-field' : ''}`}
			/>
		)}

		{serverError && <span className='error-inline'>{ErrorConstants[serverError]}</span>}
	</label>
);

export default ProjectFormEmailInput;
