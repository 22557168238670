import { Checkbox, Option, Radio, RadioGroup, Select, Text, TextArea } from 'informed';
import * as React from 'react';

import GeneralConstants from '../../../constants/general.constants';
import CommentFormDisplay from '../../comments/comment-form-display.component';
import ButtonAndInputContainer from '../../project-form-elements/button-and-input-container/button-and-input-container.component';
import ButtonAndOptionsContainer from '../../project-form-elements/button-and-options-container/button-and-options-container.component';
import DropTownHeaderCheckBox from '../../project-form-elements/dropdown-header-checkbox/dropdown-header-checkbox.component';
import ProjectAddCommentButton from '../../project-form-elements/project-add-comment-button/project-add-comment-button.component';
import ProjectFormTextInput from '../../project-form-elements/project-form-text-input/project-form-text-input.component';
import ProjectRadioFreeContainer from '../../project-form-elements/project-radio-free-container/project-radio-free-container.component';

const ProjectFormWardrobe = (props) => {
	const { is_workdrawing_sent, doorhandle, order_type } = props.formData;

	const { handleShowComments, comments, activeFormData, formState } = props;

	const [hide_order_type, setOrderTypeMode] = React.useState('false');

	const [hide_doorhandle, setDoorhandleMode] = React.useState('false');

	const [hide_is_workdrawing_sent, setIsWorkdrawingSentMode] = React.useState('false');

	React.useEffect(() => {
		const isOrderType = localStorage.getItem('hide_order_type') === 'true';
		setOrderTypeMode(isOrderType);
		const isDoorhandle = localStorage.getItem('hide_doorhandle') === 'true';
		setDoorhandleMode(isDoorhandle);
		const isWorkdrawingSent = localStorage.getItem('hide_is_workdrawing_sent') === 'true';
		setIsWorkdrawingSentMode(isWorkdrawingSent);
	}, [hide_order_type, hide_doorhandle, hide_is_workdrawing_sent]);

	const toggle = (ev, section) => {
		ev.preventDefault();
		if (section == 'order_type') {
			const isOrderType = !hide_order_type;
			localStorage.setItem('hide_order_type', JSON.stringify(isOrderType));
			setOrderTypeMode(isOrderType);
		} else if (section == 'doorhandle') {
			const isDoorhandle = !hide_doorhandle;
			localStorage.setItem('hide_doorhandle', JSON.stringify(isDoorhandle));
			setDoorhandleMode(isDoorhandle);
		} else if (section == 'is_workdrawing_sent') {
			const isWorkdrawingSent = !hide_is_workdrawing_sent;
			localStorage.setItem('hide_is_workdrawing_sent', JSON.stringify(isWorkdrawingSent));
			setIsWorkdrawingSentMode(isWorkdrawingSent);
		}
	};

	const [order_type_val, setOrderType] = React.useState(order_type.brands.value);

	const todayDate = new Date().toISOString().substring(0, 10);

	return (
		<>
			<div className='dropdown-container'>
				<div
					className={`dropdown-header-container dropdown-container__header ${
						hide_order_type ? 'dropdown-container__header--closed' : null
					} `}
				>
					<a href='' className='dropdown-container-trigger' onClick={(ev) => toggle(ev, 'order_type')}>
						{order_type.label}
					</a>
					<div className='dropdown-status-container'>
						<DropTownHeaderCheckBox
							name='order_type[dates][ordered][value]'
							label={order_type.dates.ordered.label}
							activeFormData={order_type.dates.ordered.value === true}
						/>
						<DropTownHeaderCheckBox
							name='order_type[dates][approved][value]'
							label={order_type.dates.approved.label}
							activeFormData={order_type.dates.approved.value === true}
						/>
						<DropTownHeaderCheckBox
							name='order_type[dates][delivered][value]'
							label={order_type.dates.delivered.label}
							dateName='order_type[dates][delivered_date][value]'
							activeFormData={order_type.dates.delivered.value === true}
							activeFormDateData={order_type.dates.delivered_date.value ?? todayDate}
							todayDate={todayDate}
						/>
						<ProjectAddCommentButton
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='order_type.header'
							group='order_type'
							comments={comments}
							label={order_type.label}
						/>
					</div>
				</div>
				<div
					className={`local-grid local-grid--col-4 dropdown-content-initial-options ${
						hide_order_type ? 'local-grid--hidden' : null
					}`}
				>
					<CommentFormDisplay targetId='order_type.header' />
					<div>
						<RadioGroup
							field='order_type[brands][value]'
							initialValue={order_type.brands.value}
							onValueChange={(ev) => setOrderType(ev)}
						>
							{order_type &&
								order_type.brands.dyn_data.map((brand) => {
									if (brand.sys_name != 'andre' && brand.sys_name != '__empty__') {
										return (
											<ProjectRadioFreeContainer
												label={brand.name}
												value={brand.sys_name}
												key={brand.sys_name}
											/>
										);
									} else if (brand.sys_name == 'andre') {
										return (
											<fieldset className='free-grid-item' key={brand.sys_name}>
												<label>
													<Radio
														className='regular'
														field={brand.sys_name}
														value={brand.sys_name}
													/>
													<span>{brand.name}</span>
												</label>
												<label>
													<Text
														type='text'
														field='order_type[branding_text][value]'
														initialValue={order_type.branding_text.value}
														allowEmptyString={true}
														disabled={
															!(formState.values.order_type?.brands?.value === 'andre')
														}
													/>
												</label>
											</fieldset>
										);
									}
								})}
						</RadioGroup>
					</div>
				</div>
				<div
					className={`dropdown-content-options ${
						(order_type_val !== 'knapphus' && order_type_val !== 'tigne') || hide_order_type
							? 'dropdown-content-options--hidden'
							: ''
					}`}
				>
					<div className='local-grid local-grid--col-2'>
						<ProjectFormTextInput
							name='order_type[knapphus][order_number][value]'
							type='text'
							label={order_type.knapphus.order_number.label}
							activeFormData={order_type.knapphus.order_number.value}
						/>
						<fieldset className='grid-item button-and-input-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.knapphus.order_number'
								group='order_type'
								comments={comments}
								label={order_type.knapphus.order_number.label}
							/>
							<label className='button-and-input-wrapper'>
								<span className='input-description'>
									{order_type.knapphus.delivery_date.label} {GeneralConstants['can_change_in_page_1']}
								</span>
								<div className='look-like-input'>
									{activeFormData.start_transport_datetime && activeFormData.end_transport_datetime
										? `${activeFormData.start_transport_datetime} - ${activeFormData.end_transport_datetime}`
										: null}
								</div>
							</label>
						</fieldset>

						<CommentFormDisplay targetId='order_type.knapphus.order_number' />

						<ButtonAndInputContainer
							inputName='order_type[knapphus][wardrobe_model][value]'
							inputNameInitialValue={order_type.knapphus.wardrobe_model.value}
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='order_type.knapphus.wardrobe_model'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.wardrobe_model.label}
						/>
						<ButtonAndInputContainer
							inputName='order_type[knapphus][color_code][value]'
							inputNameInitialValue={order_type?.knapphus?.color_code?.value}
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='order_type.knapphus.color_code'
							group='order_type'
							comments={comments}
							label={order_type?.knapphus?.color_code?.label}
						/>
					</div>

					<div className='local-grid local-grid--col-1'>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.knapphus.height_self'
								group='order_type'
								comments={comments}
								label={order_type.knapphus.height_self.label}
							/>
							<fieldset className='options-container'>
								<span className='field-description'>{order_type.knapphus.height_self.label}</span>
								<fieldset className='local-grid local-grid--col-2 option-list'>
									<RadioGroup
										field='order_type[knapphus][height_self][standard_type][value]'
										initialValue={order_type.knapphus.height_self.standard_type.value}
									>
										{order_type &&
											order_type.knapphus.height_self.standard_type.options.map((option) => (
												<ProjectRadioFreeContainer
													name={option.sys_name}
													key={option.sys_name}
													label={option.label}
													value={option.sys_name}
													extraClass='small'
												/>
											))}
									</RadioGroup>
									<label className='input-with-checkbox'>
										<span className='input-description'>
											{order_type.knapphus.height_self.special.label}
										</span>
										<Text
											type='text'
											field='order_type[knapphus][height_self][special][value]'
											initialValue={order_type.knapphus.height_self.special.value}
										/>
									</label>
								</fieldset>
							</fieldset>
						</fieldset>
						<CommentFormDisplay targetId='order_type.knapphus.height_self' />
					</div>

					<div className='local-grid local-grid--col-2'>
						<ButtonAndInputContainer
							inputName='order_type[knapphus][pole_meter][value]'
							inputNameInitialValue={order_type.knapphus.pole_meter.value}
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.knapphus.pole_meter'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.pole_meter.label}
						/>
						<ButtonAndInputContainer
							inputName='order_type[knapphus][roof_height][value]'
							inputNameInitialValue={order_type.knapphus.roof_height.value}
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.knapphus.roof_height'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.roof_height.label}
						/>
						<ButtonAndInputContainer
							inputName='order_type[knapphus][roof_angle][value]'
							inputNameInitialValue={order_type.knapphus.roof_angle.value}
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.knapphus.roof_angle'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.roof_angle.label}
						/>
					</div>

					<div className='local-grid local-grid--col-1'>
						<ButtonAndOptionsContainer
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.knapphus.basket_section'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.basket_section.label}
							inputName='order_type[knapphus][basket_section][value]'
							inputNameInitialValue={order_type.knapphus.basket_section.value}
							options={order_type.knapphus.basket_section.options}
						/>
					</div>
					<div className='local-grid local-grid--col-1'>
						<label className='grid-item'>
							<span className='input-description'>{order_type.knapphus.comment.label}</span>
							<TextArea
								field='order_type[knapphus][comment][value]'
								initialValue={order_type.knapphus.comment.value}
								allowEmptyString={true}
							/>
						</label>
					</div>
				</div>
				<div
					className={`dropdown-content-options ${
						(order_type_val != 'sigdal' &&
							order_type_val != 'ka-garderobe' &&
							order_type_val != 'strai' &&
							order_type_val != 'andre') ||
						hide_order_type
							? 'dropdown-content-options--hidden'
							: ''
					}`}
				>
					<div className='local-grid local-grid--col-2'>
						<ProjectFormTextInput
							name='order_type[other][order_number][value]'
							type='text'
							label={order_type.other.order_number.label}
							activeFormData={order_type.other.order_number.value}
						/>
						<fieldset className='grid-item button-and-input-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.other.order_number'
								group='order_type'
								comments={comments}
								label={order_type.other.order_number.label}
							/>
							<label className='button-and-input-wrapper'>
								<span className='input-description'>
									{order_type.knapphus.delivery_date.label} {GeneralConstants['can_change_in_page_1']}
								</span>
								<div className='look-like-input'>
									{activeFormData.start_transport_datetime && activeFormData.end_transport_datetime
										? `${activeFormData.start_transport_datetime} - ${activeFormData.end_transport_datetime}`
										: null}
								</div>
							</label>
						</fieldset>

						<CommentFormDisplay targetId='order_type.other.order_number' />

						<ButtonAndInputContainer
							inputName='order_type[other][wardrobe_model][value]'
							inputNameInitialValue={order_type.other.wardrobe_model.value}
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='order_type.other.wardrobe_model'
							group='order_type'
							comments={comments}
							label={order_type.other.wardrobe_model.label}
						/>
						<ButtonAndInputContainer
							inputName='order_type[other][color_code][value]'
							inputNameInitialValue={order_type.other.color_code.value}
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='order_type.other.color_code'
							group='order_type'
							comments={comments}
							label={order_type.other.color_code.label}
						/>
					</div>

					<div className='local-grid local-grid--col-1'>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.other.height_self'
								group='order_type'
								comments={comments}
								label={order_type.other.height_self.label}
							/>
							<fieldset className='options-container'>
								<span className='field-description'>{order_type.other.height_self.label}</span>
								<fieldset className='local-grid local-grid--col-2 option-list'>
									<RadioGroup
										field='order_type[other][height_self][standard_type][value]'
										initialValue={order_type.other.height_self.standard_type.value}
									>
										{order_type &&
											order_type.other.height_self.standard_type.options.map((option) => (
												<ProjectRadioFreeContainer
													name={option.sys_name}
													key={option.sys_name}
													label={option.label}
													value={option.sys_name}
													extraClass='small'
												/>
											))}
									</RadioGroup>
									<label className='input-with-checkbox'>
										<span className='input-description'>
											{order_type.other.height_self.special.label}
										</span>
										<Text
											type='text'
											field='order_type[other][height_self][special][value]'
											initialValue={order_type.other.height_self.special.value}
											allowEmptyString={true}
											disabled={
												!(
													formState.values.order_type &&
													formState.values.order_type.other &&
													formState.values.order_type.other.height_self &&
													formState.values.order_type.other.height_self.standard_type &&
													formState.values.order_type.other.height_self.standard_type
														.value === 'special'
												)
											}
										/>
									</label>
								</fieldset>
							</fieldset>
						</fieldset>
						<CommentFormDisplay targetId='order_type.other.height_self' />
					</div>

					<div className='local-grid local-grid--col-2'>
						<ButtonAndInputContainer
							inputName='order_type[other][pole_meter][value]'
							inputNameInitialValue={order_type.other.pole_meter.value}
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.other.pole_meter'
							group='order_type'
							comments={comments}
							label={order_type.other.pole_meter.label}
						/>
						<ButtonAndInputContainer
							inputName='order_type[other][roof_height][value]'
							inputNameInitialValue={order_type.other.roof_height.value}
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.other.roof_height'
							group='order_type'
							comments={comments}
							label={order_type.other.roof_height.label}
						/>
						<ButtonAndInputContainer
							inputName='order_type[other][roof_angle][value]'
							inputNameInitialValue={order_type.other.roof_angle.value}
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.other.roof_angle'
							group='order_type'
							comments={comments}
							label={order_type.other.roof_angle.label}
						/>
					</div>

					<div className='local-grid local-grid--col-1'>
						<ButtonAndOptionsContainer
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.other.basket_section'
							group='order_type'
							comments={comments}
							label={order_type.other.basket_section.label}
							inputName='order_type[other][basket_section][value]'
							inputNameInitialValue={order_type.other.basket_section.value}
							options={order_type.other.basket_section.options}
						/>
					</div>
					<div className='local-grid local-grid--col-1'>
						<label className='grid-item'>
							<span className='input-description'>{order_type.other.comment.label}</span>
							<TextArea
								field='order_type[other][comment][value]'
								initialValue={order_type.other.comment.value}
								allowEmptyString={true}
							/>
						</label>
					</div>
				</div>
			</div>
			<div className='dropdown-container'>
				<div
					className={`dropdown-header-container dropdown-container__header ${
						hide_doorhandle ? 'dropdown-container__header--closed' : null
					}`}
				>
					<a href='' className='dropdown-container-trigger' onClick={(ev) => toggle(ev, 'doorhandle')}>
						{doorhandle.label}
					</a>
					<div className='dropdown-status-container'>
						<DropTownHeaderCheckBox
							name='doorhandle[dates][ordered][value]'
							label={doorhandle.dates.ordered.label}
							activeFormData={doorhandle.dates.ordered.value === true}
						/>
						<DropTownHeaderCheckBox
							name='doorhandle[dates][approved][value]'
							label={doorhandle.dates.approved.label}
							activeFormData={doorhandle.dates.approved.value === true}
						/>
						<DropTownHeaderCheckBox
							name='doorhandle[dates][delivered][value]'
							label={doorhandle.dates.delivered.label}
							dateName='doorhandle[dates][delivered_date][value]'
							activeFormData={doorhandle.dates.delivered.value === true}
							activeFormDateData={
								doorhandle.dates.delivered_date.value
									? doorhandle.dates.delivered_date.value
									: todayDate
							}
							todayDate={todayDate}
						/>
						<ProjectAddCommentButton
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='doorhandle.header'
							group='doorhandle'
							comments={comments}
							label={doorhandle.label}
						/>
					</div>
				</div>
				<div
					className={`local-grid local-grid--col-2 dropdown-content-initial-options ${
						hide_doorhandle ? 'local-grid--hidden' : null
					}`}
				>
					<CommentFormDisplay targetId='doorhandle.header' />
					<label className='grid-item'>
						<span className='input-description'>{doorhandle.doorhandle_options.label}</span>
						<Select
							field='doorhandle[doorhandle_options][value]'
							className='regular'
							initialValue={doorhandle.doorhandle_options.value}
						>
							{doorhandle.doorhandle_options.options.map((option) => (
								<Option value={option.sys_name} key={option.sys_name}>
									{option.label}
								</Option>
							))}
						</Select>
					</label>
					<label className='grid-item'>
						<span className='input-description'>{doorhandle.doorhandle_text.label}</span>
						<Text
							type='text'
							field='doorhandle[doorhandle_text][value]'
							initialValue={doorhandle.doorhandle_text.value}
							allowEmptyString={true}
						/>
					</label>
				</div>
			</div>
			<div className='dropdown-container'>
				<div
					className={`dropdown-header-container dropdown-container__header ${
						hide_is_workdrawing_sent ? 'dropdown-container__header--closed' : null
					}`}
				>
					<a
						href=''
						className='dropdown-container-trigger'
						onClick={(ev) => toggle(ev, 'is_workdrawing_sent')}
					>
						{is_workdrawing_sent.label}
					</a>
					<div className='dropdown-status-container'>
						<ProjectAddCommentButton
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='is_workdrawing_sent.header'
							group='is_workdrawing_sent'
							comments={comments}
							label={is_workdrawing_sent.label}
						/>
					</div>
				</div>
				<div
					className={`local-grid local-grid--col-1 dropdown-content-initial-options ${
						hide_is_workdrawing_sent ? 'local-grid--hidden' : null
					}`}
				>
					<CommentFormDisplay targetId='is_workdrawing_sent.header' />
					<div>
						<label className='free-grid-item'>
							<Checkbox
								field='is_workdrawing_sent[value]'
								initialValue={is_workdrawing_sent.value === true}
							/>
							<span>{GeneralConstants['yes']}</span>
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProjectFormWardrobe;
