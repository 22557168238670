import './App.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ProjectRoutes from './components/project-routes/project-routes.component';
import Login from './pages/login/login.component';
import { checkUserSession } from './redux/user/user.actions';
import { selectCurrentUser } from './redux/user/user.selectors';

class App extends React.Component {
	componentDidMount() {
		const { checkUserSession } = this.props;
		checkUserSession();
	}

	render() {
		const { currentUser } = this.props;
		if (currentUser) {
			return <ProjectRoutes />;
		}
		return <Login />;
	}
}

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser
});

const mapDispatchToProps = (dispatch) => ({
	checkUserSession: () => dispatch(checkUserSession())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
