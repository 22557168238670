import { Checkbox, Text } from 'informed';
import * as React from 'react';

const DropTownHeaderCheckBox = ({ name, dateName, label, activeFormData, activeFormDateData, todayDate }) => {
	function dateFormat(time) {
		const d = new Date(time);

		const val = d
			.toLocaleDateString('en-GB')
			.replace(`, `, '-')
			.replace(`/`, '.')
			.replace(`/`, '.')
			.replace(/:[^:]*$/, '');
		return val;
	}
	return (
		<label>
			{dateName && (
				<Text field={dateName} initialValue={activeFormData ? activeFormDateData : todayDate} type='hidden' />
			)}
			{name == 'products[dates][delivered][value]' ||
			name == 'products[dates][ordered][value]' ||
			name == 'products[dates][approved][value]' ? (
				<>
					<Checkbox
						field={name}
						className={`right ${activeFormData && name ? 'checked' : 'unchecked'}`}
						initialValue={activeFormData}
						disabled
					/>
					<div className='project-section-status'>
						<span className='project-section-status__name'>{label}</span>
						<span className='project-section-status__date'>
							{activeFormData === true && activeFormDateData ? dateFormat(activeFormDateData) : null}
						</span>
					</div>
				</>
			) : (
				<>
					<Checkbox field={name} className='right' initialValue={activeFormData} />
					<div className='project-section-status'>
						<span className='project-section-status__name'>{label}</span>
						<span className='project-section-status__date'>
							{activeFormData === true && activeFormDateData ? dateFormat(activeFormDateData) : null}
						</span>
					</div>
				</>
			)}
		</label>
	);
};

export default DropTownHeaderCheckBox;
