import * as React from 'react';

import ProjectAddCommentButton from '../project-add-comment-button/project-add-comment-button.component';

const DropDownHeaderComponent = ({
	title,
	toggleDropDownContent,
	handleShowComments,
	comments,
	targetId,
	group,
	label,
	showContentBelow
}) => (
	<div className={`dropdown-header-container dropdown-container__header ${showContentBelow}`}>
		<a href='#' className='dropdown-container-trigger' onClick={toggleDropDownContent}>
			{title}
		</a>
		<div className='dropdown-status-container'>
			<ProjectAddCommentButton
				handleShowComments={handleShowComments}
				extraClass
				targetId={targetId}
				group={group}
				comments={comments}
				label={label}
			/>
		</div>
	</div>
);

export default DropDownHeaderComponent;
