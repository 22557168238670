const GeneralConstants = {
	brand_name: 'Prosjekt brand',
	show_projects_complaints: 'Vis Oppsop',
	project_title: 'Prosjektnavn *',
	project_status: 'Prosjekt status',
	expected_end_date: 'Forventet leveringsdato',
	end_project_datetime: 'Forventet leveringsdato',
	project_nr: 'Prosjekt nummer',
	project_nr_short: 'Prosj.nr',
	project_type: 'Prosjekt type',
	go: 'Gå til',
	current_location: 'Nåværende lokasjon',
	user: 'Bruker',
	toggle: 'Toggle',
	hours_tracked: 'Registrerte timer',
	edit_date: 'Sist oppdatert',
	start_time: 'Start',
	end_time: 'Stopp',
	project_id: 'Prosjekt ID',
	client_name: 'Navn kunde',
	client_name_overlay: 'Navn',
	select_main_project: 'Hovedprosjekt (valgfritt)',
	select_client: 'Velg kunde',
	select_client_2: 'Velg - eller legg til en ny kunde',
	phone_nr: 'Tlf nummer',
	email: 'E-post',
	project_based: 'Prosjekt (fastpris)',
	hour_based: 'Timebasert',
	damage_based: 'Oppsop',
	create_project: 'Opprett prosjekt',
	address: 'Leveringsadresse *',
	google_address: 'Google Leveringsadresse *',
	postal_code: 'Postnummer',
	postal_place: 'Poststed',
	delete_address: 'Slett adresse',
	add_address: 'Legg til annen adresse',
	planned_delivery_date_transport: 'Planlagt transportdato',
	planned_delivery_date_assembly: 'Planlagt monteringsdato',
	planned_delivery_date_production: 'Planlagt produksjonsdato',
	transport: 'Transportør',
	assembly: 'Montør',
	production: 'Produksjon',
	project_manager: 'Prosjektleder',
	price: 'Pris',
	choose_date: 'Velg dato',
	yes: 'Ja',
	no: 'Nei',
	create_new_project_group: 'create_new_project_group',
	add_new_group: 'add_new_group',
	not_found: 'Ikke funnet',
	close_overlay: 'Lukk',
	add_new_client: 'Legg til kunde',
	demolition_and_services_header: 'Riving og andre tjenester generell kommentar',
	general_comment: 'Generell kommentar',
	client_and_delivery: 'Kunde og leveranse',
	project_details: 'Prosjekt detaljer',
	files: 'Filer',
	save_project: 'Lagre prosjekt',
	documents: 'Dokumenter',
	pictures: 'Bilder',
	upload_document: 'Last opp dokument',
	upload_picture: 'Last opp bilde',
	upload: 'Last opp',
	no_content: 'Ingen bilder eller dokumenter er lastet opp',
	uploading_documents: 'Laster opp dokumenter',
	uploading_pictures: 'Laster opp bilder',
	close: 'Lukk',
	select_transport_start_time: 'Velg transport startdato',
	select_transport_end_time: 'Velg transport sluttdato',
	select_assembly_start_time: 'Velg montering startdato',
	select_assembly_end_time: 'Velg montering sluttdato',
	select_production_start_time: 'Velg produktsjon startdato',
	select_production_end_time: 'Velg produksjon sluttdato',
	save: 'Lagre',
	update_comment: 'Rediger',
	save_comment: 'Lagre',
	add_comment: 'Legg til kommentar',
	want_to_delete_comment: 'Er du sikker på at du ønsker å slette kommentaren?',
	order_type: 'Order type',
	sent_from: 'Sendt fra',
	products: 'Produkter',
	doorhandle: 'Håndtak',
	is_workdrawing_sent: 'El.arbeidstegning sendt',
	demolition_and_services: 'Riving og andre tjenester',
	delivery_and_project: 'Leveranse og prosjekt',
	delivery_and_project_header: 'Leveranse og prosjekt generell kommentar',
	show_all_comments: 'Vis alle kommentarer',
	show_comment_for: 'Vis kommentarer for',
	edit_your_role: 'Velg rollen din for timeføring',
	add_project: 'Lag nytt prosjekt',
	active_projects: 'Aktive prosjekter',
	archive_projects: 'Arkiverte prosjekter',
	my_day: 'Min dag',
	calendar: 'Kalender',
	map: 'Kart',
	project_log: 'Prosjekt logg',
	login: 'Logg inn',
	forgot_password: 'Glemt passord?',
	contact_admin: 'Kontakt administrator for nytt passord',
	username: 'Brukernavn',
	password: 'Passord',
	select: 'Velg',
	save_your_time: 'Registrer din tid',
	start_tracker: 'Start tidtaker',
	stop_tracker: 'Pause tidtaker',
	reset_tracker: 'Nullstill tidtaker',
	choose_project: 'Velg prosjekt',
	close_tracker: 'Lukk',
	close_comment_form: 'Lukk',
	can_change_in_page_1: '(Kan kun endres i steg 1)',
	search_by_name_or_project: 'Søk etter prosjektnavn eller nummer',
	save_time: 'Lagre tid',
	close_and_save: 'Lagre og lukk',
	on: 'På',
	off: 'Av',
	search: 'Søk',
	show_my_projects: 'Vis mine prosjekt',
	list_day: 'Dag',
	list_month: 'Måned',
	list_week: 'Uke',
	success: 'Suksess!',
	delete: 'slett',
	add: 'Legg til',
	date: 'dato',
	not_found_header: 'Finner ikke siden',
	not_found_info: 'Vennligst sjekk at du er tilkoplet internett.',
	go_home: 'Hjem',
	week: 'Uke',
	load_more_projects: 'Last inn flere prosjekter',
	load_more_logs: 'Last inn flere logger',
	override: 'Overstyr',
	reload: 'Last inn siden på nytt',
	time: 'Dato',
	distance: 'Avstand',
	status: 'Status',
	contact_admin_email: 'vegard@knapphus-kjokkensenter.no',
	contact_admin_phone: '917 39 991',
	current_time: 'Dato: ',
	logo: 'Knapphus',
	offline_projects: 'offline projects',
	offline_mode_header: 'offline header',
	offline_mode_text: 'offline text',
	status_invoiced_datetime: 'Fakturert dato',
	mark: 'Velg merke',
	model: 'Modell',
	quantity: 'Antall',
	delivery_date: 'Leveringsdato',
	prev: 'FORRIGE',
	next: 'NESTE',
	diverse: 'Diverse tillegg',
	EL_pillar: 'El pillar',
	front: 'Front',
	dekks: 'Dekkside',
	color_code: 'Fargekode',
	moldings_lists: 'Lister',
	planned_week: 'Lakkering uke',
	5: '[Feilkode: 5] Vennligst sjekk at alle obligatoriske felter er utfyllt og at alle felter er i rett format.',
	7: '[Feilkode: 7] Feltet er ugyldig',
	9: '[Feilkode: 9] Feltet kan ikke sorteres',
	11: 'Brukernavn eller passord er feil',
	13: '[Feilkode: 13] Du har ikke rettigheter til å styre denne brukerens rettigheter.',
	14: '[Feilkode: 14] Denne rollen er ikke tilgjengelig for brukeren.',
	error_header: 'Brukernavn eller passord er feil',
	22: '[Feilkode: 22] En teknisk feil oppstod. Vennligst forsøk igjen eller tilkall teknisk personell.',
	31: '[Feilkode: 31] En teknisk feil oppstod. Prosjekt ID mangler.',
	50: '[Feilkode: 50] Du har ikke rettigheter til å starte et nytt prosjekt.',
	// eslint-disable-next-line max-len
	51: '[Feilkode: 51] Prosjektet har blitt endret samtidig som du jobbet med det. Se under når og hvem som lagret det.',
	52: '[Feilkode: 52] Tidsformatet er feil. Riktig format er [YYYY-MM-DD]',
	53: '[Feilkode: 53] Tidsperiodekoden er feil. Må være "Y", "M", "D" eller "W"',
	54: '[Feilkode: 54] Administratortillatelser kreves',
	64: '[Feilkode: 64] Vennligst velg en gyldig prosjkttype.',
	69: '[Feilkode: 69] En teknisk feil oppstod. Vennligst forsøk igjen eller tilkall teknisk personell.',
	71: '[Feilkode: 71] Det oppstod et tidsavbrudd. Vennligst last inn appen på nytt og forsøk igjen',
	72: '[Feilkode: 72] Vi opplever treghet med databasen. Vennligst forsøk igjen senere.',
	73: '[Feilkode: 73] Database autentisieringen feilet.',
	// eslint-disable-next-line max-len
	75: '[Feilkode: 75] Prosjektet har blitt endret samtidig som du jobbet med det. Se under når og hvem som lagret det.',
	100: '[Feilkode: 100] En teknisk feil oppstod. ID kan ikke verifiseres',
	130: '[Feilkode: 130] Brukeren tilhører ikke dette prosjektet',
	140: '[Feilkode: 140] Feil brukerrolle',
	150: '[Feilkode: 150] En teknisk feil oppstod. Prosjekt ID-en eksisterer ikke',
	409: '[Feilkode: 409] Prosjektet finnes ikke i Dropbox.',
	429: '[Feilkode: 429] Det oppstod en feil ved lagringen av filer till Dropbox.',
	503: '[Feilkode: 503] Kan ikke lese/skrive fil. Dropbox tjenesten er ikke tilgjengelig.'
};

export default GeneralConstants;
