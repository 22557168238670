import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import ErrorConstants from '../../constants/error.constants';
import GeneralConstants from '../../constants/general.constants';
import ErrorMessage from '../error/error.component';
import Spinner from '../spinner/spinner.component';
import SuccessMessage from '../success/success.component';

const api = window.api;

class TimeTracker extends React.Component {
	constructor(props) {
		super(props);

		const { running, value } = this.props;

		const timestamp = running ? Date.now() + value : value;

		const hour = Math.floor(timestamp / 3600000);

		const min = Math.floor(timestamp / 60000) % 60;

		this.state = {
			currentDate: this.toDateInputValue(),
			hour,
			min,
			projects: [],
			searchProjects: [],
			searchWord: '',
			toggleProjectList: false,
			selectedFormID: null,
			selectedFormTitle: '',
			currentUserActiveRole: this.props.currentUserActiveRole,
			error: null,
			isFetching: true,
			successMessage: false,
			errorMessage: false
		};

		this.toDateInputValue = this.toDateInputValue.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.setProjects = this.setProjects.bind(this);
		this.handleProjectSelection = this.handleProjectSelection.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.setServerError = this.setServerError.bind(this);
		this.toggleProjectList = this.toggleProjectList.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}

	async componentDidMount() {
		if (this.state.running) {
			this.timer = setInterval(() => this.forceUpdate(), 1000 | 0);
		}
		try {
			const result = await api.projects.get(`?sort=-id&fields=id,title&my_projects=true`);
			this.setProjects(result);
		} catch (error) {
			this.setServerError(error);
		}
	}

	componentWillUnmount() {
		if (this.props.running) {
			clearInterval(this.timer);
		}
	}

	setProjects(projects) {
		this.setState((prevState) => ({
			...prevState,
			projects,
			error: null,
			isFetching: false
		}));
	}

	toggleProjectList(ev) {
		ev.preventDefault();
		this.setState({ toggleProjectList: !this.state.toggleProjectList });
	}

	handleProjectSelection(ev, selectedFormID, selectedFormTitle) {
		ev.preventDefault();
		this.setState((prevState) => ({
			...prevState,
			selectedFormID,
			selectedFormTitle,
			toggleProjectList: false
		}));
	}

	toDateInputValue() {
		const local = new Date();
		local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
		return local.toJSON().slice(0, 10);
	}

	handleInputChange(ev) {
		this.setState({
			[ev.target.name]: ev.target.value
		});
	}

	handleSearch(e) {
		this.setState({ searchWord: e.target.value });
		let currentList = [];

		let newList = [];

		if (e.target.value !== '') {
			currentList = this.state.projects;
			if (currentList.length) {
				newList = currentList.filter((item) => {
					if (item.title) {
						return item.title.toLowerCase().includes(e.target.value.toLowerCase());
					}
					if (item.id) {
						return toString(item.id)
							.toLowerCase()
							.includes(e.target.value.toLowerCase());
					}
				});
			}
		} else {
			newList = this.props.projects;
		}

		if (newList !== undefined) {
			this.setState((prevState) => ({
				...prevState,
				searchProjects: newList
			}));
		}
	}

	async handleFormSubmit(ev) {
		ev.preventDefault();
		this.setState({ isFetching: true });
		const { currentDate, currentUserActiveRole, selectedFormID, hour, min } = this.state;

		const mins = parseInt(hour) * 60 + parseInt(min);
		try {
			const result = await api.timetracks.put('timetracks', {
				project_id: selectedFormID,
				role: currentUserActiveRole.label,
				mins,
				done_date: currentDate
			});
			if (result) {
				this.setState(() => ({ successMessage: true }));
				this.props.resetTrackerAfterSubmit();
				setTimeout(
					() => this.setState({ successMessage: false, isFetching: false }, () => this.props.toggleTracker()),
					1000
				);
			}
		} catch (error) {
			this.setServerError(error);
		}
	}

	setServerError(error) {
		this.setState((prevState) => ({
			...prevState,
			error,
			isFetching: false,
			successMessage: false,
			errorMessage: true
		}));
		setTimeout(() => this.setState({ errorMessage: false }), 2000);
	}

	render() {
		const { startTracker, stopTracker, resetTracker, running } = this.props;

		const {
			currentDate,
			projects,
			selectedFormID,
			selectedFormTitle,
			toggleProjectList,
			error,
			hour,
			min,
			searchProjects,
			searchWord,
			isFetching,
			successMessage,
			errorMessage
		} = this.state;

		return (
			<>
				<CSSTransition
					in={isFetching}
					appear
					timeout={{
						appear: 300,
						exit: 150
					}}
				>
					<Spinner />
				</CSSTransition>
				<CSSTransition
					in={successMessage}
					appear
					timeout={{
						enter: 3000,
						exit: 0
					}}
				>
					<SuccessMessage message={GeneralConstants['success']} />
				</CSSTransition>

				<CSSTransition
					in={errorMessage}
					appear
					timeout={{
						enter: 3000,
						exit: 0
					}}
				>
					<ErrorMessage
						errorID={
							this.state.error && this.state.error.id
								? this.state.error.id
								: this.state.error && this.state.error.status
								? this.state.error.status
								: ''
						}
					/>
				</CSSTransition>
				<div className='track-time-container'>
					<div className='timer-controls'>
						<a href='#' className='time-tracker-status time-tracker--clear' onClick={resetTracker}>
							{GeneralConstants['reset_tracker']}
						</a>

						{running ? (
							<a href='#' className='time-tracker-status time-tracker--stop' onClick={stopTracker}>
								{GeneralConstants['stop_tracker']}
							</a>
						) : (
							<a href='#' className='time-tracker-status time-tracker--start' onClick={startTracker}>
								{GeneralConstants['start_tracker']}
							</a>
						)}
					</div>

					<span className='action-title'>{GeneralConstants['save_your_time']}</span>
					<form className='time-tracker-info' onSubmit={this.handleFormSubmit}>
						<div className='add-time-container'>
							<input
								type='hidden'
								name='projectID'
								value={selectedFormID || ''}
								onChange={this.handleInputChange}
							/>
							<input
								type='number'
								name='hour'
								step='1'
								max='23'
								min='0'
								value={hour > 0 ? hour : ''}
								onChange={this.handleInputChange}
							/>
							<span>t</span>
							<input
								type='number'
								name='min'
								step='15'
								max='45'
								min='0'
								value={min > 0 ? min : ''}
								onChange={this.handleInputChange}
								className={error && error.body && error.body.mins ? 'error-field' : ''}
							/>
							<span>min</span>

							<input
								type='date'
								name='time'
								value={currentDate}
								onChange={(ev) =>
									this.setState({
										currentDate: ev.target.value
									})
								}
							/>
						</div>
						{error && error.body && error.body.mins ? (
							<span className='error-inline error-inline--trigger'>{ErrorConstants['not_valid']}</span>
						) : null}
						<a
							href=''
							className={`choose-project-trigger ${
								error && error.body && error.body.project_id ? 'choose-project-trigger--error' : ''
							}`}
							onClick={(e) => this.toggleProjectList(e)}
						>
							{selectedFormTitle || GeneralConstants['choose_project']}
						</a>
						{error && error.body && error.body.project_id ? (
							<span className='error-inline error-inline--trigger'>{ErrorConstants['required']}</span>
						) : null}

						{toggleProjectList && (
							<div className='project-inline-search'>
								<input
									type='search'
									placeholder={GeneralConstants['search_by_name_or_project']}
									onChange={(ev) => this.handleSearch(ev)}
									value={searchWord}
								/>
								{searchWord ? (
									searchProjects.length ? (
										<ul className='project-list-container'>
											{searchProjects.map((project) => (
												<li key={project.id}>
													<a
														href='#'
														onClick={(e) =>
															this.handleProjectSelection(e, project.id, project.title)
														}
													>
														<span className='project-title'>{project.title}</span>
														<span className='project-id'>{project.id}</span>
													</a>
												</li>
											))}
										</ul>
									) : null
								) : projects.length ? (
									<ul className='project-list-container'>
										{projects.map((project) => (
											<li key={project.id}>
												<a
													href='#'
													onClick={(e) =>
														this.handleProjectSelection(e, project.id, project.title)
													}
												>
													<span className='project-title'>{project.title}</span>
													<span className='project-id'>{project.id}</span>
												</a>
											</li>
										))}
									</ul>
								) : null}
							</div>
						)}
						<button className='btn large light-green right save--dark'>
							{GeneralConstants['save_time']}
						</button>
						<button className='btn large close' type='button' onClick={() => this.props.toggleTracker()}>
							{GeneralConstants['close_tracker']}
						</button>
					</form>
				</div>
			</>
		);
	}
}

export default TimeTracker;
