import { Form, Text } from 'informed';
import * as React from 'react';
import { connect } from 'react-redux';

import GeneralConstants from '../../constants/general.constants';
import { signInStart } from '../../redux/user/user.actions';

class LoginForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showHelp: false
		};
		this.handleHelpContainer = this.handleHelpContainer.bind(this);
	}

	handleHelpContainer(ev) {
		ev.preventDefault();
		this.setState(() => ({
			showHelp: !this.state.showHelp
		}));
	}

	onSubmit = (formValues) => {
		const { user, password } = formValues;
		this.props.signInStart(user, password);
	};

	render() {
		const { error } = this.props.user;

		const { showHelp } = this.state;
		return (
			<div className='login-page-container'>
				<div className='login-and-forgot-password-container'>
					<Form className='login-form' onSubmit={this.onSubmit}>
						<fieldset className='login-form-fieldset'>
							<div className='logo'>{GeneralConstants['logo']}</div>
							<label>
								<Text
									field='user'
									type='text'
									className={error && error.message ? 'error-field' : ''}
									placeholder={GeneralConstants['username']}
								/>
							</label>
							<label>
								<Text
									field='password'
									type='password'
									className={error && error.message ? 'error-field' : ''}
									placeholder={GeneralConstants['password']}
								/>
							</label>
							{error && (
								<div className='error-message-container'>
									<h2>{GeneralConstants['error_header']}</h2>
									<p className='error-message'>{GeneralConstants[`${error.id}`]}</p>
								</div>
							)}
							<div className='btn-container'>
								<button className='btn dark' type='submit'>
									{GeneralConstants['login']}
								</button>
							</div>
						</fieldset>
					</Form>
				</div>
				<div className='forgot-password-container'>
					<a
						href='#'
						className={`btn-forgot-password ${showHelp ? 'active' : ''}`}
						onClick={this.handleHelpContainer}
					>
						{GeneralConstants['forgot_password']}
					</a>
					{showHelp && (
						<div className='forgot-password-container__feedback'>
							<p className='feedback-message'>{GeneralConstants['contact_admin']}</p>
							<ul className='feedback-option-container'>
								<li className='feedback-option feedback-option--email'>
									<a href={`mailto:${GeneralConstants['contact_admin_email']}`}>
										{GeneralConstants['contact_admin_email']}
									</a>
								</li>
								<li className='feedback-option feedback-option--phone'>
									<a href={`tel:${GeneralConstants['contact_admin_phone']}`}>
										{GeneralConstants['contact_admin_phone']}
									</a>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user
});

const mapDispatchToProps = (dispatch) => ({
	signInStart: (user, password) => dispatch(signInStart({ user, password }))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
