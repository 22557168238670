import * as React from 'react';

import GeneralConstants from '../../../constants/general.constants';
import ProjectMultiformDates from '../project-multiform/project-multiform-dates.component';
import ProjectExtrasMultiform from './ProjectExtrasMultiform.component';

const ProjectExtras = (props) => {
	const getInitialValueFromGroup = props.getInitialValues(props.group);

	const showGroupOptionsInitial = getInitialValueFromGroup.filter(
		(group) => group.diverse !== '' && group.EL_pillar !== ''
	);

	const [toggleList, setToggleList] = React.useState(!!(showGroupOptionsInitial && showGroupOptionsInitial.length));

	return (
		<fieldset className='options-container'>
			<span className='field-description'>{props.optionLabel}</span>
			{!toggleList ? (
				<div className='btn-container btn-container--right btn-container--border'>
					<button type='button' onClick={() => setToggleList(true)} className='btn small light add'>
						{`${GeneralConstants?.add} ${props.products[`${props.group}`].label}`}
					</button>
				</div>
			) : null}
			<fieldset className={`local-grid local-grid--col-2 ${toggleList ? 'option-list' : 'option-list--hidden'}`}>
				<ProjectMultiformDates
					dates={props.dates}
					checkBoxPath={props.checkBoxPath}
					dateName={props.dateName}
					activeFormDateData={props.activeFormDateData}
					todayDate={props.todayDate}
				/>
				<ProjectExtrasMultiform
					getInitialValues={props.getInitialValues}
					group={props.group}
					products={props.products}
					handleProductRemove={props.handleProductRemove}
					handleShowComments={props.handleShowComments}
					comments={props.comments}
					activeFormDataStatus={props.activeFormDataStatus}
				/>
			</fieldset>
		</fieldset>
	);
};

export default ProjectExtras;
