import * as React from 'react';

import ProjectList from '../../components/project-list/project-list.component';
import ProjectNavigation from '../../components/project-navigation/project-navigation.component';

const archiveProjectTypes = ['archived'];

const ProjectArchive = (props) => (
	<>
		<ProjectNavigation />
		<ProjectList {...props} projectTypes={archiveProjectTypes} />
	</>
);

export default ProjectArchive;
