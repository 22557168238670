import * as React from 'react';

const ProjectAddCommentButton = ({ handleShowComments, extraClass, targetId, group, comments, label, ...props }) => {
	let count = [];
	if (comments) {
		count = comments.filter((comment) => comment.target_id == targetId);
	}

	let extraClassForBtn = extraClass ? 'btn-add-comment btn-add-comment--small' : 'btn-add-comment';
	if (count.length) {
		extraClassForBtn = extraClass
			? 'btn-add-comment btn-add-comment--small btn-add-comment--added'
			: 'btn-add-comment btn-add-comment--added';
	}

	return (
		<>
			<button
				className={extraClassForBtn}
				data-comments={count.length ? count.length : '+'}
				type='button'
				onClick={(ev) => handleShowComments({ targetId, group, label })}
			/>
		</>
	);
};

export default ProjectAddCommentButton;
