import { Option, Select } from 'informed';
import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';
import StatusConstants from '../../constants/status.constants';
import { timeConvert } from '../../utils/calenderHelper';

class ProjectFooter extends React.Component {
	render() {
		const {
			page,
			handleProjectStatusChange,
			projectStatus,
			handlePageSwitch,
			handleOnSubmit,
			handlePageOneData,
			fileCount,
			firstStepCommentCount,
			secondStepCommentCount,
			activeFormDataStatus,
			projectCategory,
			handlePageTwoData,
			canSetArchiveMode,
			projectName,
			trackedTime
		} = this.props;
		return (
			<footer>
				<div className={'name-container'}>
					{projectName}
					{trackedTime.total_tracked_time ? timeConvert(trackedTime?.total_tracked_time) : null}
				</div>
				<div className={'action-container'}>
					<div className='select-status-container'>
						<Select
							field='status'
							initialValue={projectStatus}
							className={`status-select status-select--${projectStatus}`}
							onChange={(ev) => handleProjectStatusChange(ev.target.value)}
						>
							<Option value='draft'>{StatusConstants['draft']}</Option>
							<Option value='order'>{StatusConstants['order']}</Option>
							<Option value='confirmed'>{StatusConstants['confirmed']}</Option>
							<Option value='ready_for_rest'>{StatusConstants['ready_for_rest']}</Option>
							<Option value='ready_for_assembly'>{StatusConstants['ready_for_assembly']}</Option>
							<Option value='delivered'>{StatusConstants['delivered']}</Option>
							<Option value='assembled'>{StatusConstants['assembled']}</Option>
							<Option value='complaints'>{StatusConstants['complaints']}</Option>
							<Option value='ready'>{StatusConstants['ready']}</Option>
							<Option value='ready_to_invoice'>{StatusConstants['ready_to_invoice']}</Option>
							{canSetArchiveMode ? (
								<Option value='invoiced'>{StatusConstants['invoiced']}</Option>
							) : (
								<Option value='invoiced' disabled>
									{StatusConstants['invoiced']}
								</Option>
							)}
							{canSetArchiveMode ? (
								<Option value='archived'>{StatusConstants['archived']}</Option>
							) : (
								<Option value='archived' disabled>
									{StatusConstants['archived']}
								</Option>
							)}
						</Select>
					</div>
					<ul className='project-step-container'>
						<li
							className={`project-step-delivery ${page === 1 ? 'project-step-delivery--active' : ''}`}
							data-comments={firstStepCommentCount}
						>
							<a href='' onClick={(ev) => handlePageOneData(ev)}>
								{GeneralConstants['client_and_delivery']}
							</a>
						</li>
						{projectCategory !== 'project_main' ? (
							<>
								<li
									className={`project-step-details ${
										page === 2 ? 'project-step-details--active' : ''
									}`}
									data-comments={secondStepCommentCount}
								>
									<a href='' onClick={(ev) => handlePageTwoData(ev, 2)}>
										{GeneralConstants['project_details']}
									</a>
								</li>
								<li
									className={`project-step-files  ${page === 3 ? 'project-step-files--active' : ''}`}
									data-files={fileCount}
								>
									<a href='' onClick={(ev) => handlePageSwitch(ev, 3)}>
										{GeneralConstants['files']}
									</a>
								</li>
							</>
						) : null}
					</ul>

					<div className='save-project-container' style={{ marginLeft: '5px' }}>
						{activeFormDataStatus === 'archived' ? (
							<button className='btn medium dark btn--save' disabled>
								{GeneralConstants['save_project']}
							</button>
						) : (
							<button className='btn medium light-green btn--save' type='submit' onClick={handleOnSubmit}>
								{GeneralConstants['save_project']}
							</button>
						)}
					</div>
				</div>
			</footer>
		);
	}
}

export default ProjectFooter;
