import * as React from 'react';
import { GoogleMap, InfoWindow, Marker, withGoogleMap } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { compose, lifecycle, withProps, withStateHandlers } from 'recompose';

import GeneralConstants from '../../constants/general.constants';
import StatusConstants from '../../constants/status.constants';
import ClipBoard from './clipboard';
import ClipboardLink from './clipboard-link.component';

const api = window.api;

function addDays(date, days) {
	const copy = new Date(Number(date));
	copy.setDate(date.getDate() + days);
	return copy;
}

const MyMapComponent = compose(
	withProps({
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `100%` }} />,
		mapElement: <div style={{ height: `600px` }} />
	}),
	withGoogleMap,
	withStateHandlers(
		() => ({
			selectedProject: null,
			currentLocation: true
		}),
		{
			onToggleSelectedProject:
				({ selectedProject }) =>
				(project) => ({
					selectedProject: project
				}),
			onToggleCurrentLocation:
				({ currentLocation }) =>
				() => ({
					currentLocation: !currentLocation
				})
		}
	),
	lifecycle({
		async componentDidMount() {
			const { page } = this.props;

			const pageDaysFromNow = 7 * page;

			const curr = new Date(); // get current date

			const pageDate = addDays(curr, pageDaysFromNow);

			const sunday = pageDate.getDate() - pageDate.getDay(); // get Last Sunday

			const monday = new Date(pageDate.setDate(sunday + 1)); // get current week first day

			const convertedDate = `${monday.getFullYear().toString()}-${(monday.getMonth() + 1)
				.toString()
				.padStart(2, 0)}-${monday.getDate().toString().padStart(2, 0)}`;

			const projectTypes = [
				'draft',
				'order',
				'confirmed',
				'ready_for_rest',
				'ready_for_assembly',
				'delivered',
				'assembled',
				'complaints',
				'ready'
			];

			const fields = [
				// eslint-disable-next-line max-len
				'title,id,client_id,category,status,start_production_datetime,end_production_datetime,start_assembly_datetime,end_assembly_datetime,start_transport_datetime,end_transport_datetime&end_project_datetime=1'
			];

			const projects = await api.projects.get(
				// eslint-disable-next-line max-len
				`?project_id=13249&includes=client&datetime_range=0,${convertedDate},1&datetime_range_unit=W&project_status=${projectTypes}&fields=${fields}`
			);

			const filterProjects = projects.filter((project) => project.client && project.client.addresses);

			const filterAddresses = filterProjects.map((filterProject) => {
				if (filterProject?.client?.addresses?.[0]?.google_address) {
					return filterProject?.client?.addresses?.[0]?.google_address;
				}
				return filterProject?.client?.addresses?.[0]?.address_line;
			});

			const filterAddressesAndRemoveNull = filterAddresses.filter((t) => t != null);

			const distanceService = new window.google.maps.DistanceMatrixService();
			distanceService.getDistanceMatrix(
				{
					origins: [`${this.props?.lat},${this.props?.lng}`],
					destinations: filterAddressesAndRemoveNull ? [...filterAddressesAndRemoveNull] : [],
					travelMode: window.google.maps.TravelMode.DRIVING,
					unitSystem: window.google.maps.UnitSystem.METRIC,
					durationInTraffic: true,
					avoidHighways: false,
					avoidTolls: false
				},
				(result, status) => {
					if (status === window.google.maps.DirectionsStatus.OK) {
						this.setState({
							matrix: result,
							filterProjects
						});
					} else {
						this.setState({
							matrix: [],
							filterProjects: []
						});
					}
				}
			);
		},
		componentDidUpdate(prevProps, prevState) {
			if (prevProps?.lat != this.props?.lat || prevProps?.lng != this.props?.lng) {
				const distanceService = new window.google.maps.DistanceMatrixService();

				let destAddress = [];
				if (this.state?.matrix && this.state?.matrix?.destinationAddresses) {
					destAddress = this.state?.matrix?.destinationAddresses || [];
					distanceService.getDistanceMatrix(
						{
							origins: [`${this.props?.lat},${this.props?.lng}`],
							destinations: destAddress ? [...destAddress] : [],
							travelMode: window.google.maps.TravelMode.DRIVING,
							unitSystem: window.google.maps.UnitSystem.METRIC,
							durationInTraffic: true,
							avoidHighways: false,
							avoidTolls: false
						},
						(result, status) => {
							if (status === window.google.maps.DirectionsStatus.OK) {
								this.setState({
									matrix: result,
									filterProjects: this.state.filterProjects
								});
							} else {
								this.setState({
									matrix: [],
									filterProjects: []
								});
							}
						}
					);
				}
			}
		}
	})
)(({ matrix, filterProjects, projects, lat, lng, isMarkerShown, ...props }) => {
	const markerIcon = require('../../sass/gfx/icons/map/marker-red.svg');
	return (
		<>
			<div className='map'>
				{matrix ? (
					<Row
						directiondata={matrix['rows'] && matrix['rows'][0] ? matrix['rows'][0] : []}
						projects={filterProjects}
						lat={parseFloat(lat)}
						lng={parseFloat(lng)}
					/>
				) : null}
			</div>

			<GoogleMap defaultZoom={13} defaultCenter={new window.google.maps.LatLng(lat, lng)}>
				<MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
					<Marker
						position={{
							lat: parseFloat(lat),
							lng: parseFloat(lng)
						}}
						options={{
							icon: {
								url: markerIcon.default,
								scaledSize: { width: 48, height: 48 }
							}
						}}
						onClick={props.onToggleCurrentLocation}
					>
						{props.currentLocation && (
							<InfoWindow
								options={{ enableEventPropagation: true }}
								onCloseClick={props.onToggleSelectedProject}
							>
								<div className='map-pointer-data-container'>
									<div className='map-pointer-data-wrapper'>
										<div className='map-pointer__project-title'>
											<span>{GeneralConstants['current_location']}</span>
										</div>
									</div>
								</div>
							</InfoWindow>
						)}
					</Marker>
					{filterProjects &&
						filterProjects.map((project, i) => {
							if (
								project.client &&
								project.client['addresses'][0] &&
								project.client['addresses'][0]?.lat
							) {
								return (
									<Marker
										key={project.id}
										position={{
											lat: project.client['addresses'][0]?.lat,
											lng: project.client['addresses'][0]?.lng
										}}
										options={{
											icon: {
												url: markerIcon.default,
												scaledSize: {
													width: 48,
													height: 48
												}
											}
										}}
										onClick={() => props.onToggleSelectedProject(project)}
									>
										{props.selectedProject && props.selectedProject.id == project.id && (
											<InfoWindow
												options={{
													enableEventPropagation: true
												}}
												onCloseClick={() => {
													props.onToggleSelectedProject(null);
												}}
											>
												<div className='map-pointer-data-container'>
													<div className='map-pointer-data-wrapper'>
														<div className='map-pointer__project-title'>
															<a
																href={`https://www.google.com/maps/dir/?api=1&origin=${lat},${lng}&destination=${parseFloat(
																	project.client['addresses'][0]?.lat
																)},${parseFloat(
																	project.client['addresses'][0]?.lng
																)}&travelmode=driving`}
																target='_blank'
																rel='noopener noreferrer'
															>
																{project.title}
															</a>
														</div>
													</div>
												</div>
											</InfoWindow>
										)}
									</Marker>
								);
							}
						})}
				</MarkerClusterer>
			</GoogleMap>
		</>
	);
});

const Row = ({ directiondata, projects, lat, lng }) => {
	function dateFormat(time) {
		const d = new Date(time);
		return d
			.toLocaleDateString('en-GB')
			.replace(`, `, '-')
			.replace(`/`, '.')
			.replace(`/`, '.')
			.replace(/:[^:]*$/, '');
	}

	return (
		<div className='map-item'>
			{directiondata['elements'] &&
				directiondata['elements'].map((distance, i) => (
					<div className='map-item__data' key={i}>
						<div className='map-item__project-title'>
							{projects[i].title}
							{projects[i].client.addresses[0]?.google_address ? (
								<div className='map-item__project-address'>
									{projects[i].client.addresses[0]?.google_address}
								</div>
							) : projects[i].client.addresses[0]?.address_line ? (
								<ClipBoard address={projects[i].client.addresses[0]?.address_line} />
							) : null}
						</div>
						<div className='map-item__project-center-wrapper'>
							<div className='map-item__project-start'>
								<span className='map-item__project-label'>{GeneralConstants['start_time']}</span>
								{projects[i].start_production_datetime ? (
									<div className='map-item-time map-item-time--production'>
										{dateFormat(projects[i].start_production_datetime)}
									</div>
								) : null}
								{projects[i].start_assembly_datetime ? (
									<div className='map-item-time map-item-time--assembly'>
										{dateFormat(projects[i].start_assembly_datetime)}
									</div>
								) : null}
								{projects[i].start_transport_datetime ? (
									<div className='map-item-time map-item-time--transport'>
										{dateFormat(projects[i].start_transport_datetime)}
									</div>
								) : null}
							</div>
							<div className='map-item__project-end'>
								<span className='map-item__project-label'>{GeneralConstants['end_time']}</span>
								{projects[i].end_production_datetime ? (
									<div className='map-item-time map-item-time--production'>
										{dateFormat(projects[i].end_production_datetime)}
									</div>
								) : null}
								{projects[i].end_assembly_datetime ? (
									<div className='map-item-time map-item-time--assembly'>
										{dateFormat(projects[i].end_assembly_datetime)}
									</div>
								) : null}
								{projects[i].end_transport_datetime ? (
									<div className='map-item-time map-item-time--transport'>
										{dateFormat(projects[i].end_transport_datetime)}
									</div>
								) : null}
							</div>
							<div className='map-item__project-id'>
								<span className='map-item__project-label'>{GeneralConstants['project_nr_short']}</span>
								{projects[i].id}
							</div>
						</div>
						<div className='map-item__project-status status-container'>
							<div className={`status ${projects[i].status} status--set`}>
								{StatusConstants[`${projects[i].status}`]}
							</div>
						</div>
						<div className='map-item__project-right-wrapper'>
							<div className='map-item__project-distance'>
								<span className='map-item__project-label'>{GeneralConstants['distance']}</span>
								{distance.status == 'OK' ? distance.distance['text'] : GeneralConstants['not_found']}
							</div>
							{distance.status == 'OK' ? (
								<div className='map-item__project-url'>
									<a
										href={`https://www.google.no/maps/dir/?api=1&origin=${lat},${lng}&destination=${projects[i].client['addresses'][0]?.lat},${projects[i].client['addresses'][0]?.lng}&travelmode=driving`}
										target='_blank'
										rel='noopener noreferrer'
									>
										{GeneralConstants['go']}
									</a>
								</div>
							) : projects[i].client.addresses[0]?.address_line ? (
								<ClipboardLink address={projects[i].client.addresses[0]?.address_line} />
							) : null}
						</div>
					</div>
				))}
		</div>
	);
};

export default MyMapComponent;
