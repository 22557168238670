import { Radio } from 'informed';
import * as React from 'react';

const ProjectRadioFreeContainer = ({ value, extraClass, label, removeLabelClass }) => (
	<label className={removeLabelClass ? 'grid-item' : 'free-grid-item'}>
		<Radio value={value} className='regular' />
		<span className={extraClass}>{label}</span>
	</label>
);

export default ProjectRadioFreeContainer;
