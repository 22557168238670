import { PDFViewer } from '@react-pdf/renderer';
import * as React from 'react';

import PdfKitchen from '../../components/pdf/pdf.kitchen';

const TestPdf = () => (
	<PDFViewer>
		<PdfKitchen />
	</PDFViewer>
);
export default TestPdf;
