const ErrorConstants = {
	required: 'Obligatorisk felt',
	not_unique: 'Vennligst skriv en unik tittel/navn',
	not_valid: 'Feil data, vennligst se over at informasjon er riktig skrevet',
	end_datetime_before_start_datetime: 'Sluttdato er tidligere enn startdato',
	_required: 'obligatorisk felt',
	_not_unique: 'vennligst skriv en unik tittel/navn',
	_not_valid: 'feil data, vennligst se over at informasjon er riktig skrevet',
	_end_datetime_before_start_datetime: 'Sluttdato er tidligere enn startdato'
};

export default ErrorConstants;
