import * as React from 'react';

const ClipBoard = ({ address }) => {
	function copyToClipboard(e) {
		navigator.clipboard.writeText(address);
	}

	return (
		<div className='map-item__project-address map-item__project-address--clipboard' onClick={copyToClipboard}>
			{address}
		</div>
	);
};

export default ClipBoard;
