export const setMyLocalStorageProjects = (val) => {
	localStorage.setItem('rememberMyProjects', val);
};
export const setMyLocalStorageProjectsKw = (val) => {
	localStorage.setItem('rememberMyProjectsKw', val);
};
export const getMyLocalStorageProjects = () => {
	return localStorage.getItem('rememberMyProjects');
};
export const getMyLocalStorageProjectsKw = () => {
	return localStorage.getItem('rememberMyProjectsKw');
};

export const setMyLocalStorageProjectsComplaints = (val) => {
	localStorage.setItem('rememberComplaints', val);
};

export const getMyLocalStorageProjectsComplaints = () => {
	return localStorage.getItem('rememberComplaints');
};
