import * as React from 'react';
import { useSelector } from 'react-redux';

import GeneralConstants from '../../constants/general.constants';
import { EditFormContext } from '../../context/edit-form-context';
import { selectCurrentUser } from '../../redux/user/user.selectors';

const CommentFormDisplay = ({ targetId }) => {
	const {
		handleToggleDeleteConfirm,
		showDeleteConfirmID,
		handleCommentDelete,
		handleCommentEditMode,
		comments,
		allUsers,
		activeFormData
	} = React.useContext(EditFormContext);

	const currentUser = useSelector(selectCurrentUser);

	const commentArray = comments.filter((comment) => comment.target_id === targetId);

	const dateFormat = (time) => {
		const d = new Date(time);
		return d
			.toLocaleString('en-GB')
			.replace(`,`, ' -')
			.replace(`/`, '.')
			.replace(`/`, '.')
			.replace(/:[^:]*$/, '');
	};

	if (!commentArray.length) {
		return <></>;
	}

	return (
		<div className='free-grid-item full-width clear'>
			{commentArray.map((comment) => (
				<div className='comment-data-container' key={comment.id}>
					<div className='comment-data__content'>{comment.comment}</div>
					<div className='comment-data-wrapper'>
						<div className='comment-data-list'>
							{allUsers.map((user) => {
								if (user.id === comment.user_id) {
									return (
										<div className='comment-data__author' key={user.id}>
											{user?.firstname} {user?.lastname}
										</div>
									);
								}
							})}

							<div className='comment-data__date'>{dateFormat(comment?.modified_datetime)}</div>
						</div>

						<div className='comment-edit-delete-container comment-edit-delete-container--display'>
							{showDeleteConfirmID === comment.id && (
								<div className='delete-confirmation-container'>
									<span className='confirm-label'>{GeneralConstants['want_to_delete_comment']}</span>

									<a
										href=''
										className='delete-confirm'
										onClick={(e) => handleCommentDelete(e, comment.id)}
									>
										{GeneralConstants.yes}
									</a>
									<a
										href=''
										className='cancel-confirm'
										onClick={(e) => handleToggleDeleteConfirm(e, false)}
									>
										{GeneralConstants.no}
									</a>
								</div>
							)}

							<div className='comment-data__category'>{GeneralConstants[`${comment.group}`]}</div>
							{activeFormData.status === 'archived' ||
							parseInt(currentUser.id, 10) !== comment.user_id ? (
								<>
									<button
										type='button'
										className='comment-delete comment-delete--disabled'
										disabled
									/>
									<button type='button' className='comment-edit comment-edit--disabled' disabled />
								</>
							) : (
								<>
									<button
										type='button'
										className='comment-delete'
										onClick={(e) => handleToggleDeleteConfirm(e, comment.id)}
									/>
									<button
										type='button'
										className='comment-edit'
										onClick={(e) => handleCommentEditMode(e, comment.id)}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default CommentFormDisplay;
