import UserActionTypes from './user.types';

export const signInStart = (userAndPassword) => ({
	type: UserActionTypes.SIGN_IN_START,
	payload: userAndPassword
});

export const signInSuccess = (currentUser) => ({
	type: UserActionTypes.SIGN_IN_SUCCESS,
	payload: currentUser
});

export const signInFailure = (error) => ({
	type: UserActionTypes.SIGN_IN_FAILURE,
	payload: error
});

export const checkUserSession = () => ({
	type: UserActionTypes.CHECK_USER_SESSION
});

export const signOutStart = () => ({
	type: UserActionTypes.SIGN_OUT_START
});

export const signOutSuccess = (status) => ({
	type: UserActionTypes.SIGN_OUT_SUCCESS,
	payload: status
});

export const signOutFailure = (error) => ({
	type: UserActionTypes.SIGN_OUT_FAILURE,
	payload: error
});

export const fetchUserRoleStart = (id) => ({
	type: UserActionTypes.FETCH_USER_ROLES_START,
	payload: id
});

export const fetchUserRoleSuccess = (userRoles) => ({
	type: UserActionTypes.FETCH_USER_ROLES_SUCCESS,
	payload: userRoles
});

export const fetchUserRoleFailure = (error) => ({
	type: UserActionTypes.FETCH_USER_ROLES_FAILURE,
	payload: error
});

export const fetchUserActiveRoleStart = (id) => ({
	type: UserActionTypes.FETCH_USER_ACTIVE_ROLE_START,
	payload: id
});

export const fetchUserActiveRoleSuccess = (userRoles) => ({
	type: UserActionTypes.FETCH_USER_ACTIVE_ROLE_SUCCESS,
	payload: userRoles
});

export const fetchUserActiveRoleFailure = (error) => ({
	type: UserActionTypes.FETCH_USER_ACTIVE_ROLE_FAILURE,
	payload: error
});

export const changeUserRoleStart = (idAndLabel) => ({
	type: UserActionTypes.CHANGE_USER_ROLE_START,
	payload: idAndLabel
});

export const changeUserRoleSuccess = (userRoles) => ({
	type: UserActionTypes.CHANGE_USER_ROLE_SUCCESS,
	payload: userRoles
});

export const changeUserRoleFailure = (error) => ({
	type: UserActionTypes.CHANGE_USER_ROLE_FAILURE,
	payload: error
});
