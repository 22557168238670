import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { createStructuredSelector } from 'reselect';

import CategoryConstants from '../../constants/category.constants';
import GeneralConstants from '../../constants/general.constants';
import NotFound from '../../pages/not-found/not-found.component';
import { fetchUserRoleStart } from '../../redux/user/user.actions';
import { selectCurrentUser, selectCurrentUserRoles } from '../../redux/user/user.selectors';
import {
	getMyLocalStorageProjects,
	getMyLocalStorageProjectsComplaints,
	getMyLocalStorageProjectsKw,
	setMyLocalStorageProjects,
	setMyLocalStorageProjectsComplaints,
	setMyLocalStorageProjectsKw
} from '../../utils/localStorageHelper';
import ErrorMessage from '../error/error.component';
import LoadMore from '../load-more/load-more.component';
import ProjectSearch from '../project-search/project-search.component';
import ProjectStatus from '../project-status/project-status.component';
import ProjectTitle from '../project-title/project-title.component';
import Spinner from '../spinner/spinner.component';

const api = window.api;

const setClassNames = (sort, sortBy, headerTitle) => {
	return sort === '' && sortBy === headerTitle ? 'desc' : sort === '-' && sortBy === headerTitle ? 'asc' : 'no-order';
};

class ProjectList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			limit: 10,
			offset: 0,
			sort: '-',
			sortBy: 'id',
			projects: [],
			error: null,
			errorMessage: '',
			projectCount: 0,
			isFetching: true,
			searchProject: '',
			showMyProject: '',
			showProjectComplains: '',
			projectTypes: this.props.projectTypes,
			fields: 'title,id,category,status,status_invoiced_datetime,start_production_datetime,end_production_datetime,start_assembly_datetime,end_assembly_datetime,start_transport_datetime,end_transport_datetime&end_project_datetime=1'
		};
	}

	dateFormat = (time) => {
		const d = new Date(time);
		return d
			.toLocaleDateString('en-GB')
			.replace(`, `, '-')
			.replace(`/`, '.')
			.replace(`/`, '.')
			.replace(/:[^:]*$/, '');
	};

	async componentDidMount() {
		const { currentUser } = this.props;
		this.props.fetchUserRoleStart({ id: currentUser.id });
		const { limit, offset, sort, sortBy, searchProject, showMyProject, projectTypes, fields } = this.state;
		try {
			const projectCountPromise = api.projects.get(`projectcount?project_status=${projectTypes}`);

			const projectPromise = api.projects.get(
				`?fields=${fields}&limit=${limit}&offset=${offset}&project_search=${
					getMyLocalStorageProjectsKw() || searchProject
				}&my_projects=${getMyLocalStorageProjects() || showMyProject}&project_status=${
					getMyLocalStorageProjectsComplaints() ? 'complaints' : projectTypes
				}&sort=${sort}${sortBy},${sort}${sortBy}&includes=brand_name,user_sales`
			);

			const projectCount = await projectCountPromise;

			const projects = await projectPromise;

			await Promise.all([
				this.setInitialState(
					projectCount,
					projects,
					getMyLocalStorageProjectsKw(),
					getMyLocalStorageProjects(),
					getMyLocalStorageProjectsComplaints()
				)
			]);
		} catch (error) {
			this.setServerError(error);
		}
	}

	setInitialState = (projectCount, projects, searchProject, showMyProject, complaints) => {
		this.setState((prevState) => ({
			...prevState,
			searchProject: searchProject || prevState.searchProject,
			showMyProject: showMyProject || prevState.showMyProject,
			projects,
			showProjectComplains: complaints,
			projectCount: projectCount.count,
			offset: prevState.offset + 10,
			isFetching: false,
			error: false
		}));
	};

	fetchProjects = () => {
		const {
			limit,
			offset,
			sort,
			sortBy,
			searchProject,
			showMyProject,
			projectTypes,
			projectCount,
			fields,
			showProjectComplains
		} = this.state;

		api.projects
			.get(
				`?fields=${fields}&sort=${sort}${sortBy},${sort}id&limit=${limit}&offset=${offset}&project_search=${searchProject}&my_projects=${showMyProject}&includes=brand_name,user_sales&project_status=${
					showProjectComplains ? 'complaints' : projectTypes
				}`
			)
			.then((res) =>
				this.setProjects(
					res,
					projectCount,
					sort,
					sortBy,
					searchProject,
					showMyProject,
					showProjectComplains,
					offset
				)
			)
			.catch((err) => this.setServerError(err));
	};

	searchProjects = async (values) => {
		const { limit, sort, projectTypes, sortBy, fields } = this.state;

		const offset = 10;

		const searchProject = values.search_project && values.search_project.length > 2 ? values.search_project : '';

		const showMyProject = values.show_my_projects ? true : '';

		const showProjectComplains = values.show_projects_complaints ? true : '';

		setMyLocalStorageProjectsKw(searchProject);
		setMyLocalStorageProjects(showMyProject);
		setMyLocalStorageProjectsComplaints(showProjectComplains);

		try {
			const projectCountPromise = api.projects.get(
				`projectcount?&project_search=${searchProject}&my_projects=${showMyProject}&project_status=${
					showProjectComplains ? 'complaints' : projectTypes
				}`
			);

			const projectPromise = api.projects.get(
				`?fields=${fields}&limit=${limit}&offset=0&project_search=${searchProject}&my_projects=${showMyProject}&includes=brand_name,user_sales&project_status=${
					showProjectComplains ? 'complaints' : projectTypes
				}&sort=${sort}${sortBy},${sort}id`
			);

			const projectCount = await projectCountPromise;

			const project = await projectPromise;

			await Promise.all([
				this.setSearchedProjects(
					project,
					projectCount.count,
					sort,
					sortBy,
					searchProject,
					showMyProject,
					showProjectComplains,
					offset
				)
			]);
		} catch (error) {
			this.setServerError(error);
		}
	};

	setProjects = (
		projects,
		projectCount,
		sort,
		sortBy,
		searchProject,
		showMyProject,
		showProjectComplains,
		offset
	) => {
		this.setState((prevState) => ({
			...prevState,
			projects: [...prevState.projects, ...projects],
			sort,
			sortBy,
			searchProject,
			showMyProject,
			showProjectComplains,
			offset: offset + 10,
			error: null,
			isFetching: false
		}));
	};

	setSearchedProjects = (
		projects,
		projectCount,
		sort,
		sortBy,
		searchProject,
		showMyProject,
		showProjectComplains,
		setOffset
	) => {
		this.setState((prevState) => ({
			...prevState,
			projects,
			projectCount,
			sort,
			sortBy,
			searchProject,
			showMyProject,
			showProjectComplains,
			error: null,
			offset: setOffset,
			isFetching: false
		}));
	};

	sortProjects = (sortByTh) => {
		const {
			limit,
			sort,
			projectTypes,
			sortBy,
			projectCount,
			searchProject,
			showMyProject,
			fields,
			showProjectComplains
		} = this.state;

		const offset = 10;

		let sortDirection = sort;

		if (sortByTh === sortBy) {
			if (sort === '-') {
				sortDirection = '';
			} else {
				sortDirection = '-';
			}
		} else {
			sortDirection = '-';
		}

		api.projects
			.get(
				`?fields=${fields}&limit=${limit}&offset=0&project_search=${searchProject}&my_projects=${showMyProject}&includes=brand_name,user_sales&project_status=${
					showProjectComplains ? 'complaints' : projectTypes
				}&sort=${sortDirection}${sortByTh},${sortDirection}id`
			)
			.then((res) =>
				this.setSearchedProjects(
					res,
					projectCount,
					sortDirection,
					sortByTh,
					searchProject,
					showMyProject,
					showProjectComplains,
					offset
				)
			)
			.catch((err) => this.setServerError(err));
	};

	setServerError = (error) => {
		this.setState((prevState) => ({
			...prevState,
			isFetching: false,
			error,
			errorMessage: true
		}));
		setTimeout(() => this.setState({ errorMessage: false }), 2000);
	};

	render() {
		const { currentUserRoles } = this.props;

		const isAdmin = currentUserRoles.filter((role) => role.label === 'admin');

		const { match } = this.props;

		const { projects, offset, projectCount, isFetching, sort, sortBy, errorMessage } = this.state;

		return (
			<div className='project-data-container'>
				<CSSTransition
					in={isFetching}
					appear
					timeout={{
						appear: 300,
						exit: 150
					}}
				>
					<Spinner />
				</CSSTransition>
				{this.state.error && this.state.error.id === 0 && this.state.error.http_code === 404 ? (
					<NotFound />
				) : (
					errorMessage && (
						<CSSTransition
							in={errorMessage}
							appear
							timeout={{
								appear: 3000,
								exit: 0
							}}
						>
							<ErrorMessage
								errorID={
									this.state?.error?.id
										? this.state.error.id
										: this.state?.error?.status
										? this.state.error.status
										: ''
								}
							/>
						</CSSTransition>
					)
				)}

				<ProjectTitle
					title={
						match.url === '/project-archive'
							? GeneralConstants?.archive_projects
							: GeneralConstants?.active_projects
					}
				/>

				<ProjectSearch onSubmit={this.searchProjects} />
				<div className='project-log-list'>
					<InfiniteScroll
						dataLength={projects.length}
						next={this.fetchProjects}
						hasMore={offset < projectCount}
						loader={<LoadMore title={GeneralConstants?.load_more_projects} />}
					>
						<table className='project-active'>
							<thead>
								<tr>
									<th
										onClick={() => this.sortProjects('title')}
										className={setClassNames(sort, sortBy, 'title')}
									>
										{GeneralConstants?.project_title}
									</th>
									<th
										onClick={() => this.sortProjects('category')}
										className={setClassNames(sort, sortBy, 'category')}
									>
										Type
									</th>
									<th>Selger</th>
									<th
										onClick={() => this.sortProjects('id')}
										className={setClassNames(sort, sortBy, 'id')}
									>
										Nummer
									</th>
									<th
										onClick={() => this.sortProjects('status')}
										className={setClassNames(sort, sortBy, 'status')}
									>
										Status
									</th>
									<th
										onClick={() => this.sortProjects('status_invoiced_datetime')}
										className={setClassNames(sort, sortBy, 'status_invoiced_datetime')}
									>
										Fakturert
									</th>
									<th
										onClick={() => this.sortProjects('end_project_datetime')}
										className={setClassNames(sort, sortBy, 'end_project_datetime')}
									>
										Leveringsdato
									</th>
								</tr>
							</thead>
							<tbody>
								{projects.map((project) => (
									<tr key={project.id}>
										<td>
											<Link to={`${match.path}/${project.id}`}>{project.title}</Link>
										</td>
										<td>
											<Link to={`${match.path}/${project.id}`}>
												{CategoryConstants[`${project.category}`]}{' '}
												{project?.brand_name && project?.brand_name === 'not_found'
													? null
													: ` - ${project?.brand_name}`}
											</Link>
										</td>
										<td>
											<Link to={`${match.path}/${project.id}`}>
												{project?.user_sales?.[0]?.name}
											</Link>
										</td>
										<td>
											<Link to={`${match.path}/${project.id}`}>{project.id}</Link>
										</td>
										<td>
											<ProjectStatus
												project={project}
												canSetArchiveMode={isAdmin.length}
												handleProjectStatusOrder={() =>
													this.sortProjects('status_invoiced_datetime')
												}
											/>
										</td>
										<td>
											<Link to={`${match.path}/${project.id}`}>
												{project?.status_invoiced_datetime
													? this.dateFormat(project?.status_invoiced_datetime)
													: ''}
											</Link>
										</td>
										<td>
											<Link to={`${match.path}/${project.id}`}>
												{project.end_project_datetime
													? this.dateFormat(project.end_project_datetime)
													: ''}
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</div>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	currentUserRoles: selectCurrentUserRoles
});

const mapDispatchToProps = (dispatch) => ({
	fetchUserRoleStart: (id) => dispatch(fetchUserRoleStart(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
