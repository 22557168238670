export const setCalenderEvents = ({
	project,
	roleDescription,
	clientList,
	startDate,
	endDate,
	serverUsers = [],
	projectCategory = []
}) => {
	const filterRelatedUsers = (category) =>
		serverUsers
			.filter((user) => category.includes(user.id))
			.map((detailInfo) => {
				return ` ${detailInfo.firstname} ${detailInfo.lastname}`;
			});

	const mapUsersByName = filterRelatedUsers(projectCategory);

	return {
		title: `${project.title} | ${project.id} ${
			clientList[project.client_id] ? clientList[project.client_id]['title'] : ''
		} | ${roleDescription} ${mapUsersByName}`,
		start: `${startDate}`,
		end: `${endDate}`,
		classNames: [
			`${project.status}`,
			`${project.category}`,
			`${project.is_knapphus_brand === 1 ? 'knapphus' : ''}`
		],
		url: `project-active/${project.id}`
	};
};

export const timeConvert = (n) => {
	let num = n;

	let hours = num / 60;

	let rhours = Math.floor(hours);

	let minutes = (hours - rhours) * 60;

	let rminutes = Math.round(minutes) > 0 ? Math.round(minutes) : '00';
	return ` - ${rhours}h ${rminutes}m`;
};
