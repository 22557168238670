import { ArrayField, Checkbox, Form, Option, Radio, RadioGroup, Select, Text, useFormApi } from 'informed';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import ErrorConstants from '../../constants/error.constants';
import GeneralConstants from '../../constants/general.constants';
import CommentFormDisplay from '../comments/comment-form-display.component';
import DemolitionAndServices from '../demolition-and-services/demolition-and-services.component';
import ErrorMessage from '../error/error.component';
import ProjectFooter from '../project-footer/project-footer.component';
import DropDownHeaderComponent from '../project-form-elements/dropdown-header-container/dropdown-header-container.component';
import LocationSearchInput from '../project-form-elements/google-search/google-search.component';
import ProjectAddCommentButton from '../project-form-elements/project-add-comment-button/project-add-comment-button.component';
import ProjectAddGeneralComment from '../project-form-elements/project-add-comment-button/project-add-general-comment.component';
import ProjectFormEmailInput from '../project-form-elements/project-form-email-input/project-form-email-input.component';
import ProjectFormTextInput from '../project-form-elements/project-form-text-input/project-form-text-input.component';
import ProjectTypeRadioGroup from '../project-form-elements/project-type-radio-group/project-type-radio-group.component';
import ProjectFormErrorGlobal from '../project-form-messages/project-form-error-global.component';

const ComponentUsingFormApi = ({ field, fieldOwn, initialValue }) => {
	const formApi = useFormApi();
	return (
		<Text
			type='text'
			field={fieldOwn}
			initialValue={initialValue}
			onChange={(ev) => {
				if (ev.target.value.length === 4) {
					const res = handlePostalCode(ev.target.value);
					res.then((res) => formApi.setValue(field, res));
				}
			}}
		/>
	);
};

const handlePostalCode = (postalCode) => {
	if (postalCode.length === 4) {
		return fetch(`https://fraktguide.bring.no/fraktguide/postalCode.json?pnr=${postalCode}`)
			.then((res) => res.json())
			.then((res) => (res.valid ? res.result : ''))
			.catch((error) => console.log(error));
	}
};

class ProjectFormFirstPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedClientId: '',
			showDemolitionAndServices: true,
			showDeliveryAndProject: true
		};
	}

	convertTime = (n) => `${(n / 60) ^ 0}:${n % 60}`;

	dateFormat = (time) => {
		const d = new Date(time);
		return d
			.toLocaleString('en-GB')
			.replace(`, `, '-')
			.replace(`/`, '.')
			.replace(`/`, '.')
			.replace(/:[^:]*$/, '');
	};

	toggleDropDown = (ev, group) => {
		ev.preventDefault();

		if (group === 'demolition_and_services') {
			if (this.state.showDemolitionAndServices === false || this.state.showDemolitionAndServices === 'false') {
				this.setState(
					(prevState) => ({
						...prevState,
						showDemolitionAndServices: true
					}),
					() => this.updateLocalStorage()
				);
			} else {
				this.setState(
					(prevState) => ({
						...prevState,
						showDemolitionAndServices: false
					}),
					() => this.updateLocalStorage()
				);
			}
		} else if (group === 'delivery_and_project') {
			if (this.state.showDeliveryAndProject === false || this.state.showDeliveryAndProject === 'false') {
				this.setState(
					(prevState) => ({
						...prevState,
						showDeliveryAndProject: true
					}),
					() => this.updateLocalStorage()
				);
			} else {
				this.setState(
					(prevState) => ({
						...prevState,
						showDeliveryAndProject: false
					}),
					() => this.updateLocalStorage()
				);
			}
		}
	};

	updateLocalStorage = () => {
		localStorage.setItem('showDemolitionAndServices', this.state.showDemolitionAndServices);
		localStorage.setItem('showDeliveryAndProject', this.state.showDeliveryAndProject);
	};

	componentDidMount() {
		const showDemolitionAndServices = localStorage.getItem('showDemolitionAndServices');

		const showDeliveryAndProject = localStorage.getItem('showDeliveryAndProject');
		this.setState((prevState) => ({
			showDemolitionAndServices,
			showDeliveryAndProject
		}));
	}

	render() {
		const { showDemolitionAndServices, showDeliveryAndProject } = this.state;

		const {
			serverClientList,
			serverUserRoles,
			error: serverError,
			activeFormData,
			activeAddressData,
			serviceList,
			servicePersonList,
			showServices,
			page,
			serviceTypeAndMaterial,
			comments,
			handleOnSubmit,
			handleRemoveAddress,
			handleShowComments,
			handlePageSwitch,
			handleProjectStatusChange,
			projectStatus,
			dbFiles,
			dbImages,
			handleStepCommentCount,
			handlePageOneData,
			handlePageTwoData,
			handleCalendarClick,
			trackedTime,
			handleAddAddress
		} = this.props;

		const user_ids_assembly_initial_value = activeFormData.user_ids_assembly
			? activeFormData.user_ids_assembly
			: [];

		const user_ids_transport_initial_value = activeFormData.user_ids_transport
			? activeFormData.user_ids_transport
			: [];

		const user_ids_project_manager_initial_value = activeFormData.user_ids_project_manager
			? activeFormData.user_ids_project_manager
			: [];

		const user_ids_sales_initial_value = activeFormData.user_ids_sales ? activeFormData.user_ids_sales : [];

		const user_ids_production_initial_value = activeFormData.user_ids_production
			? activeFormData.user_ids_production
			: [];

		let initialValue = {
			client_address: [{}]
		};
		if (activeAddressData.length) {
			initialValue = {
				client_address: this.props.activeAddressData
			};
		}

		return (
			<Form
				onSubmit={(values) => handleOnSubmit(values)}
				className='add-new-project'
				autoComplete='off'
				initialValues={initialValue}
			>
				{({ formState }) => (
					<>
						<Text field='id' type='hidden' initialValue={activeFormData.id} />
						<ProjectTypeRadioGroup
							activeFormData={`${activeFormData.category ? activeFormData.category : 'project_main'}`}
							serverError={serverError?.body?.category ? serverError.body : null}
						/>

						<div className='section-container'>
							<div className='local-grid local-grid--col-2 separate-line'>
								<ProjectFormTextInput
									name='title'
									label={GeneralConstants['project_title']}
									activeFormData={`${activeFormData.title ? activeFormData.title : ''}`}
									serverError={serverError?.body?.title ? ErrorConstants[serverError.body.title] : ''}
								/>
								{activeFormData.parent_id ? (
									<ProjectFormTextInput
										label={GeneralConstants['select_main_project']}
										activeFormData={`${activeFormData.parent_id ? activeFormData.parent_id : ''}`}
										name='parent_id'
										serverError={
											serverError?.body?.parent_id
												? ErrorConstants[serverError.body.parent_id]
												: ''
										}
										disabledField
									/>
								) : (
									<Text field='parent_id' type='hidden' />
								)}
							</div>
							<div className='local-grid local-grid--col-2'>
								<label className='grid-item grid-item--relative'>
									<span className='input-description'>{GeneralConstants?.select_client}</span>
									<Select
										field='client_id'
										disabled
										initialValue={`${activeFormData.client_id ? activeFormData.client_id : ''}`}
										onChange={(ev) => this.setState({ selectedClientId: ev.target.value })}
										className={serverError?.body?.client_id ? 'error-field' : ''}
									>
										{serverClientList.map((serverClient) => (
											<Option value={serverClient.id} key={serverClient.id}>
												{serverClient.title}
											</Option>
										))}
									</Select>
									{serverError?.body?.client_id ? (
										<span className='error-inline'>
											{ErrorConstants[serverError.body.client_id]}
										</span>
									) : null}
								</label>
								{this.state.selectedClientId ? (
									serverClientList.map((serverClient) => {
										if (serverClient.id == this.state.selectedClientId) {
											return (
												<label className='grid-item' key={serverClient.id}>
													<span className='field-description'>
														{GeneralConstants['phone_nr']}
													</span>
													<Text
														field='client_phone'
														type='text'
														initialValue={`${serverClient.phone}`}
													/>
												</label>
											);
										}
									})
								) : (
									<>
										<ProjectFormTextInput
											name='client_phone'
											label={GeneralConstants['phone_nr']}
											activeFormData={`${
												activeFormData.client_phone ? activeFormData.client_phone : ''
											}`}
										/>
									</>
								)}

								{this.state.selectedClientId ? (
									serverClientList.map((serverClient) => {
										if (serverClient.id == this.state.selectedClientId) {
											return (
												<label className='grid-item' key={serverClient.id}>
													<span className='field-description'>{GeneralConstants?.email}</span>
													<Text
														field='client_email'
														type='text'
														initialValue={`${serverClient.email}`}
														className={serverError?.body?.client_email ? 'error-field' : ''}
													/>
													{serverError?.body?.client_email ? (
														<span className='error-inline'>
															{ErrorConstants[serverError.body.client_email]}
														</span>
													) : null}
												</label>
											);
										}
									})
								) : (
									<ProjectFormEmailInput
										label={GeneralConstants?.email}
										name='client_email'
										activeFormData={`${
											activeFormData.client_email ? activeFormData.client_email : ''
										}`}
										serverError={
											serverError?.body?.client_email
												? ErrorConstants[serverError.body.client_email]
												: null
										}
									/>
								)}
								<ArrayField field='client_address'>
									{({ add, fields }) => {
										return (
											<>
												{fields.map(({ field, key, remove, initialValue, render }, i) => {
													return (
														<div
															className='local-grid local-grid--col-2 separate-line'
															key={key}
														>
															<label className='grid-item'>
																<span className='field-description'>
																	{GeneralConstants['google_address']}
																</span>
																<LocationSearchInput
																	getAddressData={(ev) => handleAddAddress(ev)}
																	project_id={activeFormData.id}
																	id={initialValue?.id}
																	google_address={initialValue?.google_address}
																	iteration={key}
																/>
																<Text
																	field={`${field}.id`}
																	initialValue={initialValue?.id}
																	type='hidden'
																/>
																<Text
																	field={`${field}.project_id`}
																	initialValue={initialValue?.project_id}
																	type='hidden'
																/>
																<Text
																	field={`${field}.iteration`}
																	type='hidden'
																	initialValue={key}
																/>
															</label>
															<label className='grid-item'>
																<span className='field-description'>
																	{GeneralConstants?.address}
																</span>
																<Text
																	field={`${field}.address_line`}
																	initialValue={initialValue?.address_line}
																	type='text'
																	allowEmptyString
																/>
															</label>
															<fieldset className='grid-item grid-item--clear local-grid local-grid--col-2'>
																<label className='grid-item grid-item--relative'>
																	<span className='field-description'>
																		{GeneralConstants?.postal_code}
																	</span>
																	<ComponentUsingFormApi
																		fieldOwn={`${field}.postal_code`}
																		initialValue={initialValue?.postal_number}
																		type='text'
																		allowEmptyString
																		field={`${field}.postal_location`}
																		postalCode={
																			formState.values?.client_address?.[i]
																				?.postal_code
																		}
																	/>
																</label>
																<label className='grid-item'>
																	<span className='field-description'>
																		{GeneralConstants?.postal_place}
																	</span>

																	<Text
																		field={`${field}.postal_location`}
																		initialValue={initialValue?.postal_location}
																		type='text'
																		allowEmptyString
																	/>
																</label>
															</fieldset>
															<div className='btn-container btn-container--right'>
																{activeFormData.status == 'archived' ? (
																	<button
																		className='btn-delete'
																		title='Remove Address'
																		disabled
																	>
																		{GeneralConstants?.delete_address}
																	</button>
																) : initialValue && initialValue.id ? (
																	<button
																		className='btn-delete'
																		type='button'
																		title='Remove Address'
																		onClick={() =>
																			handleRemoveAddress(
																				remove,
																				i,
																				initialValue.id
																			)
																		}
																	>
																		{GeneralConstants?.delete_address}
																	</button>
																) : (
																	<button
																		className='btn-delete'
																		type='button'
																		title='Remove Address'
																		onClick={() => handleRemoveAddress(remove, key)}
																	>
																		{GeneralConstants?.delete_address}
																	</button>
																)}
															</div>
														</div>
													);
												})}
												{serverError?.body?.address_line && (
													<ProjectFormErrorGlobal
														title={`${GeneralConstants?.address} - ${
															ErrorConstants[`_${serverError.body.address_line}`]
														} `}
														extraClass
													/>
												)}
												{serverError?.body?.postal_code && (
													<ProjectFormErrorGlobal
														title={`${GeneralConstants?.postal_code} - ${
															ErrorConstants[`_${serverError.body.postal_code}`]
														} `}
														extraClass
													/>
												)}
												{serverError?.body?.postal_location && (
													<ProjectFormErrorGlobal
														title={`${GeneralConstants?.postal_place} - ${
															ErrorConstants[`_${serverError.body.postal_location}`]
														} `}
														extraClass
													/>
												)}
												<div className='btn-container btn-container--left'>
													<button
														className='btn small light wide'
														type='button'
														title={GeneralConstants?.add_address}
														onClick={add}
													>
														{GeneralConstants?.add_address}
													</button>
												</div>
											</>
										);
									}}
								</ArrayField>
							</div>
						</div>
						<div className='dropdown-container'>
							<DropDownHeaderComponent
								title={GeneralConstants?.delivery_and_project}
								toggleDropDownContent={(ev) => this.toggleDropDown(ev, 'delivery_and_project')}
								handleShowComments={handleShowComments}
								extraClass
								targetId='delivery_and_project_header'
								group='delivery_and_project'
								comments={comments}
								label={GeneralConstants?.delivery_and_project_header}
								showContentBelow={
									showDeliveryAndProject == false || showDeliveryAndProject == 'false'
										? 'dropdown-container__header--closed'
										: null
								}
							/>
							<div
								className={`local-grid local-grid--col-1 dropdown-content-initial-options ${
									showDeliveryAndProject == false || showDeliveryAndProject == 'false'
										? 'local-grid--hidden'
										: null
								}`}
							>
								<CommentFormDisplay targetId='delivery_and_project_header' />
								<div className='local-grid local-grid--col-2'>
									<fieldset className='grid-item button-and-input-container'>
										<ProjectAddCommentButton
											handleShowComments={handleShowComments}
											extraClass
											targetId='transport_delivery'
											group='delivery_and_project'
											comments={comments}
											label={GeneralConstants?.planned_delivery_date_transport}
										/>
										<label className='button-and-input-wrapper'>
											<span className='input-description'>
												{GeneralConstants?.planned_delivery_date_transport}
											</span>
											<button
												type='button'
												onClick={(ev) =>
													handleCalendarClick(ev, {
														start_transport_datetime:
															activeFormData.start_transport_datetime,
														end_transport_datetime: activeFormData.end_transport_datetime,
														status: activeFormData.status,
														category: activeFormData.category
													})
												}
												className='look-like-input'
											>
												{activeFormData.start_transport_datetime &&
													`${this.dateFormat(activeFormData.start_transport_datetime)}`}
												{activeFormData.end_transport_datetime &&
													` - ${this.dateFormat(activeFormData.end_transport_datetime)}`}
												{!activeFormData.start_transport_datetime &&
													!activeFormData.end_transport_datetime &&
													`${GeneralConstants?.choose_date}`}
											</button>
										</label>
									</fieldset>

									<CommentFormDisplay targetId='transport_delivery' />

									<fieldset className='grid-item button-and-input-container'>
										<ProjectAddCommentButton
											handleShowComments={handleShowComments}
											extraClass
											targetId='user_ids_transport'
											group='delivery_and_project'
											comments={comments}
											label={GeneralConstants?.transport}
										/>
										<div className='button-and-input-wrapper'>
											<span className='input-description'>{GeneralConstants?.transport}</span>
											<div className='select-input-container'>
												<Select
													field='user_ids_transport'
													multiple
													className={`${
														activeFormData.hours_transport &&
														trackedTime &&
														trackedTime.transport
															? Math.floor(
																	(trackedTime.transport * 100) /
																		(activeFormData.hours_transport * 60) /
																		10
															  ) > 10
																? 'fill-10'
																: `fill-${Math.floor(
																		(trackedTime.transport * 100) /
																			(activeFormData.hours_transport * 60) /
																			10
																  )}`
															: ''
													}`}
													initialValue={user_ids_transport_initial_value}
												>
													{serverUserRoles.map((serverUserRole) =>
														serverUserRole.availableroles.map((role) => {
															if (role.label == 'transport') {
																return (
																	<Option
																		value={serverUserRole.id}
																		key={serverUserRole.id}
																	>
																		{serverUserRole.firstname}{' '}
																		{serverUserRole.lastname}
																	</Option>
																);
															}
														})
													)}
												</Select>
												<label
													data-hours={`${
														trackedTime && trackedTime.transport
															? this.convertTime(trackedTime.transport)
															: `0`
													}/`}
													data-hours-symb='h'
													className='spend-time'
												>
													<Text
														field='hours_transport'
														type='number'
														min='0'
														initialValue={`${
															activeFormData.hours_transport
																? activeFormData.hours_transport
																: '0'
														}`}
													/>
												</label>
											</div>
										</div>
									</fieldset>

									<CommentFormDisplay targetId='user_ids_transport' />

									<fieldset className='grid-item button-and-input-container'>
										<ProjectAddCommentButton
											handleShowComments={handleShowComments}
											extraClass
											targetId='assembly_delivery'
											group='delivery_and_project'
											comments={comments}
											label={GeneralConstants?.planned_delivery_date_assembly}
										/>
										<label className='button-and-input-wrapper'>
											<span className='input-description'>
												{GeneralConstants?.planned_delivery_date_assembly}
											</span>
											<button
												type='button'
												onClick={(ev) =>
													handleCalendarClick(ev, {
														start_assembly_datetime: activeFormData.start_assembly_datetime,
														end_assembly_datetime: activeFormData.end_assembly_datetime,
														status: activeFormData.status,
														category: activeFormData.category
													})
												}
												className='look-like-input'
											>
												{activeFormData.start_assembly_datetime &&
													`${this.dateFormat(activeFormData.start_assembly_datetime)}`}
												{activeFormData.end_assembly_datetime &&
													` - ${this.dateFormat(activeFormData.end_assembly_datetime)}`}
												{!activeFormData.start_assembly_datetime &&
													!activeFormData.end_assembly_datetime &&
													`${GeneralConstants?.choose_date}`}
											</button>
										</label>
									</fieldset>

									<CommentFormDisplay targetId='assembly_delivery' />

									<fieldset className='grid-item button-and-input-container'>
										<ProjectAddCommentButton
											handleShowComments={handleShowComments}
											extraClass
											targetId='user_ids_assembly'
											group='delivery_and_project'
											comments={comments}
											label={GeneralConstants?.assembly}
										/>
										<div className='button-and-input-wrapper'>
											<span className='input-description'>{GeneralConstants?.assembly}</span>
											<div className='select-input-container'>
												<Select
													field='user_ids_assembly'
													multiple
													className={`${
														activeFormData.hours_assembly &&
														trackedTime &&
														trackedTime.assembly
															? Math.floor(
																	(trackedTime.assembly * 100) /
																		(activeFormData.hours_assembly * 60) /
																		10
															  ) > 10
																? 'fill-10'
																: `fill-${Math.floor(
																		(trackedTime.assembly * 100) /
																			(activeFormData.hours_assembly * 60) /
																			10
																  )}`
															: ''
													}`}
													initialValue={user_ids_assembly_initial_value}
												>
													{serverUserRoles.map((serverUserRole) =>
														serverUserRole.availableroles.map((role) => {
															if (role.label == 'assembly') {
																return (
																	<Option
																		value={serverUserRole.id}
																		key={serverUserRole.id}
																	>
																		{serverUserRole.firstname}{' '}
																		{serverUserRole.lastname}
																	</Option>
																);
															}
														})
													)}
												</Select>
												<label
													data-hours={`${
														trackedTime && trackedTime.assembly
															? this.convertTime(trackedTime.assembly)
															: `0`
													}/`}
													data-hours-symb='h'
													className='spend-time'
												>
													<Text
														field='hours_assembly'
														type='number'
														min='0'
														initialValue={`${
															activeFormData.hours_assembly
																? activeFormData.hours_assembly
																: '0'
														}`}
													/>
												</label>
											</div>
										</div>
									</fieldset>

									<CommentFormDisplay targetId='user_ids_assembly' />

									<fieldset className='grid-item button-and-input-container'>
										<ProjectAddCommentButton
											handleShowComments={handleShowComments}
											extraClass
											targetId='production_delivery'
											group='delivery_and_project'
											comments={comments}
											label={GeneralConstants?.planned_delivery_date_production}
										/>
										<label className='button-and-input-wrapper'>
											<span className='input-description'>
												{GeneralConstants?.planned_delivery_date_production}
											</span>
											<button
												type='button'
												onClick={(ev) =>
													handleCalendarClick(ev, {
														start_production_datetime:
															activeFormData.start_production_datetime,
														end_production_datetime: activeFormData.end_production_datetime,
														status: activeFormData.status,
														category: activeFormData.category
													})
												}
												className='look-like-input'
											>
												{activeFormData.start_production_datetime &&
													`${this.dateFormat(activeFormData.start_production_datetime)}`}
												{activeFormData.end_production_datetime &&
													` - ${this.dateFormat(activeFormData.end_production_datetime)}`}
												{!activeFormData.start_production_datetime &&
													!activeFormData.end_production_datetime &&
													`${GeneralConstants?.choose_date}`}
											</button>
										</label>
									</fieldset>

									<CommentFormDisplay targetId='production_delivery' />

									<fieldset className='grid-item button-and-input-container'>
										<ProjectAddCommentButton
											handleShowComments={handleShowComments}
											extraClass
											targetId='user_ids_production'
											group='delivery_and_project'
											comments={comments}
											label={GeneralConstants?.production}
										/>
										<div className='button-and-input-wrapper'>
											<span className='input-description'>{GeneralConstants?.production}</span>
											<div className='select-input-container'>
												<Select
													field='user_ids_production'
													multiple
													className={`${
														activeFormData.hours_production &&
														trackedTime &&
														trackedTime.production
															? Math.floor(
																	(trackedTime.production * 100) /
																		(activeFormData.hours_production * 60) /
																		10
															  ) > 10
																? 'fill-10'
																: `fill-${Math.floor(
																		(trackedTime.production * 100) /
																			(activeFormData.hours_production * 60) /
																			10
																  )}`
															: ''
													}`}
													initialValue={user_ids_production_initial_value}
												>
													{serverUserRoles.map((serverUserRole) =>
														serverUserRole.availableroles.map((role) => {
															if (role.label == 'production') {
																return (
																	<Option
																		value={serverUserRole.id}
																		key={serverUserRole.id}
																	>
																		{serverUserRole.firstname}{' '}
																		{serverUserRole.lastname}
																	</Option>
																);
															}
														})
													)}
												</Select>
												<label
													data-hours={`${
														trackedTime && trackedTime.production
															? this.convertTime(trackedTime.production)
															: `0`
													}/`}
													data-hours-symb='h'
													className='spend-time'
												>
													<Text
														field='hours_production'
														type='number'
														min='0'
														initialValue={`${
															activeFormData.hours_production
																? activeFormData.hours_production
																: '0'
														}`}
													/>
												</label>
											</div>
										</div>
									</fieldset>

									<CommentFormDisplay targetId='user_ids_production' />

									<label className='grid-item'>
										<span className='input-description'>{GeneralConstants?.project_manager}</span>
										<Select
											field='user_ids_project_manager'
											multiple
											className='multi-select'
											initialValue={user_ids_project_manager_initial_value}
										>
											{serverUserRoles.map((serverUserRole) =>
												serverUserRole.availableroles.map((role) => {
													if (role.label == 'project_manager') {
														return (
															<Option value={serverUserRole.id} key={serverUserRole.id}>
																{serverUserRole.firstname} {serverUserRole.lastname}
															</Option>
														);
													}
												})
											)}
										</Select>
									</label>
									<label className='grid-item'>
										<span className='input-description'>Selger</span>
										<Select
											field='user_ids_sales'
											multiple
											className='multi-select'
											initialValue={user_ids_sales_initial_value}
										>
											{serverUserRoles.map((serverUserRole) =>
												serverUserRole.availableroles.map((role) => {
													if (role.label === 'sales') {
														return (
															<Option value={serverUserRole.id} key={serverUserRole.id}>
																{serverUserRole.firstname} {serverUserRole.lastname}
															</Option>
														);
													}
												})
											)}
										</Select>
									</label>
								</div>
							</div>
							<div
								className={`local-grid local-grid--col-2 separate-line ${
									showDeliveryAndProject == false || showDeliveryAndProject == 'false'
										? 'local-grid--hidden'
										: null
								}`}
							>
								<RadioGroup
									field='type'
									initialValue={`${activeFormData.type ? activeFormData.type : 'project'}`}
								>
									<fieldset className='grid-item button-and-input-container'>
										<ProjectAddCommentButton
											handleShowComments={handleShowComments}
											extraClass={false}
											targetId='project'
											group='delivery_and_project'
											comments={comments}
											label={GeneralConstants?.project_based}
										/>
										<label className='button-and-input-wrapper'>
											<Radio value='project' className='regular' />
											<span className='input-description'>{GeneralConstants?.project_based}</span>
										</label>
									</fieldset>

									<CommentFormDisplay targetId='project' />

									<fieldset className='grid-item button-and-input-container'>
										<ProjectAddCommentButton
											handleShowComments={handleShowComments}
											extraClass
											targetId='price'
											group='delivery_and_project'
											comments={comments}
											label={GeneralConstants?.price}
										/>
										<label className='button-and-input-wrapper'>
											<span className='field-description'>{GeneralConstants?.price}</span>
											<Text
												field='price'
												type='text'
												className={`${serverError?.body?.price ? 'error-field' : ''}`}
												initialValue={`${activeFormData.price ? activeFormData.price : ''}`}
												disabled={formState.values.type !== 'project'}
											/>
											{serverError?.body?.price ? (
												<span className='error-inline'>
													{ErrorConstants[serverError.body.price]}
												</span>
											) : null}
										</label>
									</fieldset>

									<CommentFormDisplay targetId='price' />

									<fieldset className='grid-item button-and-input-container'>
										<ProjectAddCommentButton
											handleShowComments={handleShowComments}
											extraClass={false}
											targetId='hour'
											group='delivery_and_project'
											comments={comments}
											label={GeneralConstants?.hour_based}
										/>
										<label className='button-and-input-wrapper'>
											<Radio value='hour' className='regular' />
											<span className='input-description'>{GeneralConstants?.hour_based}</span>
										</label>
									</fieldset>

									<CommentFormDisplay targetId='hour' />

									<fieldset className='grid-item button-and-input-container'>
										<ProjectAddCommentButton
											handleShowComments={handleShowComments}
											extraClass={false}
											targetId='damage'
											group='delivery_and_project'
											comments={comments}
											label={GeneralConstants?.damage_based}
										/>
										<label className='button-and-input-wrapper'>
											<Radio value='damage' className='regular' />
											<span className='input-description'>{GeneralConstants?.damage_based}</span>
										</label>
									</fieldset>
									<CommentFormDisplay targetId='damage' />
								</RadioGroup>
							</div>
						</div>
						<div className='dropdown-container'>
							<DropDownHeaderComponent
								title={GeneralConstants['demolition_and_services']}
								toggleDropDownContent={(ev) => this.toggleDropDown(ev, 'demolition_and_services')}
								handleShowComments={handleShowComments}
								extraClass
								targetId='demolition_and_services_header'
								group='demolition_and_services'
								comments={comments}
								label={GeneralConstants?.demolition_and_services_header}
								showContentBelow={
									showDemolitionAndServices == false || showDemolitionAndServices == 'false'
										? 'dropdown-container__header--closed'
										: null
								}
							/>
							<div
								className={`local-grid local-grid--col-1 dropdown-content-initial-options ${
									showDemolitionAndServices == false || showDemolitionAndServices == 'false'
										? 'local-grid--hidden'
										: null
								}`}
							>
								<CommentFormDisplay targetId='demolition_and_services_header' />
								<label className='free-grid-item initial'>
									<Checkbox field='demolition_and_services' initialValue={!!showServices.length} />
									<span>{GeneralConstants?.yes}</span>
								</label>
								<DemolitionAndServices
									serviceList={serviceList}
									servicePersonList={servicePersonList}
									serverError={serverError}
									serviceTypeAndMaterial={serviceTypeAndMaterial}
									showServices={showServices}
									handleShowComments={handleShowComments}
									group='demolition_and_services'
									comments={comments}
								/>
							</div>
						</div>
						<ProjectFooter
							page={page}
							projectID={activeFormData.id}
							handleProjectStatusChange={handleProjectStatusChange}
							projectStatus={projectStatus}
							handlePageSwitch={handlePageSwitch}
							fileCount={parseInt(dbImages.length) + parseInt(dbFiles.length)}
							firstStepCommentCount={handleStepCommentCount(1)}
							secondStepCommentCount={handleStepCommentCount(2)}
							activeFormDataStatus={activeFormData.status}
							projectCategory={activeFormData.category}
							projectName={activeFormData.title}
							handlePageOneData={handlePageOneData}
							handlePageTwoData={handlePageTwoData}
							canSetArchiveMode={this.props.canSetArchiveMode}
							trackedTime={trackedTime}
						/>
						{serverError?.http_code === 409 && (
							<CSSTransition
								in
								appear
								timeout={{
									appear: 300000000,
									exit: 0
								}}
							>
								<ErrorMessage
									errorID={
										serverError && serverError.id
											? serverError.id
											: serverError && serverError.status
											? serverError.status
											: ''
									}
									errorMessage={serverError && serverError.body ? serverError.body : null}
									onSubmit
								/>
							</CSSTransition>
						)}
					</>
				)}
			</Form>
		);
	}
}

export default ProjectFormFirstPage;
