const UserActionTypes = {
	SIGN_IN_START: 'SIGN_IN_START',
	SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
	SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
	CHANGE_USER_ROLE_START: 'CHANGE_USER_ROLE_START',
	CHANGE_USER_ROLE_SUCCESS: 'CHANGE_USER_ROLE_SUCCESS',
	CHANGE_USER_ROLE_FAILURE: 'CHANGE_USER_ROLE_FAILURE',
	CHECK_USER_SESSION: 'CHECK_USER_SESSION',
	SIGN_OUT_START: 'SIGN_OUT_START',
	SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
	SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
	FETCH_USER_ROLES_START: 'GET_USER_ROLES_START',
	FETCH_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
	FETCH_USER_ROLES_FAILURE: 'GET_USER_ROLES_FAILURE',
	FETCH_USER_ACTIVE_ROLE_START: 'FETCH_USER_ACTIVE_ROLE_START',
	FETCH_USER_ACTIVE_ROLE_SUCCESS: 'FETCH_USER_ACTIVE_ROLE_SUCCESS',
	FETCH_USER_ACTIVE_ROLE_FAILURE: 'FETCH_USER_ACTIVE_ROLE_FAILURE'
};

export default UserActionTypes;
