import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';

const Loading = () => (
	<div className='loading-container'>
		<div className='loading-gif'>
			<div />
			<div />
			<div />
			<div />
			<span className='loading-title'>{GeneralConstants['uploading_documents']}</span>
		</div>
	</div>
);

export default Loading;
