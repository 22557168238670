import { Form, TextArea } from 'informed';
import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';
import { EditFormContext } from '../../context/edit-form-context';

const CommentForm = () => {
	const {
		handleCommentSubmit,
		handleAttachmentChange,
		toggleCommentForm,
		handleCommentToggleForm,
		selectedComment,
		selectedCommentID,
		activeFormData,
		handleHideCommentForm
	} = React.useContext(EditFormContext);
	return (
		<Form
			className={`local-grid local-grid--col-1 overlay-comment-form ${
				toggleCommentForm ? 'overlay-comment-form--active' : ''
			}`}
			onSubmit={(ev) => handleCommentSubmit(ev)}
		>
			{toggleCommentForm && (
				<>
					<div className='input-container'>
						<label className='grid-item full-width'>
							<TextArea
								field='comment'
								initialValue={selectedComment}
								autoFocus
								onFocus={(e) => {
									const val = e.target.value;
									e.target.value = '';
									e.target.value = val;
								}}
							/>
						</label>
						<label className='grid-item full-width'>
							<input
								type='file'
								name='project_files'
								multiple
								onChange={(ev) => handleAttachmentChange(ev)}
							/>
						</label>
					</div>

					<div className='btn-container btn-container--center'>
						{activeFormData.status === 'archived' ? (
							<button className='btn semi dark' disabled type='button'>
								{selectedCommentID
									? `${GeneralConstants.update_comment}`
									: `${GeneralConstants.save_comment}`}
							</button>
						) : (
							<button className='btn semi main-green-dark' type='submit'>
								{selectedCommentID
									? `${GeneralConstants.update_comment}`
									: `${GeneralConstants.save_comment}`}
							</button>
						)}
						<button className='btn semi close close--dark' type='button' onClick={handleHideCommentForm}>
							{GeneralConstants.close_comment_form}
						</button>
					</div>
				</>
			)}
			{!toggleCommentForm && (
				<div className='btn-container btn-container--center btn-container--clear'>
					<button
						className='btn semi main-green-dark'
						type='button'
						onClick={(ev) => handleCommentToggleForm(ev)}
					>
						{GeneralConstants.add_comment}
					</button>
				</div>
			)}
		</Form>
	);
};

export default CommentForm;
