import { Form } from 'informed';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import GeneralConstants from '../../constants/general.constants';
import ProjectFooter from '../project-footer/project-footer.component';
import ProjectAddGeneralComment from '../project-form-elements/project-add-comment-button/project-add-general-comment.component';
import Loading from '../spinner/loading.component';

const FileHeader = ({ file, onDelete }) => {
	return (
		<div className='file-row'>
			<div>{file[0].name}</div>
			<div>
				<button
					type='button'
					className='comment-delete m-0'
					onClick={(ev) => {
						onDelete(file[0]);
					}}
				/>
			</div>
		</div>
	);
};

const ProjectFormThirdPage = ({
	dbImages,
	dbFiles,
	dbImageThumbs,
	handleSendDbData,
	handleDropzoneData,
	handleImageThumbs,
	handleAttachmentChange,
	handlePreviousPage,
	handleDocumentDownload,
	isUploadingImg,
	isUploadingDoc,
	canSetArchiveMode,
	handleDropZoneAttachmentChange,
	uploadDoc,
	uploadImg,
	...props
}) => {
	const [files, setFiles] = React.useState([]);

	const onDrop = React.useCallback((acceptedFiles) => {
		setFiles((curr) => [...curr, ...acceptedFiles]);
	}, []);

	React.useEffect(() => {
		handleDropZoneAttachmentChange(files);
	}, [files]);

	function onDelete(file) {
		setFiles((curr) => curr.filter((fw) => fw !== file));
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop
	});

	return (
		<div className='form-data-container'>
			<div {...getRootProps()}>
				<input {...getInputProps()} />
				<div className={isDragActive ? 'dropzone active-drag' : 'dropzone'}>
					Dra og slipp filer eller klikk for å laste opp.
				</div>
			</div>
			<div className='file-container'>
				<div className='file-header-container'>
					<div className='file-type file-type--document'>Valgte filer</div>
				</div>
				<div className='file-content-container selected-files'>
					<div className='file-list'>
						{uploadDoc?.map((fileWrapper) => (
							<FileHeader key={fileWrapper[0].name} onDelete={onDelete} file={fileWrapper} />
						))}
						{uploadImg?.map((fileWrapper) => (
							<FileHeader key={fileWrapper[0].name} onDelete={onDelete} file={fileWrapper} />
						))}
					</div>
					{(isUploadingDoc || isUploadingImg) && <Loading />}
				</div>
			</div>
			<div className='mb-20'>
				{props.activeFormData.status === 'archived' ? (
					<button className='btn small dark' disabled>
						{GeneralConstants['upload']}
					</button>
				) : (
					<button
						type='button'
						className='btn small main-green'
						onClick={(ev) => {
							setFiles([]);
							handleSendDbData(ev);
						}}
					>
						{GeneralConstants['upload']}
					</button>
				)}
			</div>
			<div className='file-container'>
				<div className='file-header-container'>
					<div className='file-type file-type--document'>{GeneralConstants['documents']}</div>
				</div>
				<div className='file-content-container'>
					{dbFiles.length ? (
						dbFiles.map((file, i) => (
							<a href='#' className='document' key={i} onClick={(ev) => handleDocumentDownload(ev, file)}>
								{file.name}
							</a>
						))
					) : (
						<div className='no-content'>{GeneralConstants['no_content']}</div>
					)}
					{isUploadingDoc && <Loading />}
				</div>
			</div>
			<div className='file-container'>
				<div className='file-header-container'>
					<div className='file-type file-type--picture'>{GeneralConstants['pictures']}</div>
				</div>
				<div className='picture-content-container'>
					{dbImages.length && !dbImageThumbs.length ? handleImageThumbs(dbImages) : null}

					{dbImageThumbs.length ? (
						dbImageThumbs.map((imageThumbEntries) =>
							imageThumbEntries.entries.map((imageThumb) => {
								const data = imageThumb.thumbnail;
								return (
									<div className='img-wrapper' key={imageThumb.metadata && imageThumb.metadata.name}>
										<img
											src={`data:image/jpeg;base64,${data}`}
											alt={imageThumb.metadata && imageThumb.metadata.name}
											onClick={(ev) => handleDocumentDownload(ev, imageThumb.metadata)}
										/>
									</div>
								);
							})
						)
					) : dbImages.length ? (
						<Loading />
					) : (
						<div className='no-content'>{GeneralConstants['no_content']}</div>
					)}
					{isUploadingImg && <Loading />}
				</div>
			</div>
			<Form>
				<ProjectFooter
					page={props.page}
					projectID={props.activeFormData.id}
					projectName={props.activeFormData.title}
					handleProjectStatusChange={props.handleProjectStatusChange}
					projectStatus={props.projectStatus}
					handlePageSwitch={props.handlePageSwitch}
					handleOnSubmit={props.handleProjectStatusSubmit}
					fileCount={parseInt(dbImages.length) + parseInt(dbFiles.length)}
					firstStepCommentCount={props.handleStepCommentCount(1)}
					secondStepCommentCount={props.handleStepCommentCount(2)}
					activeFormDataStatus={props.activeFormData.status}
					handlePageOneData={props.handlePageOneData}
					handlePageTwoData={props.handlePageTwoData}
					canSetArchiveMode={canSetArchiveMode}
					trackedTime={props.trackedTime}
				/>
			</Form>
		</div>
	);
};

export default ProjectFormThirdPage;
