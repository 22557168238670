import { RadioGroup } from 'informed';
import * as React from 'react';

import GeneralConstants from '../../../constants/general.constants';
import CommentFormDisplay from '../../comments/comment-form-display.component';
import ProjectAddCommentButton from '../project-add-comment-button/project-add-comment-button.component';
import ProjectMultiformDates from '../project-multiform/project-multiform-dates.component';
import ProjectRadioFreeContainer from '../project-radio-free-container/project-radio-free-container.component';

const ButtonAndOptionsContainer = ({
	handleShowComments,
	targetId,
	group,
	comments,
	label,
	inputName,
	inputNameInitialValue,
	options,
	hasDates = false,
	dates,
	checkBoxPath,
	dateName,
	activeFormDateData,
	todayDate
}) => {
	const [toggleList, setToggleList] = React.useState(inputNameInitialValue === 'yes');

	return (
		<>
			<fieldset className='grid-item button-and-options-container'>
				<ProjectAddCommentButton
					handleShowComments={handleShowComments}
					extraClass
					targetId={targetId}
					group={group}
					comments={comments}
					label={label}
				/>
				{hasDates ? (
					<fieldset className='options-container'>
						<span className='field-description'>{label}</span>
						{!toggleList ? (
							<div className='btn-container btn-container--right btn-container--border'>
								<button
									type='button'
									onClick={() => setToggleList(true)}
									className='btn small light add'
								>
									{`${GeneralConstants?.add} ${label}`}
								</button>
							</div>
						) : null}
						<fieldset
							className={`local-grid local-grid--col-2 ${
								toggleList ? 'option-list' : 'option-list--hidden'
							}`}
						>
							<ProjectMultiformDates
								dates={dates}
								checkBoxPath={checkBoxPath}
								dateName={dateName}
								activeFormDateData={activeFormDateData}
								todayDate={todayDate}
							/>
							<RadioGroup field={inputName} initialValue={inputNameInitialValue}>
								{options?.map((option) => (
									<ProjectRadioFreeContainer
										name={option.sys_name}
										key={option.sys_name}
										label={option.label}
										value={option.sys_name}
										extraClass='small'
									/>
								))}
							</RadioGroup>
						</fieldset>
					</fieldset>
				) : (
					<fieldset className='options-container'>
						<span className='field-description'>{label}</span>
						<fieldset className='local-grid local-grid--col-2 option-list'>
							<RadioGroup field={inputName} initialValue={inputNameInitialValue}>
								{options?.map((option) => (
									<ProjectRadioFreeContainer
										name={option.sys_name}
										key={option.sys_name}
										label={option.label}
										value={option.sys_name}
										extraClass='small'
									/>
								))}
							</RadioGroup>
						</fieldset>
					</fieldset>
				)}
			</fieldset>
			<CommentFormDisplay targetId={targetId} />
		</>
	);
};

export default ButtonAndOptionsContainer;
