import { Form } from 'informed';
import * as React from 'react';

import ErrorConstants from '../../constants/error.constants';
import GeneralConstants from '../../constants/general.constants';
import ProjectFormTextInput from '../project-form-elements/project-form-text-input/project-form-text-input.component';

const ProjectNewGroup = ({ handleAddNewProjectGroup, handleCreateNewProjectGroup, serverError }) => {
	return (
		<div className='add-new-client-overlay'>
			<Form onSubmit={(values) => handleAddNewProjectGroup(values)}>
				<div className='local-grid local-grid--col-1'>
					<ProjectFormTextInput
						name='title'
						label={GeneralConstants['create_new_project_group']}
						serverError={
							serverError && serverError.body && serverError.body.title
								? ErrorConstants[serverError.body.title]
								: ''
						}
					/>
				</div>
				<div className='btn-container btn-container--center'>
					<button className='btn small light-green right save--dark'>
						{GeneralConstants['add_new_group']}
					</button>
					<button
						className='btn small close close--small'
						type='button'
						onClick={handleCreateNewProjectGroup}
					>
						{GeneralConstants['close_overlay']}
					</button>
				</div>
			</Form>
		</div>
	);
};

export default ProjectNewGroup;
