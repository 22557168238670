import * as React from 'react';

import GeneralConstants from '../../../constants/general.constants';
import ProjectFormTextInput from '../project-form-text-input/project-form-text-input.component';
import ProjectMultiformDates from '../project-multiform/project-multiform-dates.component';

const ProjectGreenCardWoodPanels = ({ wood_panels, todayDate }) => {
	const [toggleList, setToggleList] = React.useState(
		!!(
			wood_panels.standard_quantity.value ||
			wood_panels.quantity_70.value ||
			wood_panels.end_list_quantity.value ||
			wood_panels.corner_list_quantity.value
		)
	);

	return (
		<fieldset className='options-container'>
			<span className='field-description'>{wood_panels.label}</span>
			{!toggleList ? (
				<div className='btn-container btn-container--right btn-container--border'>
					<button type='button' onClick={() => setToggleList(true)} className='btn small light add'>
						{`${GeneralConstants?.add} ${wood_panels.label}`}
					</button>
				</div>
			) : null}
			<fieldset className={`local-grid local-grid--col-2 ${toggleList ? 'option-list' : 'option-list--hidden'}`}>
				<ProjectMultiformDates
					dates={wood_panels?.dates}
					checkBoxPath='products[wood_panels][dates]'
					dateName='products[wood_panels][dates][delivered_date][value]'
					activeFormDateData={
						wood_panels?.dates?.delivered_date?.value ? wood_panels.dates.delivered_date.value : todayDate
					}
					todayDate={todayDate}
				/>
				<ProjectFormTextInput
					name='products[wood_panels][standard_quantity][value]'
					type='text'
					label={wood_panels.standard_quantity.label}
					activeFormData={wood_panels.standard_quantity.value}
				/>
				<ProjectFormTextInput
					name='products[wood_panels][quantity_70][value]'
					type='text'
					label={wood_panels.quantity_70.label}
					activeFormData={wood_panels.quantity_70.value}
				/>
				<ProjectFormTextInput
					name='products[wood_panels][end_list_quantity][value]'
					type='text'
					label={wood_panels.end_list_quantity.label}
					activeFormData={wood_panels.end_list_quantity.value}
				/>
				<ProjectFormTextInput
					name='products[wood_panels][corner_list_quantity][value]'
					type='text'
					label={wood_panels.corner_list_quantity.label}
					activeFormData={wood_panels.corner_list_quantity.value}
				/>
			</fieldset>
		</fieldset>
	);
};

export default ProjectGreenCardWoodPanels;
