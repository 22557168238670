const DemolitionConstants = {
	ceiling: 'Tak',
	ceiling_list: 'Listing tak',
	floor: 'Gulv',
	floor_list: 'Listing gulv',
	window_list: 'Vinduslist',
	electrician: 'Elektrier',
	plumber: 'Rørlegger',
	construction_manager: 'Byggeleder',
	type: 'Type *',
	select_type: 'Velg type',
	material: 'Materialer *',
	select_material: 'Velg materialer',
	m2m: 'm2',
	description: 'Beskrivelse',
	date: 'Dato',
	name: 'Navn',
	email: 'E-post',
	phone: 'Tlf nummer'
};

export default DemolitionConstants;
