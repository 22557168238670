// eslint-disable-next-line
import FullCalendar from '@fullcalendar/react';
// eslint-disable-next-line
import nnLocale from '@fullcalendar/core/locales/nn';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import _ from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import ErrorMessage from '../../components/error/error.component';
import ProjectNavigation from '../../components/project-navigation/project-navigation.component';
import Spinner from '../../components/spinner/spinner.component';
import GeneralConstants from '../../constants/general.constants';
import { setCalenderEvents } from '../../utils/calenderHelper';

const api = window.api;

class Calendar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			serverClientList: [],
			calendarProjectList: [],
			serverUsers: [],
			isFetching: true,
			error: null,
			errorMessage: false,
			handleSetServerError: this.handleSetServerError,
			setCalendarState: this.setCalendarState,
			handleEventPositioned: this.handleEventPositioned
		};
	}

	async componentDidMount() {
		try {
			const calendarProjectListPromise = api.projects.get(
				`?datetime_range=6,6&fields=id,title,client_id,category,status,user_ids_transport,user_ids_assembly,user_ids_production,start_assembly_datetime,end_assembly_datetime,start_production_datetime,end_production_datetime,start_transport_datetime,end_transport_datetime&show_knapphus_brand=1`
			);

			const clientsPromise = api.clients.get();

			const availableRolesPromise = api.users.get(`?includes=availableroles`);

			const calendarProjectList = await calendarProjectListPromise;

			const serverClientList = await clientsPromise;

			const serverUsers = await availableRolesPromise;
			await Promise.all([this.setCalendarState(calendarProjectList, serverClientList, serverUsers)]);
		} catch (error) {
			this.handleSetServerError(error);
		}
	}

	setCalendarState = (calendarProjectList, serverClientList, serverUsers) => {
		this.setState((prevState) => ({
			...prevState,
			serverClientList,
			calendarProjectList,
			serverUsers,
			error: null,
			isFetching: false,
			errorMessage: false
		}));
	};

	handleSetServerError = (error) => {
		this.setState((prevState) => ({
			...prevState,
			isFetching: false,
			error,
			errorMessage: true
		}));
		setTimeout(() => this.setState({ errorMessage: false }), 3000);
	};

	handleEventPositioned(info) {
		function hasSomeParentTheClass(element, classname) {
			if (element.className?.split(' ').indexOf(classname) >= 0) return true;
			return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
		}
		const hasParent = hasSomeParentTheClass(info.el, 'fc-daygrid');
		if (!hasParent) {
			return;
		}
		info.el.setAttribute('data-tip', info.el.innerText);
		ReactTooltip.rebuild();
	}

	render() {
		const { isFetching, errorMessage, calendarProjectList, serverClientList, serverUsers } = this.state;

		const clientList = _.mapKeys(serverClientList, function (value, key) {
			return value.id;
		});

		const calendarProjectAssemblyListData = calendarProjectList
			.filter((project) => project.start_assembly_datetime && project.end_assembly_datetime)
			.map((project) =>
				setCalenderEvents({
					project,
					roleDescription: GeneralConstants?.assembly,
					clientList,
					startDate: project.start_assembly_datetime,
					endDate: project.end_assembly_datetime,
					serverUsers,
					projectCategory: project.user_ids_assembly
				})
			);

		const calendarProjectTransportListData = calendarProjectList
			.filter((project) => project.start_transport_datetime && project.end_transport_datetime)
			.map((project) =>
				setCalenderEvents({
					project,
					roleDescription: GeneralConstants?.transport,
					clientList,
					startDate: project.start_transport_datetime,
					endDate: project.end_transport_datetime,
					serverUsers,
					projectCategory: project.user_ids_transport
				})
			);

		const calendarProjectProductionListData = calendarProjectList
			.filter((project) => project.start_production_datetime && project.end_production_datetime)
			.map((project) =>
				setCalenderEvents({
					project,
					roleDescription: GeneralConstants?.production,
					clientList,
					startDate: project.start_production_datetime,
					endDate: project.end_production_datetime,
					serverUsers,
					projectCategory: project.user_ids_production
				})
			);

		return (
			<>
				{isFetching && <Spinner />}
				{errorMessage && (
					<ErrorMessage
						errorID={
							this.state?.error?.id
								? this.state.error.id
								: this.state?.error?.status
								? this.state.error.status
								: ''
						}
					/>
				)}
				<ProjectNavigation />
				<div className='calendar-container calendar-container--full-width'>
					<div className='title-container'>
						<Link to='/my-day'>{GeneralConstants?.my_day}</Link>
						<Link to='/calendar' className='active'>
							{GeneralConstants?.calendar}
						</Link>
						<Link to='/map'>{GeneralConstants?.map}</Link>
					</div>
					{calendarProjectList && (
						<div className='calendar-container-data'>
							<ReactTooltip />
							<FullCalendar
								initialView='dayGridMonth'
								plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
								weekends={false}
								headerToolbar={{
									left: 'title',
									center: 'dayGridMonth,listWeek listDay',
									right: 'prev,next today'
								}}
								views={{
									listDay: { buttonText: `${GeneralConstants?.list_day}` },
									listWeek: { buttonText: `${GeneralConstants?.list_week}` }
								}}
								allDaySlot
								firstDay={1}
								nowIndicator
								slotLabelFormat={{
									hour: '2-digit',
									minute: '2-digit',
									meridiem: false,
									hour12: false
								}}
								eventTimeFormat={{
									hour: '2-digit',
									minute: '2-digit',
									meridiem: false,
									hour12: false
								}}
								selectable
								locale={nnLocale}
								weekNumbers
								weekText=''
								slotEventOverlap={false}
								events={[
									...calendarProjectAssemblyListData,
									...calendarProjectTransportListData,
									...calendarProjectProductionListData
								]}
								eventMouseEnter={this.handleEventPositioned}
							/>
						</div>
					)}
				</div>
			</>
		);
	}
}

export default Calendar;
