import { Text } from 'informed';
import * as React from 'react';

const ProjectFormTextInput = ({ activeFormData, label, name, serverError, type = 'text', disabledField }) => (
	<label className='grid-item'>
		<span className='field-description'>{label}</span>
		{disabledField ? (
			<Text
				field={name}
				type={type}
				initialValue={activeFormData}
				allowEmptyString
				disabled
				className={`${serverError ? 'error-field' : ''}`}
			/>
		) : (
			<Text
				field={name}
				type={type}
				initialValue={activeFormData}
				allowEmptyString
				className={`${serverError ? 'error-field' : ''}`}
			/>
		)}
		{serverError && <span className='error-inline'>{serverError}</span>}
	</label>
);

export default ProjectFormTextInput;
