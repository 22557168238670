import { Checkbox, Option, Radio, RadioGroup, Select, Text, TextArea } from 'informed';
import * as React from 'react';

import GeneralConstants from '../../../constants/general.constants';
import CommentFormDisplay from '../../comments/comment-form-display.component';
import ButtonAndInputContainer from '../../project-form-elements/button-and-input-container/button-and-input-container.component';
import ButtonAndOptionsContainer from '../../project-form-elements/button-and-options-container/button-and-options-container.component';
import DropTownHeaderCheckBox from '../../project-form-elements/dropdown-header-checkbox/dropdown-header-checkbox.component';
import ProjectExtras from '../../project-form-elements/project_extras/ProjectExtras.component';
import ProjectAddCommentButton from '../../project-form-elements/project-add-comment-button/project-add-comment-button.component';
import ProjectFormCheckBox from '../../project-form-elements/project-form-checkbox-input/project-form-checkbox-input.component';
import ProjectFormTextInput from '../../project-form-elements/project-form-text-input/project-form-text-input.component';
import ProjectGreenCardExtras from '../../project-form-elements/project-green-card-option/ProjectGreenCardExtras.component';
import ProjectGreenCardOption from '../../project-form-elements/project-green-card-option/ProjectGreenCardOption.component';
import ProjectGreenCardWoodPanels from '../../project-form-elements/project-green-card-option/ProjectGreenCardWoodPanels.component';
import ProjectMultiformDates from '../../project-form-elements/project-multiform/project-multiform-dates.component';
import ProjectPaint from '../../project-form-elements/project-paint/ProjectPaint.component';
import ProjectRadioFreeContainer from '../../project-form-elements/project-radio-free-container/project-radio-free-container.component';

const api = window.api;

const ProjectFormKitchen = (props) => {
	const { is_workdrawing_sent, sent_from, doorhandle, order_type, products } = props.formData;

	const { handleShowComments, comments, activeFormDataStatus, activeFormData, formState } = props;

	const getOrderTypeInitialValues = (group) => {
		let initialValue;

		if (
			order_type.knapphus[`${group}`].list.items.value &&
			order_type.knapphus[`${group}`].list.items.value.length
		) {
			initialValue = [...order_type.knapphus[`${group}`].list.items.value];
		} else {
			initialValue = [
				{
					id: api.forms.getUniqueID(),
					front: '',
					dekks: '',
					color_code: '',
					moldings_lists: '',
					planned_week: ''
				}
			];
		}
		return initialValue;
	};

	const getInitialValues = (group) => {
		let initialValue;

		if (products[`${group}`].list.items.value && products[`${group}`].list.items.value.length) {
			initialValue = [...products[`${group}`].list.items.value];
		} else if (group === 'fridge') {
			initialValue = [
				{
					id: api.forms.getUniqueID(),
					brand: products[`${group}`].list.pattern.brand.options[0].sys_name,
					mode: products[`${group}`].list.pattern.mode.options[0].sys_name,
					model: '',
					quantity: 0,
					delivery_date: ''
				}
			];
		} else if (group === 'various_extras_list') {
			initialValue = [
				{
					id: api.forms.getUniqueID(),
					diverse: '',
					quantity: 0,
					delivery_date: ''
				}
			];
		} else if (group === 'various_extras_list_2') {
			initialValue = [
				{
					id: api.forms.getUniqueID(),
					EL_pillar: '',
					quantity: 0,
					delivery_date: ''
				}
			];
		} else {
			initialValue = [
				{
					id: api.forms.getUniqueID(),
					brand: products[`${group}`].list.pattern.brand.options[0].sys_name,
					model: '',
					quantity: 0,
					delivery_date: ''
				}
			];
		}

		return initialValue;
	};

	const [hide_order_type, setOrderTypeMode] = React.useState('false');

	const [hide_products, setProductsMode] = React.useState('false');

	const [hide_sent_from, setSentFromMode] = React.useState('false');

	const [hide_doorhandle, setDoorhandleMode] = React.useState('false');

	const [hide_is_workdrawing_sent, setIsWorkdrawingSentMode] = React.useState('false');

	React.useEffect(() => {
		const isOrderType = localStorage.getItem('hide_order_type') === 'true';
		setOrderTypeMode(isOrderType);
		const isProducts = localStorage.getItem('hide_products') === 'true';
		setProductsMode(isProducts);
		const isSentFrom = localStorage.getItem('hide_sent_from') === 'true';
		setSentFromMode(isSentFrom);
		const isDoorhandle = localStorage.getItem('hide_doorhandle') === 'true';
		setDoorhandleMode(isDoorhandle);
		const isWorkdrawingSent = localStorage.getItem('hide_is_workdrawing_sent') === 'true';
		setIsWorkdrawingSentMode(isWorkdrawingSent);
	}, [hide_order_type, hide_products, hide_sent_from, hide_doorhandle, hide_is_workdrawing_sent]);

	const toggle = (ev, section) => {
		ev.preventDefault();
		if (section === 'order_type') {
			const isOrderType = !hide_order_type;
			localStorage.setItem('hide_order_type', JSON.stringify(isOrderType));
			setOrderTypeMode(isOrderType);
		} else if (section === 'products') {
			const isProducts = !hide_products;
			localStorage.setItem('hide_products', JSON.stringify(isProducts));
			setProductsMode(isProducts);
		} else if (section === 'sent_from') {
			const isSentFrom = !hide_sent_from;
			localStorage.setItem('hide_sent_from', JSON.stringify(isSentFrom));
			setSentFromMode(isSentFrom);
		} else if (section === 'doorhandle') {
			const isDoorhandle = !hide_doorhandle;
			localStorage.setItem('hide_doorhandle', JSON.stringify(isDoorhandle));
			setDoorhandleMode(isDoorhandle);
		} else if (section === 'is_workdrawing_sent') {
			const isWorkdrawingSent = !hide_is_workdrawing_sent;
			localStorage.setItem('hide_is_workdrawing_sent', JSON.stringify(isWorkdrawingSent));
			setIsWorkdrawingSentMode(isWorkdrawingSent);
		}
	};

	const [order_type_val, setOrderType] = React.useState(order_type.brands.value);

	const todayDate = new Date().toISOString().substring(0, 10);

	return (
		<>
			<div className='dropdown-container'>
				<div
					className={`dropdown-header-container dropdown-container__header ${
						hide_order_type ? 'dropdown-container__header--closed' : null
					} `}
				>
					<a href='' className='dropdown-container-trigger' onClick={(ev) => toggle(ev, 'order_type')}>
						{order_type.label}
					</a>
					<div className='dropdown-status-container'>
						<DropTownHeaderCheckBox
							name='order_type[dates][ordered][value]'
							label={order_type.dates.ordered.label}
							activeFormData={order_type.dates.ordered.value === true}
						/>
						<DropTownHeaderCheckBox
							name='order_type[dates][approved][value]'
							label={order_type.dates.approved.label}
							activeFormData={order_type.dates.approved.value === true}
						/>
						<DropTownHeaderCheckBox
							name='order_type[dates][delivered][value]'
							label={order_type.dates.delivered.label}
							dateName='order_type[dates][delivered_date][value]'
							activeFormData={order_type.dates.delivered.value === true}
							activeFormDateData={order_type.dates.delivered_date.value ?? todayDate}
							todayDate={todayDate}
						/>
						<ProjectAddCommentButton
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='order_type.header'
							group='order_type'
							comments={comments}
							label={order_type.label}
						/>
					</div>
				</div>
				<div
					className={`local-grid local-grid--col-4 dropdown-content-initial-options ${
						hide_order_type ? 'local-grid--hidden' : null
					}`}
				>
					<CommentFormDisplay targetId='order_type.header' />
					<div>
						<RadioGroup
							field='order_type[brands][value]'
							initialValue={order_type.brands.value}
							onValueChange={(ev) => setOrderType(ev)}
						>
							{order_type &&
								order_type.brands.dyn_data.map((brand) => {
									if (brand.sys_name !== 'andre' && brand.sys_name !== '__empty__') {
										return (
											<ProjectRadioFreeContainer
												label={brand.name}
												value={brand.sys_name}
												key={brand.sys_name}
											/>
										);
									} else if (brand.sys_name === 'andre') {
										return (
											<fieldset className='free-grid-item' key={brand.sys_name}>
												<label>
													<Radio
														className='regular'
														field={brand.sys_name}
														value={brand.sys_name}
													/>
													<span>{brand.name}</span>
												</label>
												<label>
													<Text
														type='text'
														field='order_type[branding_text][value]'
														initialValue={order_type.branding_text.value}
														allowEmptyString={true}
														disabled={
															!(formState.values.order_type?.brands?.value === 'andre')
														}
													/>
												</label>
											</fieldset>
										);
									}
								})}
						</RadioGroup>
					</div>
				</div>
				<div
					className={`dropdown-content-options ${
						(order_type_val !== 'knapphus' && order_type_val !== 'tigne') || hide_order_type
							? 'dropdown-content-options--hidden'
							: ''
					}`}
				>
					<div className='local-grid local-grid--col-2'>
						<ProjectFormTextInput
							name='order_type[knapphus][order_number][value]'
							type='text'
							label={order_type.knapphus.order_number.label}
							activeFormData={order_type.knapphus.order_number.value}
							allowEmptyString={true}
						/>
						<fieldset className='grid-item button-and-input-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.knapphus.order_number'
								group='order_type'
								comments={comments}
								label={order_type.knapphus.order_number.label}
							/>
							<label className='button-and-input-wrapper'>
								<span className='input-description'>
									{order_type.knapphus.delivery_date.label} {GeneralConstants['can_change_in_page_1']}
								</span>
								<div className='look-like-input'>
									{activeFormData.start_transport_datetime && activeFormData.end_transport_datetime
										? `${activeFormData.start_transport_datetime} - ${activeFormData.end_transport_datetime}`
										: null}
								</div>
							</label>
						</fieldset>

						<CommentFormDisplay targetId='order_type.knapphus.order_number' />

						<ButtonAndInputContainer
							inputName='order_type[knapphus][kitchen_model][value]'
							inputNameInitialValue={order_type.knapphus.kitchen_model.value}
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='order_type.knapphus.kitchen_model'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.kitchen_model.label}
						/>
						<fieldset className='grid-item button-and-options-container full-width-100'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.knapphus.lacquer'
								group='lacquer'
								comments={comments}
								label={order_type.knapphus.lacquer.label}
							/>
							<ProjectPaint
								optionLabel={order_type.knapphus.lacquer.label}
								getInitialValues={getOrderTypeInitialValues}
								group='lacquer'
								order_type={order_type}
								handleOrderTypeRemove={props.handleOrderTypeRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
					</div>
					<div className={'full-width-100'}>
						<CommentFormDisplay targetId='order_type.knapphus.lacquer' />
					</div>
					<div className='local-grid local-grid--col-1'>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.knapphus.moldings'
								group='order_type'
								comments={comments}
								label={order_type.knapphus.moldings.label}
							/>
							<fieldset className='options-container'>
								<span className='field-description'>{order_type.knapphus.moldings.label}</span>
								<fieldset className='option-list'>
									<ProjectFormCheckBox
										name='order_type[knapphus][moldings][linear_fitting][value]'
										label={order_type.knapphus.moldings.linear_fitting.label}
										activeFormData={order_type.knapphus.moldings.linear_fitting.value === true}
									/>
									<ProjectFormCheckBox
										name='order_type[knapphus][moldings][gemis][value]'
										label={order_type.knapphus.moldings.gemis.label}
										activeFormData={order_type.knapphus.moldings.gemis.value === true}
									/>
									<ProjectFormCheckBox
										name='order_type[knapphus][moldings][spotlights][value]'
										label={order_type.knapphus.moldings.spotlights.label}
										activeFormData={order_type.knapphus.moldings.spotlights.value === true}
									/>
									<fieldset className='free-grid-item clear-padding'>
										<ProjectFormCheckBox
											name='order_type[knapphus][moldings][roof_liner][value]'
											label={order_type.knapphus.moldings.roof_liner.label}
											activeFormData={order_type.knapphus.moldings.roof_liner.value === true}
										/>
										<label className='input-with-checkbox'>
											<span className='input-description'>
												{order_type.knapphus.moldings.cm.label}
											</span>
											<Text
												type='text'
												field='order_type[knapphus][moldings][cm][value]'
												initialValue={order_type.knapphus.moldings.cm.value}
												allowEmptyString={true}
											/>
										</label>
									</fieldset>
								</fieldset>
							</fieldset>
						</fieldset>
						<CommentFormDisplay targetId='order_type.knapphus.moldings' />
					</div>

					<div className='local-grid local-grid--col-1'>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.knapphus.glass_type'
								group='order_type'
								comments={comments}
								label={order_type.knapphus.glass_type.label}
							/>
							<fieldset className='options-container'>
								<span className='field-description'>{order_type.knapphus.glass_type.label}</span>
								<fieldset className='local-grid local-grid--col-2 option-list'>
									<RadioGroup
										field='order_type[knapphus][glass_type][standard_type][value]'
										initialValue={order_type.knapphus.glass_type.standard_type.value}
									>
										{order_type &&
											order_type.knapphus.glass_type.standard_type.options.map((option) => (
												<ProjectRadioFreeContainer
													name={option.sys_name}
													key={option.sys_name}
													label={option.label}
													value={option.sys_name}
													extraClass='small'
												/>
											))}
									</RadioGroup>
									<label className='input-with-checkbox'>
										<span className='input-description'>
											{order_type.knapphus.glass_type.special.label}
										</span>
										<Text
											type='text'
											field='order_type[knapphus][glass_type][special][value]'
											initialValue={order_type.knapphus.glass_type.special.value}
											allowEmptyString={true}
										/>
									</label>
								</fieldset>
							</fieldset>
						</fieldset>
						<CommentFormDisplay targetId='order_type.knapphus.glass_type' />
					</div>
					<div className='local-grid local-grid--col-1'>
						<ButtonAndOptionsContainer
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.knapphus.glass_type
									.glass_cabinet_internal_hullcolor_as_front'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.glass_type.glass_cabinet_internal_hullcolor_as_front.label}
							inputName='order_type[knapphus][glass_type][glass_cabinet_internal_hullcolor_as_front][value]'
							inputNameInitialValue={
								order_type.knapphus.glass_type.glass_cabinet_internal_hullcolor_as_front.value
							}
							options={order_type.knapphus.glass_type.glass_cabinet_internal_hullcolor_as_front.options}
						/>
					</div>

					<div className='local-grid local-grid--col-2'>
						<ButtonAndInputContainer
							inputName='order_type[knapphus][handle][value]'
							inputNameInitialValue={order_type.knapphus.handle.value}
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.knapphus.handle'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.handle.label}
						/>
					</div>

					<div className='local-grid local-grid--col-1'>
						<ButtonAndOptionsContainer
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.knapphus.edging'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.edging.label}
							inputName='order_type[knapphus][edging][value]'
							inputNameInitialValue={order_type.knapphus.edging.value}
							options={order_type.knapphus.edging.options}
						/>
					</div>

					<div className='local-grid local-grid--col-1'>
						<ButtonAndOptionsContainer
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.knapphus.drawer_color'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.drawer_color.label}
							inputName='order_type[knapphus][drawer_color][value]'
							inputNameInitialValue={order_type.knapphus.drawer_color.value}
							options={order_type.knapphus.drawer_color.options}
						/>
					</div>

					<div className='local-grid local-grid--col-1'>
						<ButtonAndOptionsContainer
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.knapphus.refrigerator'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.refrigerator.label}
							inputName='order_type[knapphus][refrigerator][value]'
							inputNameInitialValue={order_type.knapphus.refrigerator.value}
							options={order_type.knapphus.refrigerator.options}
						/>
					</div>

					<div className='local-grid local-grid--col-1'>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.knapphus.tip_on'
								group='order_type'
								comments={comments}
								label={order_type.knapphus.tip_on.label}
							/>
							<fieldset className='options-container'>
								<span className='field-description'>{order_type.knapphus.tip_on.label}</span>
								<fieldset className='local-grid local-grid--col-2 option-list'>
									<ProjectFormCheckBox
										name='order_type[knapphus][tip_on][door][value]'
										label={order_type.knapphus.tip_on.door.label}
										activeFormData={order_type.knapphus.tip_on.door.value === true}
									/>
									<ProjectFormCheckBox
										name='order_type[knapphus][tip_on][drawer][value]'
										label={order_type.knapphus.tip_on.drawer.label}
										activeFormData={order_type.knapphus.tip_on.drawer.value === true}
									/>
									<ProjectFormCheckBox
										name='order_type[knapphus][tip_on][refrigerator_freezer][value]'
										label={order_type.knapphus.tip_on.refrigerator_freezer.label}
										activeFormData={order_type.knapphus.tip_on.refrigerator_freezer.value === true}
									/>
								</fieldset>
							</fieldset>
						</fieldset>
						<CommentFormDisplay targetId='order_type.knapphus.tip_on' />
					</div>

					<div className='local-grid local-grid--col-1'>
						<ButtonAndOptionsContainer
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='order_type.knapphus.optionally_raising_ref_freezer_siemens'
							group='order_type'
							comments={comments}
							label={order_type.knapphus.optionally_raising_ref_freezer_siemens.label}
							inputName='order_type[knapphus][optionally_raising_ref_freezer_siemens][value]'
							inputNameInitialValue={order_type.knapphus.optionally_raising_ref_freezer_siemens.value}
							options={order_type.knapphus.optionally_raising_ref_freezer_siemens.options}
						/>
					</div>

					<div className='local-grid local-grid--col-2'>
						<fieldset className='grid-item button-and-input-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.knapphus.work_surface'
								group='order_type'
								comments={comments}
								label={order_type.knapphus.work_surface.label}
							/>
							<label className='button-and-input-wrapper'>
								<span className='input-description'>{order_type.knapphus.work_surface.mm.label}</span>
								<Text
									type='text'
									field='order_type[knapphus][work_surface][mm][value]'
									initialValue={order_type.knapphus.work_surface.mm.value}
									allowEmptyString={true}
								/>
							</label>
						</fieldset>
						<CommentFormDisplay targetId='order_type.knapphus.work_surface' />
					</div>
					<div className='local-grid local-grid--col-1'>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='order_type.knapphus.additional'
								group='order_type'
								comments={comments}
								label={order_type.knapphus.additional.label}
							/>
							<fieldset className='options-container'>
								<span className='field-description'>{order_type.knapphus.additional.label}</span>
								<fieldset className='local-grid local-grid--col-2 option-list'>
									<ProjectFormTextInput
										name='order_type[knapphus][additional][cutlery_post][value]'
										type='text'
										label={order_type.knapphus.additional.cutlery_post.label}
										activeFormData={order_type.knapphus.additional.cutlery_post.value}
									/>
									<ProjectFormTextInput
										name='order_type[knapphus][additional][recycling][value]'
										type='text'
										label={order_type.knapphus.additional.recycling.label}
										activeFormData={order_type.knapphus.additional.recycling.value}
									/>
								</fieldset>
							</fieldset>
						</fieldset>
						<CommentFormDisplay targetId='order_type.knapphus.additional' />
					</div>
				</div>
				<div
					className={`dropdown-content-options ${
						(order_type_val !== 'sigdal' &&
							order_type_val !== 'ka-garderobe' &&
							order_type_val !== 'strai' &&
							order_type_val !== 'andre') ||
						hide_order_type
							? 'dropdown-content-options--hidden'
							: ''
					}`}
				>
					<div className='local-grid local-grid--col-2'>
						<ProjectFormTextInput
							name='order_type[other][model][value]'
							type='text'
							label={order_type.other.model.label}
							activeFormData={order_type.other.model.value}
						/>
						<label className='grid-item'>
							<span className='input-description'>{order_type.other.estimation_date.label}</span>
							<Text
								type='date'
								field='order_type[other][estimation_date][value]'
								allowEmptyString={true}
								initialValue={order_type.other.estimation_date.value}
							/>
						</label>
					</div>
				</div>
			</div>
			<div className='dropdown-container'>
				<div
					className={`dropdown-header-container dropdown-container__header ${
						hide_products ? 'dropdown-container__header--closed' : null
					} `}
				>
					<a href='' className='dropdown-container-trigger' onClick={(ev) => toggle(ev, 'products')}>
						{products.label}
					</a>
					<div className='dropdown-status-container'>
						<DropTownHeaderCheckBox
							name='products[dates][ordered][value]'
							label={products.dates.ordered.label}
							activeFormData={products.dates.ordered.value === true}
						/>
						<DropTownHeaderCheckBox
							name='products[dates][approved][value]'
							label={products.dates.approved.label}
							activeFormData={products.dates.approved.value === true}
						/>
						<DropTownHeaderCheckBox
							name='products[dates][delivered][value]'
							label={products.dates.delivered.label}
							dateName='products[dates][delivered_date][value]'
							activeFormData={products.dates.delivered.value === true}
							activeFormDateData={
								products.dates.delivered_date.value ? products.dates.delivered_date.value : todayDate
							}
							todayDate={todayDate}
						/>
						<ProjectAddCommentButton
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='products.header'
							group='products'
							comments={comments}
							label={products.label}
						/>
					</div>
				</div>
				<div className={`dropdown-content-options ${hide_products ? 'dropdown-content-options--hidden' : ''}`}>
					<div className='local-grid local-grid--col-1'>
						<CommentFormDisplay targetId='products.header' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.oven'
								group='products'
								comments={comments}
								label={products.oven.label}
							/>
							<ProjectGreenCardOption
								optionLabel={products.oven.label}
								dates={products.oven.dates}
								checkBoxPath='products[oven][dates]'
								dateName='products[oven][dates][delivered_date][value]'
								activeFormDateData={
									products.oven.dates.delivered_date.value
										? products.oven.dates.delivered_date.value
										: todayDate
								}
								getInitialValues={getInitialValues}
								group='oven'
								products={products}
								handleProductRemove={props.handleProductRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.oven' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.micro'
								group='products'
								comments={comments}
								label={products.micro.label}
							/>
							<ProjectGreenCardOption
								optionLabel={products.micro.label}
								dates={products.micro.dates}
								checkBoxPath='products[micro][dates]'
								dateName='products[micro][dates][delivered_date][value]'
								activeFormDateData={
									products.micro.dates.delivered_date.value
										? products.micro.dates.delivered_date.value
										: todayDate
								}
								getInitialValues={getInitialValues}
								group='micro'
								products={products}
								handleProductRemove={props.handleProductRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.micro' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.coffee_machine'
								group='products'
								comments={comments}
								label={products.coffee_machine.label}
							/>
							<ProjectGreenCardOption
								optionLabel={products.coffee_machine.label}
								dates={products.coffee_machine.dates}
								checkBoxPath='products[coffee_machine][dates]'
								dateName='products[coffee_machine][dates][delivered_date][value]'
								activeFormDateData={
									products.coffee_machine.dates.delivered_date.value
										? products.coffee_machine.dates.delivered_date.value
										: todayDate
								}
								getInitialValues={getInitialValues}
								group='coffee_machine'
								products={products}
								handleProductRemove={props.handleProductRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.coffee_machine' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.top'
								group='products'
								comments={comments}
								label={products.top.label}
							/>
							<ProjectGreenCardOption
								optionLabel={products.top.label}
								dates={products.top.dates}
								checkBoxPath='products[top][dates]'
								dateName='products[top][dates][delivered_date][value]'
								activeFormDateData={
									products.top.dates.delivered_date.value
										? products.top.dates.delivered_date.value
										: todayDate
								}
								getInitialValues={getInitialValues}
								group='top'
								products={products}
								handleProductRemove={props.handleProductRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.top' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.dishwasher'
								group='products'
								comments={comments}
								label={products.dishwasher.label}
							/>
							<ProjectGreenCardOption
								optionLabel={products.dishwasher.label}
								dates={products.dishwasher.dates}
								checkBoxPath='products[dishwasher][dates]'
								dateName='products[dishwasher][dates][delivered_date][value]'
								activeFormDateData={
									products.dishwasher.dates.delivered_date.value
										? products.dishwasher.dates.delivered_date.value
										: todayDate
								}
								getInitialValues={getInitialValues}
								group='dishwasher'
								products={products}
								handleProductRemove={props.handleProductRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.dishwasher' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.fridge'
								group='products'
								comments={comments}
								label={products.fridge.label}
							/>
							<ProjectGreenCardOption
								optionLabel={products.fridge.label}
								dates={products.fridge.dates}
								checkBoxPath='products[fridge][dates]'
								dateName='products[fridge][dates][delivered_date][value]'
								activeFormDateData={
									products.fridge.dates.delivered_date.value
										? products.fridge.dates.delivered_date.value
										: todayDate
								}
								getInitialValues={getInitialValues}
								group='fridge'
								products={products}
								handleProductRemove={props.handleProductRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.fridge' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.freezer'
								group='products'
								comments={comments}
								label={products.freezer.label}
							/>
							<ProjectGreenCardOption
								optionLabel={products.freezer.label}
								dates={products.freezer.dates}
								checkBoxPath='products[freezer][dates]'
								dateName='products[freezer][dates][delivered_date][value]'
								activeFormDateData={
									products.freezer.dates.delivered_date.value
										? products.freezer.dates.delivered_date.value
										: todayDate
								}
								getInitialValues={getInitialValues}
								group='freezer'
								products={products}
								handleProductRemove={props.handleProductRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.freezer' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.ventilator'
								group='products'
								comments={comments}
								label={products.ventilator.label}
							/>
							<ProjectGreenCardOption
								optionLabel={products.ventilator.label}
								dates={products.ventilator.dates}
								checkBoxPath='products[ventilator][dates]'
								dateName='products[ventilator][dates][delivered_date][value]'
								activeFormDateData={
									products.ventilator.dates.delivered_date.value
										? products.ventilator.dates.delivered_date.value
										: todayDate
								}
								getInitialValues={getInitialValues}
								group='ventilator'
								products={products}
								handleProductRemove={props.handleProductRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.ventilator' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.various_extras_list'
								group='products'
								comments={comments}
								label={products.various_extras_list.label}
							/>
							<ProjectExtras
								optionLabel={products.various_extras_list.label}
								dates={products.various_extras_list.dates}
								checkBoxPath='products[various_extras_list][dates]'
								dateName='products[various_extras_list][dates][delivered_date][value]'
								activeFormDateData={
									products.various_extras_list.dates.delivered_date.value
										? products.various_extras_list.dates.delivered_date.value
										: todayDate
								}
								getInitialValues={getInitialValues}
								group='various_extras_list'
								products={products}
								handleProductRemove={props.handleProductRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.various_extras_list' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.various_extras_list_2'
								group='products'
								comments={comments}
								label={products?.various_extras_list_2?.label}
							/>
							<ProjectExtras
								optionLabel={products.various_extras_list_2.label}
								dates={products.various_extras_list_2.dates}
								checkBoxPath='products[various_extras_list_2][dates]'
								dateName='products[various_extras_list_2][dates][delivered_date][value]'
								activeFormDateData={
									products.various_extras_list_2.dates.delivered_date.value
										? products.various_extras_list_2.dates.delivered_date.value
										: todayDate
								}
								getInitialValues={getInitialValues}
								group='various_extras_list_2'
								products={products}
								handleProductRemove={props.handleProductRemove}
								handleShowComments={handleShowComments}
								comments={comments}
								activeFormDataStatus={activeFormDataStatus}
								todayDate={todayDate}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.various_extras_list_2' />
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.various_extras_3'
								group='products'
								comments={comments}
								label={products?.various_extras_3?.label}
							/>
							<ProjectGreenCardExtras
								extraLabel={products?.various_extras_3?.label}
								dates={products?.various_extras_3?.dates}
								checkBoxPath='products[various_extras_3][dates]'
								dateName='products[various_extras_3][dates][delivered_date][value]'
								activeFormDateData={
									products?.various_extras_3?.dates?.delivered_date?.value
										? products.various_extras_3.dates.delivered_date.value
										: todayDate
								}
								name='products[various_extras_3][quooker][value]'
								label={products?.various_extras_3?.quooker?.label}
								activeFormData={products?.various_extras_3?.quooker?.value}
								todayDate={todayDate}
								various={products.various_extras_3}
								variousFieldQuantityName={'products[various_extras_3][quantity][value]'}
								variousFieldDateName={'products[various_extras_3][delivery_date][value]'}
							/>
						</fieldset>
						<CommentFormDisplay targetId='products.various_extras_3' />
						<ButtonAndOptionsContainer
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='products.recycling'
							group='products'
							comments={comments}
							label={products?.recycling?.label}
							inputName='products[recycling][recyclingmode][value]'
							inputNameInitialValue={
								products?.recycling?.recyclingmode?.value
									? products?.recycling?.recyclingmode?.value
									: products?.recycling?.recyclingmode?.options?.[0]?.sys_name
							}
							options={products?.recycling?.recyclingmode?.options}
							hasDates
							dates={products?.recycling?.dates}
							checkBoxPath='products[recycling][dates]'
							dateName='products[recycling][dates][delivered_date][value]'
							activeFormDateData={
								products?.recycling?.dates?.delivered_date?.value
									? products.recycling.dates.delivered_date.value
									: todayDate
							}
							todayDate={todayDate}
						/>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='products.wood_panels'
								group='products'
								comments={comments}
								label={products.wood_panels.label}
							/>
							<ProjectGreenCardWoodPanels wood_panels={products?.wood_panels} todayDate={todayDate} />
						</fieldset>
						<CommentFormDisplay targetId='products.wood_panels' />
					</div>
				</div>
			</div>
			<div className='dropdown-container'>
				<div
					className={`dropdown-header-container dropdown-container__header ${
						hide_sent_from ? 'dropdown-container__header--closed' : null
					}`}
				>
					<a href='' className='dropdown-container-trigger' onClick={(ev) => toggle(ev, 'sent_from')}>
						{sent_from.label}
					</a>
					<div className='dropdown-status-container'>
						<DropTownHeaderCheckBox
							name='sent_from[dates][ordered][value]'
							label={sent_from.dates.ordered.label}
							activeFormData={sent_from.dates.ordered.value === true}
						/>
						<DropTownHeaderCheckBox
							name='sent_from[dates][approved][value]'
							label={sent_from.dates.approved.label}
							activeFormData={sent_from.dates.approved.value === true}
						/>
						<DropTownHeaderCheckBox
							name='sent_from[dates][delivered][value]'
							label={sent_from.dates.delivered.label}
							dateName='sent_from[dates][delivered_date][value]'
							activeFormData={sent_from.dates.delivered.value === true}
							activeFormDateData={
								sent_from.dates.delivered_date.value ? sent_from.dates.delivered_date.value : todayDate
							}
							todayDate={todayDate}
						/>
						<ProjectAddCommentButton
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='sent_from.header'
							group='sent_from'
							comments={comments}
							label={sent_from.label}
						/>
					</div>
				</div>
				<div
					className={`local-grid local-grid--col-4 dropdown-content-initial-options ${
						hide_sent_from ? 'local-grid--hidden' : null
					}`}
				>
					<CommentFormDisplay targetId='sent_from.header' />
					<div>
						<RadioGroup field='sent_from[dealers][value]' initialValue={sent_from.dealers.value}>
							{sent_from &&
								sent_from.dealers.dyn_data.map((dealer) => {
									if (dealer.sys_name !== 'andre' && dealer.sys_name !== '__empty__') {
										return (
											<ProjectRadioFreeContainer
												label={dealer.name}
												value={dealer.sys_name}
												key={dealer.sys_name}
											/>
										);
									} else if (dealer.sys_name === 'andre') {
										return (
											<fieldset className='free-grid-item' key={dealer.sys_name}>
												<label>
													<Radio
														className='regular'
														field={dealer.sys_name}
														value={dealer.sys_name}
													/>
													<span>{dealer.name}</span>
												</label>
												<label>
													<Text
														type='text'
														field='sent_from[other][value]'
														initialValue={sent_from.other.value}
														allowEmptyString={true}
														disabled={
															!(
																formState.values.sent_from &&
																formState.values.sent_from.dealers &&
																formState.values.sent_from.dealers.value === 'andre'
															)
														}
													/>
												</label>
											</fieldset>
										);
									}
								})}
						</RadioGroup>
					</div>
				</div>
				<div className={`dropdown-content-options ${hide_sent_from ? 'dropdown-content-options--hidden' : ''}`}>
					<div className='local-grid local-grid--col-1'>
						<label className='grid-item'>
							<span className='input-description'>{sent_from.comment.label}</span>
							<TextArea
								field='sent_from[comment][value]'
								initialValue={sent_from.comment.value}
								allowEmptyString={true}
							/>
						</label>
					</div>

					<div className='local-grid local-grid--col-1'>
						<ButtonAndOptionsContainer
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='sent_from.work_surface'
							group='sent_from'
							comments={comments}
							label={sent_from.work_surface.label}
							inputName='sent_from[work_surface][value]'
							inputNameInitialValue={sent_from.work_surface.value}
							options={sent_from.work_surface.options}
						/>
					</div>

					<div className='local-grid local-grid--col-2'>
						<ButtonAndInputContainer
							inputName='sent_from[surface_thinkness][value]'
							inputNameInitialValue={sent_from.surface_thinkness.value}
							handleShowComments={handleShowComments}
							extraClass={true}
							targetId='sent_from.surface_thinkness'
							group='sent_from'
							comments={comments}
							label={sent_from.surface_thinkness.label}
						/>
					</div>
					<div className='local-grid local-grid--col-1'>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='sent_from.mixer_tap'
								group='sent_from'
								comments={comments}
								label={sent_from.mixer_tap.label}
							/>
							<fieldset className='options-container'>
								<span className='input-description'>{sent_from.mixer_tap.label}</span>

								<fieldset className='local-grid local-grid--col-2 option-list'>
									<ProjectMultiformDates
										dates={sent_from?.mixer_tap?.dates}
										checkBoxPath='sent_from[mixer_tap][dates]'
										dateName='sent_from[mixer_tap][dates][delivered_date][value]'
										activeFormDateData={
											sent_from?.mixer_tap?.dates?.delivered_date?.value
												? sent_from.mixer_tap.dates.delivered_date.value
												: todayDate
										}
										todayDate={props.todayDate}
									/>
									<label className='grid-item'>
										<span className='input-description'>
											{sent_from.mixer_tap.mixer_options.label}
										</span>
										<Select
											field='sent_from[mixer_tap][mixer_options][value]'
											className='regular'
											initialValue={sent_from.mixer_tap.mixer_options.value}
										>
											{sent_from.mixer_tap.mixer_options.options.map((option) => (
												<Option value={option.sys_name} key={option.sys_name}>
													{option.label}
												</Option>
											))}
										</Select>
									</label>
									<label className='grid-item'>
										<span className='input-description'>
											{sent_from.mixer_tap.mixer_tap_text.label}
										</span>
										<Text
											type='text'
											field='sent_from[mixer_tap][mixer_tap_text][value]'
											initialValue={sent_from.mixer_tap.mixer_tap_text.value}
											allowEmptyString={true}
										/>
									</label>
								</fieldset>
							</fieldset>
						</fieldset>
						<CommentFormDisplay targetId='sent_from.mixer_tap' />
					</div>
					<div className='local-grid local-grid--col-1'>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='sent_from.wash_basin'
								group='sent_from'
								comments={comments}
								label={sent_from.wash_basin.label}
							/>
							<fieldset className='options-container'>
								<span className='input-description'>{sent_from.wash_basin.label}</span>

								<fieldset className='local-grid local-grid--col-2 option-list'>
									<ProjectMultiformDates
										dates={sent_from?.wash_basin?.dates}
										checkBoxPath='sent_from[wash_basin][dates]'
										dateName='sent_from[wash_basin][dates][delivered_date][value]'
										activeFormDateData={
											sent_from?.wash_basin?.dates?.delivered_date?.value
												? sent_from.wash_basin.dates.delivered_date.value
												: todayDate
										}
										todayDate={props.todayDate}
									/>
									<label className='grid-item'>
										<span className='input-description'>{sent_from.wash_basin.brands.label}</span>
										<Select
											field='sent_from[wash_basin][brands][value]'
											className='regular'
											initialValue={sent_from.wash_basin.brands.value}
										>
											{sent_from.wash_basin.brands.dyn_data.map((brands) => (
												<Option value={brands.sys_name} key={brands.sys_name}>
													{brands.name}
												</Option>
											))}
										</Select>
									</label>
									<label className='grid-item'>
										<span className='input-description'>
											{sent_from.wash_basin.wash_basin_text.label}
										</span>
										<Text
											type='text'
											field='sent_from[wash_basin][wash_basin_text][value]'
											initialValue={sent_from.wash_basin.wash_basin_text.value}
											allowEmptyString={true}
										/>
									</label>
									<label className='grid-item'>
										<span className='input-description'>
											{sent_from.wash_basin.wash_accessories_text.label}
										</span>
										<Text
											type='text'
											field='sent_from[wash_basin][wash_accessories_text][value]'
											initialValue={sent_from.wash_basin.wash_accessories_text.value}
											allowEmptyString={true}
										/>
									</label>
								</fieldset>
							</fieldset>
						</fieldset>
						<CommentFormDisplay targetId='sent_from.wash_basin' />
					</div>
					<div className='local-grid local-grid--col-1'>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='sent_from.wash_basin.execution'
								group='sent_from'
								comments={comments}
								label={sent_from.wash_basin.execution.label}
							/>
							<fieldset className='options-container'>
								<span className='field-description'>{sent_from.wash_basin.execution.label}</span>
								<fieldset className='local-grid local-grid--col-1 option-list'>
									<RadioGroup
										field='sent_from[wash_basin][execution][value]'
										initialValue={sent_from.wash_basin.execution.value}
									>
										{sent_from.wash_basin.execution.options.map((brands) => (
											<ProjectRadioFreeContainer
												name={brands.sys_name}
												label={brands.label}
												value={brands.sys_name}
												key={brands.sys_name}
												extraClass='small'
											/>
										))}
									</RadioGroup>
									<label className='free-grid-item'>
										<Text
											type='text'
											field='sent_from[wash_basin][execution_various_text][value]'
											initialValue={sent_from.wash_basin.execution_various_text.value}
											allowEmptyString={true}
											disabled={
												!(
													formState.values.sent_from &&
													formState.values.sent_from.wash_basin &&
													formState.values.sent_from.wash_basin.execution &&
													formState.values.sent_from.wash_basin.execution.value === 'various'
												)
											}
										/>
									</label>
									<label className='free-grid-item'>
										<Checkbox
											field='sent_from[wash_basin][sent_to_skattekjaer][value]'
											initialValue={sent_from.wash_basin.sent_to_skattekjaer.value === true}
										/>
										<span>{sent_from.wash_basin.sent_to_skattekjaer.label}</span>
									</label>
								</fieldset>
							</fieldset>
						</fieldset>
						<CommentFormDisplay targetId='sent_from.wash_basin.execution' />
					</div>
					<div className='local-grid local-grid--col-1'>
						<fieldset className='grid-item button-and-options-container'>
							<ProjectAddCommentButton
								handleShowComments={handleShowComments}
								extraClass={true}
								targetId='sent_from.oil_type'
								group='sent_from'
								comments={comments}
								label={sent_from.oil_type.label}
							/>
							<fieldset className='options-container'>
								<span className='field-description'>{sent_from.oil_type.label}</span>
								<fieldset className='local-grid local-grid--col-1 option-list'>
									<RadioGroup
										field='sent_from[oil_type][value]'
										initialValue={sent_from.oil_type.value}
									>
										{sent_from &&
											sent_from.oil_type.options.map((oil_type) => {
												return (
													<ProjectRadioFreeContainer
														label={oil_type.label}
														value={oil_type.sys_name}
														key={oil_type.sys_name}
														extraClass='small'
													/>
												);
											})}
									</RadioGroup>
									<label className='free-grid-item'>
										<Text
											type='text'
											field='sent_from[oil_type_other][value]'
											initialValue={sent_from.oil_type_other.value}
											allowEmptyString={true}
											disabled={
												!(
													formState.values.sent_from &&
													formState.values.sent_from.oil_type &&
													formState.values.sent_from.oil_type.value === 'other'
												)
											}
										/>
									</label>
								</fieldset>
							</fieldset>
						</fieldset>
						<CommentFormDisplay targetId='sent_from.oil_type' />
					</div>
				</div>
			</div>
			<div className='dropdown-container'>
				<div
					className={`dropdown-header-container dropdown-container__header ${
						hide_doorhandle ? 'dropdown-container__header--closed' : null
					}`}
				>
					<a href='' className='dropdown-container-trigger' onClick={(ev) => toggle(ev, 'doorhandle')}>
						{doorhandle.label}
					</a>
					<div className='dropdown-status-container'>
						<DropTownHeaderCheckBox
							name='doorhandle[dates][ordered][value]'
							label={doorhandle.dates.ordered.label}
							activeFormData={doorhandle.dates.ordered.value === true}
						/>
						<DropTownHeaderCheckBox
							name='doorhandle[dates][approved][value]'
							label={doorhandle.dates.approved.label}
							activeFormData={doorhandle.dates.approved.value === true}
						/>
						<DropTownHeaderCheckBox
							name='doorhandle[dates][delivered][value]'
							label={doorhandle.dates.delivered.label}
							dateName='doorhandle[dates][delivered_date][value]'
							activeFormData={doorhandle.dates.delivered.value === true}
							activeFormDateData={
								doorhandle.dates.delivered_date.value
									? doorhandle.dates.delivered_date.value
									: todayDate
							}
							todayDate={todayDate}
						/>
						<ProjectAddCommentButton
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='doorhandle.header'
							group='doorhandle'
							comments={comments}
							label={doorhandle.label}
						/>
					</div>
				</div>
				<div
					className={`local-grid local-grid--col-2 dropdown-content-initial-options ${
						hide_doorhandle ? 'local-grid--hidden' : null
					}`}
				>
					<CommentFormDisplay targetId='doorhandle.header' />
					<label className='grid-item'>
						<span className='input-description'>{doorhandle.doorhandle_options.label}</span>
						<Select
							field='doorhandle[doorhandle_options][value]'
							className='regular'
							initialValue={doorhandle.doorhandle_options.value}
						>
							{doorhandle.doorhandle_options.options.map((option) => (
								<Option value={option.sys_name} key={option.sys_name}>
									{option.label}
								</Option>
							))}
						</Select>
					</label>
					<label className='grid-item'>
						<span className='input-description'>{doorhandle.doorhandle_text.label}</span>
						<Text
							type='text'
							field='doorhandle[doorhandle_text][value]'
							initialValue={doorhandle.doorhandle_text.value}
							allowEmptyString={true}
						/>
					</label>
				</div>
			</div>
			<div className='dropdown-container'>
				<div
					className={`dropdown-header-container dropdown-container__header ${
						hide_is_workdrawing_sent ? 'dropdown-container__header--closed' : null
					}`}
				>
					<a
						href=''
						className='dropdown-container-trigger'
						onClick={(ev) => toggle(ev, 'is_workdrawing_sent')}
					>
						{is_workdrawing_sent.label}
					</a>
					<div className='dropdown-status-container'>
						<ProjectAddCommentButton
							handleShowComments={handleShowComments}
							extraClass={false}
							targetId='is_workdrawing_sent.header'
							group='is_workdrawing_sent'
							comments={comments}
							label={is_workdrawing_sent.label}
						/>
					</div>
				</div>
				<div
					className={`local-grid local-grid--col-1 dropdown-content-initial-options ${
						hide_is_workdrawing_sent ? 'local-grid--hidden' : null
					}`}
				>
					<CommentFormDisplay targetId='is_workdrawing_sent.header' />
					<div>
						<label className='free-grid-item'>
							<Checkbox
								field='is_workdrawing_sent[value]'
								initialValue={is_workdrawing_sent.value === true}
							/>
							<span>{GeneralConstants['yes']}</span>
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProjectFormKitchen;
