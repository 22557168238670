import { Text } from 'informed';
import * as React from 'react';

import ErrorConstants from '../../../constants/error.constants';

const ProjectFormDateInput = ({ activeFormData, label, name, serverError, disabledField }) => (
	<label className='grid-item'>
		<span className='field-description'>{label}</span>
		{disabledField ? (
			<Text
				field={name}
				type='date'
				disabled
				className={`${serverError ? 'datepicker error-field' : 'datepicker'}`}
				initialValue={activeFormData}
			/>
		) : (
			<Text
				field={name}
				type='date'
				className={`${serverError ? 'datepicker error-field' : 'datepicker'}`}
				initialValue={activeFormData}
			/>
		)}

		{serverError && <span className='error-inline'>{ErrorConstants[serverError]}</span>}
	</label>
);

export default ProjectFormDateInput;
