import * as React from 'react';

import AddProject from '../../components/project-add/add-project.component';
import EditProject from '../../components/project-edit/edit-project.component';
import ProjectNavigation from '../../components/project-navigation/project-navigation.component';

const ProjectAddOrEdit = (props) => (
	<>
		<ProjectNavigation />
		<div className='page-wrapper'>{props.match.params.projectId ? <EditProject {...props} /> : <AddProject />}</div>
	</>
);

export default ProjectAddOrEdit;
