import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';
import { EditFormContext } from '../../context/edit-form-context';
import CommentForm from './comment-form.component';
import CommentGallery from './comment-gallery.component';
import CommentListView from './comment-list-view.component';

const CommentView = () => {
	const {
		commentGroups,
		dbImages,
		dbImageThumbs,
		handleImageThumbs,
		showCommentGallery,
		handleCloseGallery,
		handleHideComments,
		commentTargetGroup,
		handleSelectGroupChange
	} = React.useContext(EditFormContext);

	return (
		<div className='overlay-comment-container'>
			{dbImages.length && !dbImageThumbs.length ? handleImageThumbs(dbImages) : null}

			<div className='overlay-comment-wrapper'>
				{dbImageThumbs.length && showCommentGallery ? (
					<CommentGallery images={showCommentGallery} handleCloseGallery={handleCloseGallery} />
				) : null}
				<CommentForm />
				<div className='overlay-comment-data-container'>
					<label className='filter-container'>
						<span className='input-description'>{GeneralConstants.show_comment_for}</span>
						<select
							name='project_comment_groups'
							value={commentTargetGroup}
							onChange={(ev) => handleSelectGroupChange(ev)}
						>
							<option value='show_all'>{GeneralConstants.show_all_comments}</option>
							{commentGroups.length
								? commentGroups.map((commentGroup) => (
										<option value={commentGroup} key={commentGroup}>
											{GeneralConstants[commentGroup]}
										</option>
								  ))
								: null}
						</select>
					</label>
					{commentTargetGroup === 'show_all' ? (
						<>
							{commentGroups.length
								? commentGroups.map((commentGroup) => {
										return (
											<CommentListView
												key={commentGroup}
												commentGroup={commentGroup}
												commentGroupLabel={GeneralConstants[commentGroup]}
											/>
										);
								  })
								: null}
						</>
					) : (
						<>
							{commentTargetGroup ? (
								<CommentListView
									commentGroup={commentTargetGroup}
									commentGroupLabel={GeneralConstants[commentTargetGroup]}
								/>
							) : null}
						</>
					)}
				</div>
				<button
					type='button'
					className='overlay-comment-data-container-trigger'
					onClick={(ev) => handleHideComments(ev)}
				>
					{GeneralConstants.close}
				</button>
			</div>
		</div>
	);
};

export default CommentView;
