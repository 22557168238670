import { ArrayField, Text } from 'informed';
import * as React from 'react';

import GeneralConstants from '../../../constants/general.constants';
import CommentFormDisplay from '../../comments/comment-form-display.component';

const api = window.api;

class ProjectPaintMultiform extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			group: this.props.group,
			getInitialValues: this.props.getInitialValues,
			order_type: this.props.order_type,
			handleOrderTypeRemove: this.props.handleOrderTypeRemove,
			activeFormDataStatus: this.props.activeFormDataStatus,
			extraClass: true
		};
	}

	triggerComment = (path, group, iteration, label) => {
		if (
			this.props.order_type.knapphus[`${group}`].list.items.value[`${iteration}`] &&
			this.props.order_type.knapphus[`${group}`].list.items.value[`${iteration}`].id
		) {
			this.state.handleShowComments({
				targetId: path + this.props.order_type.knapphus[`${group}`].list.items.value[`${iteration}`].id,
				group: 'order_type',
				label
			});
		} else {
		}
	};

	getId = (group, iteration) => {
		if (
			this.props.order_type.knapphus[`${group}`].list.items.value[`${iteration}`] &&
			this.props.order_type.knapphus[`${group}`].list.items.value[`${iteration}`].id
		) {
			return this.props.order_type.knapphus[`${group}`].list.items.value[`${iteration}`].id;
		}
	};

	getCommentCount = (path, id) => {
		return this.props.comments.filter((comment) => comment.target_id == `${path}${id}`);
	};

	render() {
		const { group, getInitialValues, order_type, handleOrderTypeRemove, activeFormDataStatus } = this.state;
		return (
			<ArrayField
				field={`order_type[knapphus][${group}][list][items][value]`}
				initialValue={getInitialValues(group)}
			>
				{({ add, fields }) => (
					<>
						{fields.map(({ field, key, remove, initialValue }, i) => (
							<div className='local-grid local-grid--col-2 separate-line' key={key}>
								<Text
									type='hidden'
									field={`${field}.id`}
									initialValue={
										initialValue && initialValue.id ? initialValue.id : api.forms.getUniqueID()
									}
								/>
								{order_type.knapphus[`${group}`].list.pattern.front ? (
									<label className='grid-item'>
										<span className='input-description'>
											{order_type.knapphus[`${group}`].list.pattern.front.label}
										</span>
										<Text
											type='text'
											field={`${field}.front`}
											initialValue={initialValue && initialValue.front ? initialValue.front : ''}
											allowEmptyString
										/>
									</label>
								) : null}
								{order_type.knapphus[`${group}`].list.pattern.dekks ? (
									<label className='grid-item'>
										<span className='input-description'>
											{order_type.knapphus[`${group}`].list.pattern.dekks.label}
										</span>
										<Text
											type='text'
											field={`${field}.dekks`}
											initialValue={initialValue && initialValue.dekks ? initialValue.dekks : ''}
											allowEmptyString
										/>
									</label>
								) : null}
								<label className='grid-item'>
									<span className='input-description'>
										{order_type.knapphus[`${group}`].list.pattern.color_code.label}
									</span>
									<Text
										type='text'
										field={`${field}.color_code`}
										initialValue={
											initialValue && initialValue.color_code ? initialValue.color_code : ''
										}
										allowEmptyString
									/>
								</label>
								<label className='grid-item'>
									<span className='input-description'>
										{order_type.knapphus[`${group}`].list.pattern.moldings_lists.label}
									</span>
									<Text
										type='text'
										field={`${field}.moldings_lists`}
										initialValue={
											initialValue && initialValue.moldings_lists
												? initialValue.moldings_lists
												: ''
										}
										allowEmptyString
									/>
								</label>
								<label className='grid-item'>
									<span className='input-description'>
										{order_type.knapphus[`${group}`].list.pattern.planned_week.label}
									</span>
									<Text
										type='date'
										field={`${field}.planned_week`}
										allowEmptyString
										initialValue={
											initialValue && initialValue.planned_week ? initialValue.planned_week : ''
										}
									/>
								</label>
								<div className='comment-wrapper-fit'>
									<CommentFormDisplay
										targetId={
											initialValue && initialValue.id
												? `order_type.${
														order_type.knapphus[`${group}`].list.pattern.planned_week.label
												  }.${initialValue.id}`
												: `order_type.${
														order_type.knapphus[`${group}`].list.pattern.planned_week.label
												  }.${this.getId(group, i)}`
										}
									/>
								</div>
								{i !== 0 && (
									<div className='btn-container btn-container--right'>
										{activeFormDataStatus === 'ready' ? (
											<button className='btn-delete' title={GeneralConstants['delete']} disabled>
												{GeneralConstants['delete']}
											</button>
										) : (
											<button
												className='btn-delete'
												type='button'
												title={GeneralConstants['delete']}
												onClick={(ev) =>
													handleOrderTypeRemove(remove, initialValue, [`${group}`])
												}
											>
												{GeneralConstants['delete']}
											</button>
										)}
									</div>
								)}
							</div>
						))}
						<div className='btn-container btn-container--right'>
							<button
								className='btn small light add'
								type='button'
								title={`${GeneralConstants['add']} ${order_type.knapphus[`${group}`].label}`}
								onClick={add}
							>
								{`${GeneralConstants['add']} ${order_type.knapphus[`${group}`].label}`}
							</button>
						</div>
					</>
				)}
			</ArrayField>
		);
	}
}

export default ProjectPaintMultiform;
