import * as React from 'react';

const Spinner = () => (
	<div className='screen-loading'>
		<div className='lds-ring'>
			<div />
			<div />
			<div />
			<div />
		</div>
	</div>
);

export default Spinner;
