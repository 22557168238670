import { Radio, RadioGroup } from 'informed';
import * as React from 'react';

import CategoryConstants from '../../../constants/category.constants';
import ErrorConstants from '../../../constants/error.constants';

const ProjectTypeRadioGroup = ({ activeFormData, serverError, disabledField }) => (
	<>
		<div
			className={`project-type-container ${
				serverError && serverError.category ? 'project-type-container--error' : ''
			}`}
		>
			{disabledField ? (
				<RadioGroup field='category' initialValue={activeFormData} disabled>
					<label>
						<Radio value='kitchen' className='project-type' disabled />
						<span className='project-type--kitchen'>{CategoryConstants['kitchen']}</span>
					</label>
					<label>
						<Radio className='project-type' value='wardrobe' disabled />
						<span className='project-type--wardrobe'>{CategoryConstants['wardrobe']}</span>
					</label>
					<label>
						<Radio className='project-type' value='laundry' disabled />
						<span className='project-type--laundry'>{CategoryConstants['laundry']}</span>
					</label>
					<label>
						<Radio className='project-type' value='bathroom' disabled />
						<span className='project-type--bathroom'>{CategoryConstants['bathroom']}</span>
					</label>
				</RadioGroup>
			) : (
				<RadioGroup field='category' initialValue={activeFormData}>
					{/* <label> */}
					{/*	<Radio value='project_main' className='project-type' /> */}
					{/*	<span className='project-type--main'> */}
					{/*		{CategoryConstants['project_main']} */}
					{/*	</span> */}
					{/* </label> */}
					<label>
						<Radio value='kitchen' className='project-type' />
						<span className='project-type--kitchen'>{CategoryConstants['kitchen']}</span>
					</label>
					<label>
						<Radio className='project-type' value='wardrobe' />
						<span className='project-type--wardrobe'>{CategoryConstants['wardrobe']}</span>
					</label>
					<label>
						<Radio className='project-type' value='laundry' />
						<span className='project-type--laundry'>{CategoryConstants['laundry']}</span>
					</label>
					<label>
						<Radio className='project-type' value='bathroom' />
						<span className='project-type--bathroom'>{CategoryConstants['bathroom']}</span>
					</label>
				</RadioGroup>
			)}
		</div>
		{serverError && serverError.category ? (
			<span className='error-inline'>{ErrorConstants[serverError.category]}</span>
		) : null}
	</>
);

export default ProjectTypeRadioGroup;
