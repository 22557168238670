import { Document } from '@react-pdf/renderer';
import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';
import PdfOrderType from './PdfOrderType.component';
import {
	FormDataContainer,
	FormDataContainerWrapper,
	FormDataContent,
	FormDataContentContainer,
	FormDataTitle,
	FormDataTitleContainer,
	ImgContainer,
	InputHeader,
	InputHeaderText,
	PageContainer,
	PromoImg
} from './styles';

const hasOrderTypes = (productArray) => {
	const front = productArray.list.items.value?.filter((product) => product.front?.length > 0);

	const dekks = productArray.list.items.value?.filter((product) => product.dekks?.length > 0);

	const color_code = productArray.list.items.value?.filter((product) => product.color_code?.length > 0);

	const moldings_lists = productArray.list.items.value?.filter((product) => product.moldings_lists?.length > 0);
	return !!(front?.length || dekks?.length || color_code?.length || moldings_lists?.length);
};

const PdfKitchen = ({ orderType, activeFormData }) => {
	const { knapphus } = orderType;

	const [orderTypeCopy] = React.useState(orderType);
	return (
		<Document>
			<PageContainer size='A4'>
				<ImgContainer>
					<PromoImg source='../logo.png' />
				</ImgContainer>
				<FormDataContainer>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{GeneralConstants['project_title']}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{activeFormData.title}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.order_number.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.order_number.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.kitchen_model.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.kitchen_model.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
				</FormDataContainer>
				{hasOrderTypes(orderTypeCopy.knapphus.lacquer) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{knapphus.lacquer.label}</InputHeaderText>
						</InputHeader>
						<PdfOrderType group={orderTypeCopy.knapphus.lacquer} />
					</FormDataContainer>
				) : null}
			</PageContainer>
		</Document>
	);
};

export default PdfKitchen;
