import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import GeneralConstants from '../../constants/general.constants';
import {
	changeUserRoleStart,
	fetchUserActiveRoleStart,
	fetchUserRoleStart,
	signOutStart
} from '../../redux/user/user.actions';
import { selectActiveRole, selectCurrentUser } from '../../redux/user/user.selectors';
import TimeTracker from '../time-tracker/time-tracker.component';
import UserRoles from '../user-roles/user-roles.component';

class Header extends React.Component {
	constructor(props) {
		super(props);
		let stopWatch = {
			running: false,
			value: 0
		};
		if (localStorage.getItem('stop_watch')) {
			stopWatch = JSON.parse(localStorage.getItem('stop_watch'));
		}

		this.state = {
			showRoleDropDown: false,
			showTracker: false,
			running: stopWatch.running,
			value: stopWatch.value
		};

		this.toggleUserRoleBar = this.toggleUserRoleBar.bind(this);
		this.toggleTracker = this.toggleTracker.bind(this);
		this.startTracker = this.startTracker.bind(this);
		this.stopTracker = this.stopTracker.bind(this);
		this.resetTracker = this.resetTracker.bind(this);
		this.saveTrackerChanges = this.saveTrackerChanges.bind(this);
		this.resetTrackerAfterSubmit = this.resetTrackerAfterSubmit.bind(this);
	}

	componentDidMount() {
		const { currentUser } = this.props;
		this.props.fetchUserActiveRoleStart({ id: currentUser.id });
		if (this.state.running) {
			this.timer = setInterval(() => this.forceUpdate(), 1000 | 0);
		}
	}

	componentWillUnmount() {
		if (this.state.running) {
			clearInterval(this.timer);
		}
	}

	toggleUserRoleBar(ev) {
		ev.preventDefault();
		return this.setState({
			showRoleDropDown: !this.state.showRoleDropDown,
			showTracker: false
		});
	}

	onSubmit = (formValues) => {
		const { role } = formValues;

		const { currentUser } = this.props;
		this.props.changeUserRoleStart({ id: currentUser.id, label: role });
		return this.setState({
			showRoleDropDown: !this.state.showRoleDropDown
		});
	};

	toggleTracker(ev) {
		if (ev) {
			ev.preventDefault();
		}
		this.setState((prevState) => ({
			...prevState,
			showTracker: !prevState.showTracker,
			showRoleDropDown: false
		}));
	}

	startTracker(ev) {
		ev.preventDefault();

		const now = Date.now();
		this.setState(({ running, value }) => {
			if (running) return null;
			this.timer = setInterval(() => this.forceUpdate(), 1000 | 0);
			return this.saveTrackerChanges({
				running: true,
				value: value - now
			});
		});
	}

	stopTracker(ev) {
		ev.preventDefault();
		const now = Date.now();
		this.setState(({ running, value }) => {
			if (!running) return null;
			clearInterval(this.timer);
			return this.saveTrackerChanges({
				running: false,
				value: value + now
			});
		});
	}

	resetTracker(ev) {
		ev.preventDefault();
		this.setState(() => {
			return this.saveTrackerChanges({
				running: false,
				value: 0
			});
		});
	}

	resetTrackerAfterSubmit() {
		this.setState(() => {
			return this.saveTrackerChanges({
				running: false,
				value: 0
			});
		});
	}

	// eslint-disable-next-line class-methods-use-this
	saveTrackerChanges(state) {
		if ('stop_watch') {
			localStorage.setItem('stop_watch', JSON.stringify(state));
		}
		return state;
	}

	render() {
		const { currentUser, signOutStart, currentUserActiveRole } = this.props;

		const { showRoleDropDown, showTracker, running, value } = this.state;

		const timestamp = running ? Date.now() + value : value;

		const h = Math.floor(timestamp / 3600000);

		const m = Math.floor(timestamp / 60000) % 60;

		const s = Math.floor(timestamp / 1000) % 60;

		const _ = (nr, length = 2, padding = 0) => String(nr).padStart(length, padding);

		return (
			<>
				<nav className='user-nav'>
					<div className='user-nav-role-container'>
						<span className='user-nav--details'>
							{currentUser.firstname} {currentUser.lastname}
						</span>
						{currentUserActiveRole && (
							<a href='#' className='user-nav--roles' onClick={this.toggleUserRoleBar}>
								{currentUserActiveRole.name}
							</a>
						)}
					</div>
					<Link to='/login' className='user-nav--logout' onClick={signOutStart} />
					<div className='time-tracking-header-container'>
						<a
							href='#'
							className={`user-nav--time-tracking ${
								running ? 'user-nav--time-tracking--play' : 'user-nav--time-tracking--stop'
							}`}
							onClick={this.toggleTracker}
						>
							{`${_(h)}h ${_(m)}min ${_(s)}s`}
						</a>
						{running ? (
							<a
								href='#'
								className='user-nav--time-tracking-stopper tracking-stopper--stop'
								onClick={this.stopTracker}
							/>
						) : (
							<a href='#' className='user-nav--time-tracking-stopper' onClick={this.startTracker} />
						)}
					</div>
				</nav>
				{showRoleDropDown && (
					<div className='user-role-container'>
						<span className='action-title'>{GeneralConstants['edit_your_role']}</span>
						<UserRoles currentUserActiveRole={currentUserActiveRole} onSubmit={this.onSubmit} />
					</div>
				)}
				{showTracker && (
					<TimeTracker
						startTracker={this.startTracker}
						stopTracker={this.stopTracker}
						resetTracker={this.resetTracker}
						resetTrackerAfterSubmit={this.resetTrackerAfterSubmit}
						running={running}
						value={value}
						currentUser={currentUser}
						currentUserActiveRole={currentUserActiveRole}
						toggleTracker={this.toggleTracker}
					/>
				)}
			</>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	signOutStart: () => dispatch(signOutStart()),
	changeUserRoleStart: (id, label) => dispatch(changeUserRoleStart(id, label)),
	fetchUserRoleStart: (id) => dispatch(fetchUserRoleStart(id)),
	fetchUserActiveRoleStart: (id) => dispatch(fetchUserActiveRoleStart(id))
});

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	currentUserActiveRole: selectActiveRole
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
