import { Checkbox, Text, useFieldState } from 'informed';
import * as React from 'react';

import DemolitionConstants from '../../../constants/demolition.constants';
import GeneralConstants from '../../../constants/general.constants';
import CommentFormDisplay from '../../comments/comment-form-display.component';
import ProjectAddCommentButton from '../../project-form-elements/project-add-comment-button/project-add-comment-button.component';
import ProjectFormDateInput from '../../project-form-elements/project-form-date-input/project-form-date-input.component';
import ProjectFormSelectInput from '../../project-form-elements/project-form-select-input/project-form-select-input.component';
import ProjectFormTextInput from '../../project-form-elements/project-form-text-input/project-form-text-input.component';

const ProjectService = ({
	activeFormData,
	name,
	groupName,
	label,
	serverError,
	dynamicType,
	dynamicMaterial,
	handleShowComments,
	group,
	comments
}) => {
	const { value: show_list } = useFieldState(name);

	const serverErrorBrandList = serverError.map((error) => {
		if (Object.keys(error) == `${groupName}`) {
			return error;
		}
	});

	const materialErrorObj = serverErrorBrandList.map((error) => {
		if (Object.keys(error) == `${groupName}`) {
			return error;
		}
	});

	let materialError;

	let typeError;

	let dateError;

	if (materialErrorObj && materialErrorObj[0]) {
		if (materialErrorObj[0][`${groupName}`][`${groupName}_material`]) {
			materialError = materialErrorObj[0][`${groupName}`][`${groupName}_material`];
		}
		if (materialErrorObj[0][`${groupName}`][`${groupName}_type`]) {
			typeError = materialErrorObj[0][`${groupName}`][`${groupName}_type`];
		}
		if (materialErrorObj[0][`${groupName}`][`date`]) {
			dateError = materialErrorObj[0][`${groupName}`][`date`];
		}
	}

	return (
		<fieldset className='grid-item button-and-options-container'>
			<ProjectAddCommentButton
				handleShowComments={handleShowComments}
				extraClass='small'
				targetId={groupName}
				group={group}
				comments={comments}
				label={label}
			/>
			<fieldset className='options-container'>
				<span className='field-description'>{label}</span>
				<fieldset className='local-grid local-grid--col-2 option-list'>
					<label className='free-grid-item'>
						<Checkbox
							field={name}
							initialValue={!!(activeFormData && activeFormData.active_service == 'checked')}
						/>
						<span>{GeneralConstants['yes']}</span>
					</label>

					<div className={`local-grid local-grid--col-2 ${show_list === true ? '' : 'local-grid--hidden'}`}>
						<ProjectFormSelectInput
							serviceOptionValues={dynamicType}
							label={DemolitionConstants['type']}
							activeFormData={`${activeFormData && activeFormData.type ? activeFormData.type : ''}`}
							name={`${groupName}[type]`}
							defaultOption={DemolitionConstants['select_type']}
							serverError={typeError && typeError.length ? typeError : null}
						/>
						<ProjectFormSelectInput
							serviceOptionValues={dynamicMaterial}
							label={DemolitionConstants['material']}
							activeFormData={`${
								activeFormData && activeFormData.material ? activeFormData.material : ''
							}`}
							name={`${groupName}[material]`}
							defaultOption={DemolitionConstants['select_material']}
							serverError={materialError && materialError.length ? materialError : null}
						/>
						<ProjectFormTextInput
							name={`${groupName}[material_amount]`}
							label={DemolitionConstants['m2m']}
							activeFormData={`${
								activeFormData && activeFormData.material_amount ? activeFormData.material_amount : ''
							}`}
						/>
						<ProjectFormTextInput
							name={`${groupName}[description]`}
							label={DemolitionConstants['description']}
							activeFormData={`${
								activeFormData && activeFormData.description ? activeFormData.description : ''
							}`}
						/>
						<ProjectFormDateInput
							name={`${groupName}[date]`}
							label={DemolitionConstants['date']}
							activeFormData={`${activeFormData && activeFormData.date ? activeFormData.date : ''}`}
							serverError={dateError && dateError.length ? dateError : null}
						/>
						<Text field={`${groupName}[field_name]`} type='hidden' initialValue={`${groupName}`} />
						{activeFormData && activeFormData.id ? (
							<Text field={`${groupName}[id]`} type='hidden' initialValue={activeFormData.id} />
						) : (
							''
						)}
						<Text
							field={`${groupName}[project_id]`}
							type='hidden'
							initialValue={`${
								activeFormData && activeFormData.project_id ? activeFormData.project_id : ''
							}`}
						/>
					</div>
				</fieldset>

				<CommentFormDisplay targetId={groupName} />
			</fieldset>
		</fieldset>
	);
};

export default ProjectService;
