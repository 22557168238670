import 'react-responsive-carousel/lib/styles/carousel.min.css';

import * as React from 'react';
import { Carousel } from 'react-responsive-carousel';

import GeneralConstants from '../../constants/general.constants';

const CommentGallery = ({ images, handleCloseGallery }) => {
	return (
		<div className='carousel-overlay'>
			<Carousel className='carousel-container' infiniteLoop showThumbs={false} swipeable emulateTouch>
				{images.map((imgArr) =>
					imgArr.map((imageThumb) => (
						<img
							src={`data:image/jpeg;base64,${imageThumb.thumbnail}`}
							alt={imageThumb.metadata && imageThumb.metadata.name}
							key={imageThumb.metadata && imageThumb.metadata.name}
						/>
					))
				)}
			</Carousel>
			<button type='button' className='close-gallery' onClick={handleCloseGallery}>
				{GeneralConstants['close']}
			</button>
		</div>
	);
};

export default CommentGallery;
