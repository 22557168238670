import { Checkbox, Text } from 'informed';
import * as React from 'react';

const ProjectMultiformDates = ({ dates, checkBoxPath, dateName, todayDate, activeFormDateData }) => {
	function dateFormat(time) {
		const d = new Date(time);
		return d
			.toLocaleDateString('en-GB')
			.replace(`, `, '-')
			.replace(`/`, '.')
			.replace(`/`, '.')
			.replace(/:[^:]*$/, '');
	}

	return (
		<div className='date-wrapper'>
			<label className='free-grid-item'>
				{dateName && (
					<Text
						field={dateName}
						initialValue={dates?.delivered?.value ? activeFormDateData : todayDate}
						type='hidden'
					/>
				)}
				<Checkbox
					field={`${checkBoxPath}[ordered][value]`}
					initialValue={dates?.ordered?.value === true}
					className='right'
				/>
				<div className='project-section-status'>
					<span className='project-section-status__name'>{dates?.ordered?.label}</span>
				</div>
			</label>
			<label className='free-grid-item'>
				<Checkbox
					field={`${checkBoxPath}[approved][value]`}
					initialValue={dates?.approved?.value === true}
					className='right'
				/>
				<div className='project-section-status'>
					<span className='project-section-status__name'>{dates?.approved?.label}</span>
				</div>
			</label>
			<label className='free-grid-item'>
				<Checkbox
					field={`${checkBoxPath}[delivered][value]`}
					initialValue={dates?.delivered?.value === true}
					className='right'
				/>
				<div className='project-section-status'>
					<span className='project-section-status__name'>{dates?.delivered?.label}</span>
					<span className='project-section-status__date'>
						{dates?.delivered?.value === true ? dateFormat(activeFormDateData) : null}
					</span>
				</div>
			</label>
		</div>
	);
};

export default ProjectMultiformDates;
