import { Document } from '@react-pdf/renderer';
import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';
import {
	FormDataContainer,
	FormDataContainerWrapper,
	FormDataContent,
	FormDataContentContainer,
	FormDataTitle,
	FormDataTitleContainer,
	ImgContainer,
	InputHeader,
	InputHeaderText,
	InputWrapper,
	PageContainer,
	PromoImg
} from './styles';

const PdfBathroom = ({ orderType, activeFormData }) => {
	const formatDateToDayAndMonthAndYear = (date) => {
		const formatDate = date.split('T')[0];
		return formatDate
			? // eslint-disable-next-line max-len
			  `${formatDate[8]}${formatDate[9]}.${formatDate[5]}${formatDate[6]}.${formatDate[0]}${formatDate[1]}${formatDate[2]}${formatDate[3]}`
			: '-';
	};

	const { knapphus } = orderType;
	return (
		<Document>
			<PageContainer size='A4'>
				<ImgContainer>
					<PromoImg source='../logo.png' />
				</ImgContainer>
				<FormDataContainer>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{GeneralConstants['project_title']}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{activeFormData.title}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.order_number.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.order_number.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{knapphus.bathroom_model.label}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{knapphus.bathroom_model.value}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
				</FormDataContainer>
				<FormDataContainer>
					<InputHeader>
						<InputHeaderText>{knapphus.lacquer.label}</InputHeaderText>
					</InputHeader>
					<InputWrapper>
						<FormDataContainerWrapper>
							<FormDataTitleContainer>
								<FormDataTitle>{knapphus.lacquer.front.label}</FormDataTitle>
							</FormDataTitleContainer>
							<FormDataContentContainer>
								<FormDataContent>{knapphus.lacquer.front.value}</FormDataContent>
							</FormDataContentContainer>
						</FormDataContainerWrapper>
						<FormDataContainerWrapper>
							<FormDataTitleContainer>
								<FormDataTitle>{knapphus.lacquer.dekks.label}</FormDataTitle>
							</FormDataTitleContainer>
							<FormDataContentContainer>
								<FormDataContent>{knapphus.lacquer.dekks.value}</FormDataContent>
							</FormDataContentContainer>
						</FormDataContainerWrapper>
						<FormDataContainerWrapper>
							<FormDataTitleContainer>
								<FormDataTitle>{knapphus.color_code.label}</FormDataTitle>
							</FormDataTitleContainer>
							<FormDataContentContainer>
								<FormDataContent>{knapphus.color_code.value}</FormDataContent>
							</FormDataContentContainer>
						</FormDataContainerWrapper>
						<FormDataContainerWrapper>
							<FormDataTitleContainer>
								<FormDataTitle>{knapphus.lacquer?.moldings_lists?.label}</FormDataTitle>
							</FormDataTitleContainer>
							<FormDataContentContainer>
								<FormDataContent>{knapphus.lacquer?.moldings_lists?.value}</FormDataContent>
							</FormDataContentContainer>
						</FormDataContainerWrapper>
						<FormDataContainerWrapper>
							<FormDataTitleContainer>
								<FormDataTitle>{knapphus.lacquer?.planned_week?.label}</FormDataTitle>
							</FormDataTitleContainer>
							<FormDataContentContainer>
								<FormDataContent>
									{knapphus.lacquer?.planned_week?.value
										? formatDateToDayAndMonthAndYear(knapphus.lacquer?.planned_week?.value)
										: null}
								</FormDataContent>
							</FormDataContentContainer>
						</FormDataContainerWrapper>
					</InputWrapper>
				</FormDataContainer>
			</PageContainer>
		</Document>
	);
};

export default PdfBathroom;
