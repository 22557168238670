import { Checkbox, Text, useFieldState } from 'informed';
import * as React from 'react';

import DemolitionConstants from '../../../constants/demolition.constants';
import GeneralConstants from '../../../constants/general.constants';
import CommentFormDisplay from '../../comments/comment-form-display.component';
import ProjectAddCommentButton from '../../project-form-elements/project-add-comment-button/project-add-comment-button.component';
import ProjectFormDateInput from '../../project-form-elements/project-form-date-input/project-form-date-input.component';
import ProjectFormEmailInput from '../../project-form-elements/project-form-email-input/project-form-email-input.component';
import ProjectFormTextInput from '../../project-form-elements/project-form-text-input/project-form-text-input.component';

const ProjectServicePerson = ({
	label,
	name,
	activeFormData,
	groupName,
	serverError,
	handleShowComments,
	group,
	comments
}) => {
	let typeError;
	if (serverError && serverError[0]) {
		if (serverError[0][`${groupName}`]) {
			typeError = serverError[0][`${groupName}`];
		}
	}

	const { value: show_list } = useFieldState(name);
	return (
		<fieldset className='grid-item button-and-options-container'>
			<ProjectAddCommentButton
				handleShowComments={handleShowComments}
				extraClass='small'
				targetId={groupName}
				group={group}
				comments={comments}
				label={label}
			/>
			<fieldset className='options-container'>
				<span className='field-description'>{label}</span>
				<fieldset className='local-grid local-grid--col-2 option-list'>
					<label className='free-grid-item'>
						<Checkbox
							field={name}
							initialValue={!!(activeFormData && activeFormData.active_service == 'checked')}
						/>
						<span>{GeneralConstants['yes']}</span>
					</label>
					<div className={`local-grid local-grid--col-2 ${show_list === true ? '' : 'local-grid--hidden'}`}>
						<ProjectFormTextInput
							name={`${groupName}[name]`}
							label={DemolitionConstants['name']}
							activeFormData={`${activeFormData && activeFormData.name ? activeFormData.name : ''}`}
						/>
						<ProjectFormTextInput
							name={`${groupName}[phone]`}
							label={DemolitionConstants['phone']}
							activeFormData={`${activeFormData && activeFormData.phone ? activeFormData.phone : ''}`}
						/>
						<ProjectFormEmailInput
							name={`${groupName}[email]`}
							label={DemolitionConstants['email']}
							activeFormData={`${activeFormData && activeFormData.email ? activeFormData.email : ''}`}
							serverError={typeError && typeError.email ? typeError.email : null}
						/>
						<ProjectFormDateInput
							name={`${groupName}[date]`}
							label={DemolitionConstants['date']}
							activeFormData={`${activeFormData && activeFormData.date ? activeFormData.date : ''}`}
							serverError={typeError && typeError.date ? typeError.date : null}
						/>
						<Text field={`${groupName}[field_name]`} type='hidden' initialValue={`${groupName}`} />
						{activeFormData && activeFormData.id ? (
							<Text field={`${groupName}[id]`} type='hidden' initialValue={activeFormData.id} />
						) : (
							''
						)}
						<Text
							field={`${groupName}[project_id]`}
							type='hidden'
							initialValue={`${
								activeFormData && activeFormData.project_id ? activeFormData.project_id : ''
							}`}
						/>
					</div>
				</fieldset>

				<CommentFormDisplay targetId={groupName} />
			</fieldset>
		</fieldset>
	);
};

export default ProjectServicePerson;
