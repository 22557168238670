import * as React from 'react';
import { useSelector } from 'react-redux';

import GeneralConstants from '../../constants/general.constants';
import { EditFormContext } from '../../context/edit-form-context';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import Spinner from '../spinner/spinner.component';

const CommentListView = ({ commentGroup, commentGroupLabel }) => {
	const {
		uniqueTargetIDs,
		comments,
		allUsers,
		showDeleteConfirmID,
		handleCommentDelete,
		handleToggleDeleteConfirm,
		handleCommentEditMode,
		dbImageThumbs,
		dbFiles,
		handleDocumentDownload,
		handleCommentGallery,
		isUploadingDoc,
		isUploadingImg,
		activeFormData
	} = React.useContext(EditFormContext);

	const currentUser = useSelector(selectCurrentUser);

	const dateFormat = (time) => {
		const d = new Date(time);

		return d
			.toLocaleString('en-GB')
			.replace(`,`, ' -')
			.replace(`/`, '.')
			.replace(`/`, '.')
			.replace(/:[^:]*$/, '');
	};
	return (
		<div className='overlay-comment-list'>
			<h4>{commentGroupLabel}</h4>
			{uniqueTargetIDs
				? uniqueTargetIDs
						.filter((uniqueComment) => {
							if (uniqueComment.group === commentGroup) {
								return uniqueComment;
							}
						})
						.map((commentData) => (
							<div key={commentData.target_id} className='sub-group-content'>
								<h3 id={commentData.target_id}>{commentData.label}</h3>
								{comments.length
									? comments.map((comment) => {
											if (comment.target_id === commentData.target_id) {
												return (
													<div
														className='comment-data-container comment-data-container--light'
														key={comment.id}
													>
														{comment.comment.length && (
															<div className='comment-data__content'>
																{comment.comment}
																<div className='file-container file-container--comment'>
																	<div className='file-content-container file-content-container--comment'>
																		{dbFiles.map((file, i) => {
																			if (file.name.includes(comment.id)) {
																				return (
																					<a
																						href='#'
																						className='document'
																						key={i}
																						onClick={(ev) =>
																							handleDocumentDownload(
																								ev,
																								file
																							)
																						}
																					>
																						{file.name}
																					</a>
																				);
																			}
																		})}
																		{isUploadingDoc && <Spinner />}
																	</div>
																	<div className='picture-content-container picture-content-container--comment'>
																		{isUploadingImg && <Spinner />}
																		{dbImageThumbs.length
																			? dbImageThumbs.map((imageThumbEntries) =>
																					imageThumbEntries.entries.map(
																						(imageThumb) => {
																							const data =
																								imageThumb.thumbnail;
																							if (
																								imageThumb.metadata &&
																								imageThumb.metadata.name.includes(
																									comment.id
																								)
																							) {
																								return (
																									<a
																										href='#'
																										key={
																											imageThumb.metadata &&
																											imageThumb
																												.metadata
																												.name
																										}
																										onClick={(ev) =>
																											handleCommentGallery(
																												ev,
																												comment.id
																											)
																										}
																									>
																										<img
																											src={`data:image/jpeg;base64,${data}`}
																											alt={
																												imageThumb.metadata &&
																												imageThumb
																													.metadata
																													.name
																											}
																										/>
																									</a>
																								);
																							}
																						}
																					)
																			  )
																			: null}
																	</div>
																</div>
															</div>
														)}
														<div className='comment-data-wrapper'>
															{allUsers
																? allUsers.map((user) => {
																		if (user.id === comment.user_id) {
																			return (
																				<div
																					className='comment-data-list'
																					key={user.id}
																				>
																					<div className='comment-data__author'>
																						{user?.firstname}{' '}
																						{user?.lastname}
																					</div>
																					<div className='comment-data__date'>
																						{dateFormat(
																							comment?.modified_datetime
																						)}
																					</div>
																				</div>
																			);
																		}
																		return null;
																  })
																: null}

															<div className='comment-edit-delete-container'>
																{showDeleteConfirmID === comment.id && (
																	<div className='delete-confirmation-container'>
																		<span className='confirm-label'>
																			{GeneralConstants.want_to_delete_comment}
																		</span>
																		<a
																			href=''
																			className='delete-confirm'
																			onClick={(e) =>
																				handleCommentDelete(e, comment.id)
																			}
																		>
																			{GeneralConstants.yes}
																		</a>
																		<a
																			href=''
																			className='cancel-confirm'
																			onClick={(e) =>
																				handleToggleDeleteConfirm(e, false)
																			}
																		>
																			{GeneralConstants.no}
																		</a>
																	</div>
																)}
																{activeFormData.status === 'archived' ||
																parseInt(currentUser.id, 10) !== comment.user_id ? (
																	<>
																		<button
																			type='button'
																			className='comment-delete comment-delete--disabled'
																			disabled
																		/>
																		<button
																			type='button'
																			className='comment-edit comment-edit--disabled'
																			disabled
																		/>
																	</>
																) : (
																	<>
																		<button
																			type='button'
																			className='comment-delete'
																			onClick={(e) =>
																				handleToggleDeleteConfirm(e, comment.id)
																			}
																		/>
																		<button
																			type='button'
																			className='comment-edit'
																			onClick={(e) =>
																				handleCommentEditMode(e, comment.id)
																			}
																		/>
																	</>
																)}
															</div>
														</div>
													</div>
												);
											}
									  })
									: null}
							</div>
						))
				: null}
		</div>
	);
};

export default CommentListView;
