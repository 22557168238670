import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';

const ClipboardLink = ({ address }) => {
	function copyToClipboard(e) {
		e.preventDefault();
		navigator.clipboard.writeText(address);
	}

	return (
		<div className='map-item__project-url'>
			<a onClick={copyToClipboard} href='#'>
				{GeneralConstants['go']}
			</a>
		</div>
	);
};

export default ClipboardLink;
