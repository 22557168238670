import { Form, formState, Option, Radio, RadioGroup, Select, Text } from 'informed';
import * as React from 'react';

import ErrorConstants from '../../constants/error.constants';
import GeneralConstants from '../../constants/general.constants';
import ProjectFormTextInput from '../project-form-elements/project-form-text-input/project-form-text-input.component';
import ProjectTypeRadioGroup from '../project-form-elements/project-type-radio-group/project-type-radio-group.component';

const ProjectFormStartPage = (props) => {
	const {
		handleOnSubmit,
		error,
		parentProjects,
		serverClientList,
		handleCreateNewClient,
		handleCreateNewProjectGroup,
		newlyCreatedClient
	} = props;

	const [selectedClientId, setClientID] = React.useState(newlyCreatedClient.id);

	const [selectedGroupId, setGroupID] = React.useState(
		parentProjects && parentProjects.length ? parentProjects[0] : {}
	);

	return (
		<Form onSubmit={(values) => handleOnSubmit(values, selectedClientId)} className='add-new-project'>
			{({ formState }) => (
				<>
					<ProjectTypeRadioGroup
						activeFormData='kitchen'
						serverError={error?.body?.category ? error.body : null}
					/>

					<div className='section-container'>
						<div className='local-grid local-grid--col-2 separate-line'>
							<ProjectFormTextInput
								name='title'
								label={GeneralConstants['project_title']}
								serverError={error?.body?.title ? ErrorConstants[error.body.title] : null}
							/>
							<label className='grid-item grid-item--relative'>
								<span className='input-description'>{GeneralConstants['select_main_project']}</span>
								<Select
									field='parent_id'
									className={error?.body?.parent_id ? 'error-field' : ''}
									onChange={(ev) =>
										setGroupID(parentProjects.filter((project) => project.id == ev.target.value)[0])
									}
									value={selectedGroupId}
								>
									<Option value=''>{GeneralConstants['select_main_project']}</Option>
									{parentProjects &&
										parentProjects.map((project) => (
											<Option key={project.id} value={project.id}>
												{project.title}
											</Option>
										))}
								</Select>
								{error?.body?.parent_id ? (
									<span className='error-inline'>{ErrorConstants[error.body.parent_id]}</span>
								) : null}
								<button
									className='add-new-client-btn'
									onClick={(ev) => handleCreateNewProjectGroup(ev)}
								>
									+
								</button>
							</label>
						</div>
						<div className='local-grid local-grid--col-2 separate-line'>
							<label className='grid-item grid-item--relative'>
								<span className='input-description'>{GeneralConstants['select_client']}</span>
								<Select
									field='client_id'
									onChange={(ev) => setClientID(ev.target.value)}
									className={error?.body?.client_id ? 'error-field' : ''}
									value={selectedClientId}
								>
									<Option value=''>{GeneralConstants['select_client_2']}</Option>
									{serverClientList.map((serverClient) => (
										<Option value={serverClient.id} key={serverClient.id}>
											{serverClient.title}
										</Option>
									))}
								</Select>
								{error?.body?.client_id ? (
									<span className='error-inline'>{ErrorConstants[error.body.client_id]}</span>
								) : null}
								<button className='add-new-client-btn' onClick={(ev) => handleCreateNewClient(ev)}>
									+
								</button>
							</label>
							{selectedClientId ? (
								serverClientList.map((serverClient) => {
									if (serverClient.id == selectedClientId) {
										return (
											<label className='grid-item' key={serverClient.id}>
												<span className='field-description'>
													{GeneralConstants['phone_nr']}
												</span>
												<Text
													field='client_phone'
													type='text'
													initialValue={serverClient.phone}
												/>
											</label>
										);
									}
								})
							) : (
								<label className='grid-item'>
									<span className='field-description'>{GeneralConstants['phone_nr']}</span>
									<Text field='client_phone' type='text' initialValue='' />
								</label>
							)}

							{selectedClientId ? (
								serverClientList.map((serverClient) => {
									if (serverClient.id == selectedClientId) {
										return (
											<label className='grid-item' key={serverClient.id}>
												<span className='field-description'>{GeneralConstants['email']}</span>
												<Text
													field='client_email'
													type='text'
													initialValue={serverClient.email}
												/>
												{error?.body?.client_email ? (
													<span className='error-inline'>
														{ErrorConstants[error.body.client_email]}
													</span>
												) : null}
											</label>
										);
									}
								})
							) : (
								<label className='grid-item'>
									<span className='field-description'>{GeneralConstants['email']}</span>
									<Text field='client_email' type='text' initialValue='' />
									{error?.body?.client_email ? (
										<span className='error-inline'>{ErrorConstants[error.body.client_email]}</span>
									) : null}
								</label>
							)}
						</div>
						<div className='local-grid local-grid--col-2'>
							<RadioGroup field='type' initialValue='project'>
								<fieldset className='grid-item button-and-input-container'>
									<label className='full-width full-width--extra-padding'>
										<Radio value='project' className='regular' />
										<span className='input-description'>{GeneralConstants['project_based']}</span>
									</label>
								</fieldset>
								<fieldset className='grid-item button-and-input-container'>
									<label className='full-width'>
										<span className='field-description'>{GeneralConstants['price']}</span>

										<Text
											field='price'
											type='text'
											className={`${error?.body?.price ? 'error-field' : ''}`}
											disabled={formState.values.type !== 'project'}
										/>
										{error?.body?.price ? (
											<span className='error-inline'>{ErrorConstants[error.body.price]}</span>
										) : null}
									</label>
								</fieldset>
								<fieldset className='grid-item button-and-input-container'>
									<label className='full-width'>
										<Radio value='hour' className='regular' />
										<span className='input-description'>{GeneralConstants['hour_based']}</span>
									</label>
								</fieldset>
								<fieldset className='grid-item button-and-input-container'>
									<label className='full-width'>
										<Radio value='damage' className='regular' />
										<span className='input-description'>{GeneralConstants['damage_based']}</span>
									</label>
								</fieldset>
							</RadioGroup>
						</div>
					</div>
					<div className='btn-container btn-container--center'>
						<button type='submit' className='btn btn-large light-green btn--create'>
							{GeneralConstants['create_project']}
						</button>
					</div>
				</>
			)}
		</Form>
	);
};

export default ProjectFormStartPage;
