import * as React from 'react';

import ProjectPaintMultiform from './ProjectPaintMultiform.component';

const ProjectPaint = (props) => {
	return (
		<fieldset className='options-container'>
			<span className='field-description'>{props.optionLabel}</span>
			<fieldset className='local-grid local-grid--col-2 option-list'>
				<ProjectPaintMultiform
					getInitialValues={props.getInitialValues}
					group={props.group}
					order_type={props.order_type}
					handleOrderTypeRemove={props.handleOrderTypeRemove}
					handleShowComments={props.handleShowComments}
					comments={props.comments}
					activeFormDataStatus={props.activeFormDataStatus}
				/>
			</fieldset>
		</fieldset>
	);
};

export default ProjectPaint;
