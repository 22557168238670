import * as React from 'react';

import DemolitionConstants from '../../../constants/demolition.constants';
import ProjectService from '../../project-form-sections/project-service/project-service.component';
import ProjectServicePerson from '../../project-form-sections/project-service-person/project-service-person.component';

const DemolitionAndServicesData = ({
	serviceList,
	servicePersonList,
	serverError,
	serviceTypeAndMaterial,
	demolition_and_services,
	handleShowComments,
	group,
	comments
}) => {
	const serverCeiling = serviceList.filter((service) => service.field_name === 'ceiling');

	const serverCeilingList = serviceList.filter((service) => service.field_name === 'ceiling_list');

	const serverFloor = serviceList.filter((service) => service.field_name === 'floor');

	const serverFloorList = serviceList.filter((service) => service.field_name === 'floor_list');

	const serverWindowList = serviceList.filter((service) => service.field_name === 'window_list');

	const serverElectrician = servicePersonList.filter((service) => service.field_name === 'electrician');

	const serverPlumber = servicePersonList.filter((service) => service.field_name === 'plumber');

	const serverConstructionManager = servicePersonList.filter(
		(service) => service.field_name === 'construction_manager'
	);

	let serverCeilingError = [];

	let serverCeilingListError = [];

	let serverFloorError = [];

	let serverFloorListError = [];

	let serverWindowListError = [];

	let serverElectricianError = [];

	let serverPlumberError = [];

	let serverConstructionManagerError = [];

	if (serverError && Array.isArray(serverError.body)) {
		serverCeilingError = serverError.body.filter((message) => Object.keys(message) == 'ceiling');
		serverCeilingListError = serverError.body.filter((message) => Object.keys(message) == 'ceiling_list');
		serverFloorError = serverError.body.filter((message) => Object.keys(message) == 'floor');
		serverFloorListError = serverError.body.filter((message) => Object.keys(message) == 'floor_list');
		serverWindowListError = serverError.body.filter((message) => Object.keys(message) == 'window_list');
		serverElectricianError = serverError.body.filter((message) => Object.keys(message) == 'electrician');
		serverPlumberError = serverError.body.filter((message) => Object.keys(message) == 'plumber');
		serverConstructionManagerError = serverError.body.filter(
			(message) => Object.keys(message) == 'construction_manager'
		);
	}
	return (
		<div
			className={`toggle-data-container full-width ${
				demolition_and_services ? null : 'toggle-data-container--hidden'
			}`}
		>
			<ProjectService
				activeFormData={serverCeiling[0]}
				name='ceiling[checkbox]'
				groupName='ceiling'
				label={DemolitionConstants['ceiling']}
				serverError={serverCeilingError}
				dynamicType={serviceTypeAndMaterial.ceiling_type}
				dynamicMaterial={serviceTypeAndMaterial.ceiling_material}
				handleShowComments={handleShowComments}
				group={group}
				comments={comments}
			/>
			<ProjectService
				activeFormData={serverCeilingList[0]}
				name='ceiling_list[checkbox]'
				groupName='ceiling_list'
				label={DemolitionConstants['ceiling_list']}
				serverError={serverCeilingListError}
				dynamicType={serviceTypeAndMaterial.ceiling_list_type}
				dynamicMaterial={serviceTypeAndMaterial.ceiling_list_material}
				handleShowComments={handleShowComments}
				group={group}
				comments={comments}
			/>
			<ProjectService
				activeFormData={serverFloor[0]}
				name='floor[checkbox]'
				groupName='floor'
				label={DemolitionConstants['floor']}
				serverError={serverFloorError}
				dynamicType={serviceTypeAndMaterial.floor_type}
				dynamicMaterial={serviceTypeAndMaterial.floor_material}
				handleShowComments={handleShowComments}
				group={group}
				comments={comments}
			/>
			<ProjectService
				activeFormData={serverFloorList[0]}
				name='floor_list[checkbox]'
				groupName='floor_list'
				label={DemolitionConstants['floor_list']}
				serverError={serverFloorListError}
				dynamicType={serviceTypeAndMaterial.floor_list_type}
				dynamicMaterial={serviceTypeAndMaterial.floor_list_material}
				handleShowComments={handleShowComments}
				group={group}
				comments={comments}
			/>
			<ProjectService
				activeFormData={serverWindowList[0]}
				name='window_list[checkbox]'
				groupName='window_list'
				label={DemolitionConstants['window_list']}
				serverError={serverWindowListError}
				dynamicType={serviceTypeAndMaterial.window_type}
				dynamicMaterial={serviceTypeAndMaterial.window_material}
				handleShowComments={handleShowComments}
				group={group}
				comments={comments}
			/>

			<ProjectServicePerson
				activeFormData={serverElectrician[0]}
				name='electrician[checkbox]'
				groupName='electrician'
				label={DemolitionConstants['electrician']}
				serverError={serverElectricianError}
				handleShowComments={handleShowComments}
				group={group}
				comments={comments}
			/>
			<ProjectServicePerson
				activeFormData={serverPlumber[0]}
				name='plumber[checkbox]'
				groupName='plumber'
				label={DemolitionConstants['plumber']}
				serverError={serverPlumberError}
				handleShowComments={handleShowComments}
				group={group}
				comments={comments}
			/>
			<ProjectServicePerson
				activeFormData={serverConstructionManager[0]}
				name='construction_manager[checkbox]'
				groupName='construction_manager'
				label={DemolitionConstants['construction_manager']}
				serverError={serverConstructionManagerError}
				handleShowComments={handleShowComments}
				group={group}
				comments={comments}
			/>
		</div>
	);
};

export default DemolitionAndServicesData;
