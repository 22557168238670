const StatusConstants = {
	draft: 'Utkast',
	order: 'Bestilling',
	confirmed: 'Bekreftet',
	ready_for_rest: 'Klar for rest',
	ready_for_assembly: 'Klart for montering',
	delivered: 'Levert',
	assembled: 'Klar til fakturering med rest',
	complaints: 'Oppsop',
	ready: 'Klart til levering',
	archived: 'Arkivert',
	invoiced: 'Fakturert',
	ready_to_invoice: 'Klar til fakturering'
};

export default StatusConstants;
