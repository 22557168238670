import * as React from 'react';

const SuccessMessage = ({ message }) => (
	<div className='success-container'>
		<div className='success-container__wrapper'>
			<div className='success-message-container'>{message}</div>
		</div>
	</div>
);

export default SuccessMessage;
