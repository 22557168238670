import { ArrayField, Option, RadioGroup, Select, Text } from 'informed';
import * as React from 'react';

import GeneralConstants from '../../../constants/general.constants';
import CommentFormDisplay from '../../comments/comment-form-display.component';
import ProjectRadioFreeContainer from '../project-radio-free-container/project-radio-free-container.component';

const api = window.api;

class ProjectMultiform extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			group: this.props.group,
			getInitialValues: this.props.getInitialValues,
			products: this.props.products,
			handleProductRemove: this.props.handleProductRemove,
			activeFormDataStatus: this.props.activeFormDataStatus,
			extraClass: true
		};
	}

	triggerComment = (path, group, iteration, label) => {
		if (
			this.props.products[`${group}`].list.items.value[`${iteration}`] &&
			this.props.products[`${group}`].list.items.value[`${iteration}`].id
		) {
			this.state.handleShowComments({
				targetId: path + this.props.products[`${group}`].list.items.value[`${iteration}`].id,
				group: 'products',
				label
			});
		} else {
		}
	};

	getId = (group, iteration) => {
		if (
			this.props.products[`${group}`].list.items.value[`${iteration}`] &&
			this.props.products[`${group}`].list.items.value[`${iteration}`].id
		) {
			return this.props.products[`${group}`].list.items.value[`${iteration}`].id;
		}
	};

	getCommentCount = (path, id) => {
		return this.props.comments.filter((comment) => comment.target_id == `${path}${id}`);
	};

	render() {
		const { group, getInitialValues, products, handleProductRemove, activeFormDataStatus } = this.state;

		return (
			<ArrayField field={`products[${group}][list][items][value]`} initialValue={getInitialValues(group)}>
				{({ add, fields }) => (
					<>
						{fields.map(({ field, key, remove, initialValue }, i) => (
							<div className='local-grid local-grid--col-2 separate-line' key={key}>
								<Text
									type='hidden'
									field={`${field}.id`}
									initialValue={
										initialValue && initialValue.id ? initialValue.id : api.forms.getUniqueID()
									}
								/>
								<label className='grid-item'>
									<span className='input-description'>
										{products[`${group}`].list.pattern.brand.label}
									</span>
									<Select
										field={`${field}.brand`}
										className='regular'
										allowEmptyString
										initialValue={
											initialValue && initialValue.brand ? initialValue.brand : '__empty__'
										}
									>
										{products[`${group}`].list.pattern.brand.dyn_data.map((brand) => (
											<Option value={brand.sys_name} key={brand.sys_name}>
												{brand.name}
											</Option>
										))}
									</Select>
								</label>
								<label className='grid-item'>
									<span className='input-description'>
										{products[`${group}`].list.pattern.model.label}
									</span>
									<Text
										type='text'
										field={`${field}.model`}
										initialValue={initialValue && initialValue.model ? initialValue.model : ''}
										allowEmptyString
									/>
								</label>
								{products[`${group}`].list.pattern.mode ? (
									<RadioGroup
										field={`${field}.mode`}
										initialValue={
											initialValue && initialValue.mode
												? initialValue.mode
												: products[`${group}`].list.pattern.mode.options[0].sys_name
										}
									>
										{products[`${group}`].list.pattern.mode.options.map((mode) => (
											<ProjectRadioFreeContainer
												value={mode.sys_name}
												key={mode.sys_name}
												label={mode.label}
												extraClass='small'
												removeLabelClass
											/>
										))}
									</RadioGroup>
								) : null}
								<label className='grid-item'>
									<span className='input-description'>
										{products[`${group}`].list.pattern.quantity.label}
									</span>
									<Text
										type='number'
										field={`${field}.quantity`}
										min='1'
										initialValue={initialValue && initialValue.quantity ? initialValue.quantity : 1}
										allowEmptyString
									/>
								</label>
								<label className='grid-item'>
									<span className='input-description'>
										{products[`${group}`].list.pattern.delivery_date.label}
									</span>
									<Text
										type='date'
										field={`${field}.delivery_date`}
										allowEmptyString
										initialValue={
											initialValue && initialValue.delivery_date ? initialValue.delivery_date : ''
										}
									/>
								</label>
								<div className='comment-wrapper-fit'>
									<CommentFormDisplay
										targetId={
											initialValue && initialValue.id
												? `products.${products[`${group}`].list.pattern.delivery_date.label}.${
														initialValue.id
												  }`
												: `products.${
														products[`${group}`].list.pattern.delivery_date.label
												  }.${this.getId(group, i)}`
										}
									/>
								</div>
								{i !== 0 && (
									<div className='btn-container btn-container--right'>
										{activeFormDataStatus === 'ready' ? (
											<button className='btn-delete' title={GeneralConstants['delete']} disabled>
												{GeneralConstants['delete']}
											</button>
										) : (
											<button
												className='btn-delete'
												type='button'
												title={GeneralConstants['delete']}
												onClick={(ev) =>
													handleProductRemove(remove, initialValue, [`${group}`])
												}
											>
												{GeneralConstants['delete']}
											</button>
										)}
									</div>
								)}
							</div>
						))}
						<div className='btn-container btn-container--right'>
							<button
								className='btn small light add'
								type='button'
								title={`${GeneralConstants['add']} ${products[`${group}`].label}`}
								onClick={add}
							>
								{`${GeneralConstants['add']} ${products[`${group}`].label}`}
							</button>
						</div>
					</>
				)}
			</ArrayField>
		);
	}
}

export default ProjectMultiform;
