import { PDFDownloadLink } from '@react-pdf/renderer';
import { Form, formState } from 'informed';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import ErrorMessage from '../error/error.component';
import PdfBathroom from '../pdf/pdf.bathroom';
import PdfGreenCard from '../pdf/pdf.greencard';
import PdfKitchen from '../pdf/pdf.kitchen';
import PdfKnapphusWardrobe from '../pdf/pdf.knapphus-wardrobe';
import PdfWardrobe from '../pdf/pdf.wardrobe';
import ProjectFooter from '../project-footer/project-footer.component';
import ProjectFormBathroom from '../project-form-type/project-form-bathroom/project-form-bathroom.component';
import ProjectFormKitchen from '../project-form-type/project-form-kitchen/project-form-kitchen.component';
import ProjectFormWardrobe from '../project-form-type/project-form-wardrobe/project-form-wardrobe.component';

const ProjectFormSecondPage = (props) => {
	const { category } = props.activeFormData;

	const { trackedTime } = props;

	return (
		<>
			<Form onSubmit={(values) => props.handleCategoryFormSubmit(values)} className='form-data-container'>
				{({ formState }) => (
					<>
						{category === 'kitchen' || category === 'laundry' ? (
							<ProjectFormKitchen
								formData={props.categoryFormData}
								handleShowComments={props.handleShowComments}
								comments={props.comments}
								activeFormDataStatus={props.activeFormData.status}
								activeFormData={props.activeFormData}
								formState={formState}
								handleProductRemove={props.handleProductRemove}
								handleOrderTypeRemove={props.handleOrderTypeRemove}
							/>
						) : null}
						{category === 'wardrobe' ? (
							<ProjectFormWardrobe
								formData={props.categoryFormData}
								handleShowComments={props.handleShowComments}
								comments={props.comments}
								activeFormData={props.activeFormData}
								formState={formState}
								handleProductRemove={props.handleProductRemove}
							/>
						) : null}
						{category === 'bathroom' ? (
							<ProjectFormBathroom
								formData={props.categoryFormData}
								handleShowComments={props.handleShowComments}
								comments={props.comments}
								activeFormData={props.activeFormData}
								formState={formState}
								handleProductRemove={props.handleProductRemove}
							/>
						) : null}
						<ProjectFooter
							page={props.page}
							projectID={props.activeFormData.id}
							handleProjectStatusChange={props.handleProjectStatusChange}
							projectStatus={props.projectStatus}
							projectName={props.activeFormData.title}
							handlePageSwitch={props.handlePageSwitch}
							fileCount={parseInt(props.dbImages.length) + parseInt(props.dbFiles.length)}
							firstStepCommentCount={props.handleStepCommentCount(1)}
							secondStepCommentCount={props.handleStepCommentCount(2)}
							activeFormDataStatus={props.activeFormData.status}
							handlePageOneData={props.handlePageOneData}
							handlePageTwoData={props.handlePageTwoData}
							canSetArchiveMode={props.canSetArchiveMode}
							trackedTime={props.trackedTime}
						/>
						{props.error && props.error.http_code == 409 && props.errorMessage && (
							<CSSTransition
								in
								appear
								timeout={{
									appear: 300000000,
									exit: 0
								}}
							>
								<ErrorMessage
									errorID={
										props.error && props.error.id
											? props.error.id
											: props.error && props.error.status
											? props.error.status
											: ''
									}
									errorMessage={props.error && props.error.body ? props.error.body : null}
									onSubmit
								/>
							</CSSTransition>
						)}
					</>
				)}
			</Form>
			{!props.isFetching ? (
				<div className='pdf-download-btn'>
					{['kitchen', 'laundry'].includes(category) &&
						['knapphus', 'tigne'].includes(props.categoryFormData.order_type.brands.value) && (
							<PDFDownloadLink
								document={
									<PdfKitchen
										orderType={props.categoryFormData.order_type}
										activeFormData={props.activeFormData}
									/>
								}
								fileName={`${props.activeFormData.client_id}_lakk.pdf`}
							>
								LAKK
							</PDFDownloadLink>
						)}

					{category === 'wardrobe' &&
						!['knapphus', 'tigne'].includes(props.categoryFormData.order_type.brands.value) && (
							<PDFDownloadLink
								document={
									<PdfWardrobe
										orderType={props.categoryFormData.order_type}
										activeFormData={props.activeFormData}
									/>
								}
								fileName={`${props.activeFormData.client_id}_lakk.pdf`}
							>
								LAKK
							</PDFDownloadLink>
						)}

					{category === 'wardrobe' &&
						['knapphus', 'tigne'].includes(props.categoryFormData.order_type.brands.value) && (
							<PDFDownloadLink
								document={
									<PdfKnapphusWardrobe
										orderType={props.categoryFormData.order_type}
										activeFormData={props.activeFormData}
									/>
								}
								fileName={`${props.activeFormData.client_id}_lakk.pdf`}
							>
								LAKK
							</PDFDownloadLink>
						)}

					{category === 'bathroom' &&
						['knapphus', 'tigne'].includes(props.categoryFormData.order_type.brands.value) && (
							<PDFDownloadLink
								document={
									<PdfBathroom
										orderType={props.categoryFormData.order_type}
										activeFormData={props.activeFormData}
										clientList={props.serverClientList}
									/>
								}
								fileName={`${props.activeFormData.client_id}_lakk.pdf`}
							>
								LAKK
							</PDFDownloadLink>
						)}

					{['kitchen', 'laundry'].includes(category) && (
						<PDFDownloadLink
							document={<PdfGreenCard products={props.categoryFormData.products} />}
							fileName={`${props.activeFormData.client_id}_green_card.pdf`}
						>
							GREEN CARD
						</PDFDownloadLink>
					)}
				</div>
			) : null}
		</>
	);
};

export default ProjectFormSecondPage;
