import nnLocale from '@fullcalendar/core/locales/nn';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import _ from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import ErrorMessage from '../../components/error/error.component';
import ProjectNavigation from '../../components/project-navigation/project-navigation.component';
import Spinner from '../../components/spinner/spinner.component';
import GeneralConstants from '../../constants/general.constants';

const api = window.api;

const setCalenderEvents = ({ project, roleDescription, clientList, startDate, endDate }) => {
	return {
		title: `${project.title} | ${project.id} ${
			clientList[project.client_id] ? clientList[project.client_id]['title'] : ''
		} | ${roleDescription}`,
		start: `${startDate}`,
		end: `${endDate}`,
		classNames: [
			`${project.status}`,
			`${project.category}`,
			`${project.is_knapphus_brand === 1 ? 'knapphus' : ''}`
		],
		url: `project-active/${project.id}`
	};
};

class MyDay extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			calendarProjectList: [],
			isFetching: true,
			error: null,
			errorMessage: false,
			handleEventPositioned: this.handleEventPositioned
		};
	}

	async componentDidMount() {
		try {
			const calendarProjectListPromise = await api.projects.get(
				`?datetime_range=6,6&fields=id,title,category,client_id,status,start_assembly_datetime,end_assembly_datetime,start_production_datetime,end_production_datetime,start_transport_datetime,end_transport_datetime&my_projects=true&show_knapphus_brand=1`
			);

			const clientsPromise = api.clients.get();

			const calendarProjectList = await calendarProjectListPromise;

			const serverClientList = await clientsPromise;
			await Promise.all([this.setCalendarState(calendarProjectList, serverClientList)]);
		} catch (error) {
			this.handleSetServerError(error);
		}
	}

	handleSetServerError = (error) => {
		this.setState((prevState) => ({
			...prevState,
			isFetching: false,
			error,
			errorMessage: true
		}));
		setTimeout(() => this.setState({ errorMessage: false }), 3000);
	};

	handleEventPositioned(info) {
		function hasSomeParentTheClass(element, classname) {
			if (element.className?.split(' ').indexOf(classname) >= 0) return true;
			return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
		}

		const hasParent = hasSomeParentTheClass(info.el, 'fc-daygrid-body');
		if (!hasParent) {
			return;
		}
		info.el.setAttribute('data-tip', info.el.innerText);
		ReactTooltip.rebuild();
	}

	setCalendarState = (calendarProjectList, serverClientList) => {
		this.setState((prevState) => ({
			...prevState,
			serverClientList,
			calendarProjectList,
			error: null,
			isFetching: false,
			errorMessage: false
		}));
	};

	render() {
		const { isFetching, errorMessage, calendarProjectList, serverClientList } = this.state;

		const clientList = _.mapKeys(serverClientList, function (value) {
			return value.id;
		});

		const calendarProjectAssemblyListData = calendarProjectList
			.filter((project) => project.start_assembly_datetime && project.end_assembly_datetime)
			.map((project) =>
				setCalenderEvents({
					project,
					roleDescription: GeneralConstants?.assembly,
					clientList,
					startDate: project.start_assembly_datetime,
					endDate: project.end_assembly_datetime
				})
			);

		const calendarProjectTransportListData = calendarProjectList
			.filter((project) => project.start_transport_datetime && project.end_transport_datetime)
			.map((project) =>
				setCalenderEvents({
					project,
					roleDescription: GeneralConstants?.transport,
					clientList,
					startDate: project.start_transport_datetime,
					endDate: project.end_transport_datetime
				})
			);

		const calendarProjectProductionListData = calendarProjectList
			.filter((project) => project.start_production_datetime && project.end_production_datetime)
			.map((project) =>
				setCalenderEvents({
					project,
					roleDescription: GeneralConstants?.production,
					clientList,
					startDate: project.start_production_datetime,
					endDate: project.end_production_datetime
				})
			);

		return (
			<>
				{isFetching && <Spinner />}
				{errorMessage && (
					<ErrorMessage
						errorID={
							this.state.error && this.state.error.id
								? this.state.error.id
								: this.state.error && this.state.error.status
								? this.state.error.status
								: ''
						}
					/>
				)}
				<ProjectNavigation />
				<div className='calendar-container calendar-container--full-width'>
					<div className='title-container'>
						<Link to='/my-day' className='active'>
							{GeneralConstants['my_day']}
						</Link>
						<Link to='/calendar'>{GeneralConstants['calendar']}</Link>
						<Link to='/map'>{GeneralConstants['map']}</Link>
					</div>
					{calendarProjectList && (
						<div className='calendar-container-data'>
							<ReactTooltip />
							<FullCalendar
								initialView='dayGridMonth'
								plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
								weekends={false}
								headerToolbar={{
									left: 'title',
									center: 'dayGridMonth,listWeek listDay',
									right: 'prev,next today'
								}}
								views={{
									listDay: { buttonText: `${GeneralConstants?.list_day}` },
									listWeek: { buttonText: `${GeneralConstants?.list_week}` }
								}}
								allDaySlot
								firstDay={1}
								nowIndicator
								slotLabelFormat={{
									hour: '2-digit',
									minute: '2-digit',
									meridiem: false,
									hour12: false
								}}
								eventTimeFormat={{
									hour: '2-digit',
									minute: '2-digit',
									meridiem: false,
									hour12: false
								}}
								selectable
								locale={nnLocale}
								weekNumbers
								weekText=''
								slotEventOverlap={false}
								events={[
									...calendarProjectAssemblyListData,
									...calendarProjectTransportListData,
									...calendarProjectProductionListData
								]}
								eventMouseEnter={this.handleEventPositioned}
							/>
						</div>
					)}
				</div>
			</>
		);
	}
}

export default MyDay;
