import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';
import {
	FormDataContainerWrapper,
	FormDataContent,
	FormDataContentContainer,
	FormDataTitle,
	FormDataTitleContainer,
	InputWrapper
} from './styles';

const PdfMultiForm = ({ group }) => {
	const formatDateToDayAndMonthAndYear = (date) => {
		const formatDate = date.split('T')[0];
		return formatDate
			? `${formatDate[8]}${formatDate[9]}.${formatDate[5]}${formatDate[6]}.${formatDate[0]}${formatDate[1]}${formatDate[2]}${formatDate[3]}`
			: '-';
	};

	return (
		<InputWrapper>
			{group?.list?.items?.value?.map((item) => (
				<React.Fragment key={item.id}>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{GeneralConstants.mark}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{item?.brand !== '__empty__' ? item?.brand : ''}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{GeneralConstants.model}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{item?.model}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{GeneralConstants.quantity}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>{item?.quantity}</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
					<FormDataContainerWrapper>
						<FormDataTitleContainer>
							<FormDataTitle>{GeneralConstants.delivery_date}</FormDataTitle>
						</FormDataTitleContainer>
						<FormDataContentContainer>
							<FormDataContent>
								{item?.delivery_date ? formatDateToDayAndMonthAndYear(item?.delivery_date) : null}
							</FormDataContent>
						</FormDataContentContainer>
					</FormDataContainerWrapper>
				</React.Fragment>
			))}
		</InputWrapper>
	);
};

export default PdfMultiForm;
