import styled from '@react-pdf/styled-components';

export const PageContainer = styled.Page`
	padding-bottom: 30px;
`;
export const ImgContainer = styled.View`
	width: 100%;
	height: 60px;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
	margin: 0 0 20px 0;
	background-color: #364c60;
`;
export const PromoImg = styled.Image`
	height: 100%;
	padding: 10px 0;
`;

export const FormDataContainer = styled.View`
	width: 100%;
	padding: 0 20px;
`;

export const FormDataContainerWrapper = styled.View`
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 10px;
	background-color: #fff;
	padding: 5px 0;
`;

export const SpecialFormDataContainerWrapper = styled.View`
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: space-between;
	background-color: #fff;
`;

export const FormDataTitleContainer = styled.View`
	padding-left: 5px;
	min-width: 100%;
	margin-bottom: 5px;
`;

export const FreeFormDataTitleContainer = styled.View`
	padding-left: 5px;
	margin-bottom: 5px;
`;
export const FormDataContentContainer = styled.View`
	min-width: 100%;
	border: 1px solid #e1e5e9;
	border-radius: 3px;
`;
export const FreeFormDataContentContainer = styled.View`
	border: 1px solid #e1e5e9;
	border-radius: 3px;
	min-width: 200px;
`;

export const FormDataTitle = styled.Text`
	font-weight: 700;
	font-size: 12px;
`;

export const FormDataContent = styled.Text`
	font-size: 12px;
	padding: 8px 10px;
	min-height: 28px;
`;

export const CheckBoxWrapper = styled.View`
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: flex-end;
	border: 1px solid #e1e5e9;
	border-radius: 3px;
	padding: 20px 0 0 9px;
	margin-bottom: 20px;
`;

export const InputWrapper = styled.View`
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: flex-end;
	border: 1px solid #e1e5e9;
	border-radius: 3px;
	padding: 20px 9px 0 9px;
	margin-bottom: 20px;
`;

export const RadioWrapper = styled.View`
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: flex-end;
	border: 1px solid #e1e5e9;
	border-radius: 3px;
	padding: 20px 9px 0 9px;
	margin-bottom: 20px;
`;

export const CheckBoxHeader = styled.View`
	width: 100%;
	padding-left: 5px;
	padding-bottom: 5px;
`;

export const InputHeader = styled.View`
	width: 100%;
	padding-left: 5px;
	padding-bottom: 5px;
`;

export const CheckBoxContainer = styled.View`
	padding: 2px 5px 2px 2px;
	background-color: #f0f3f5;
	border-radius: 3px;
	border: 1px solid #e1e5e9;
	margin-right: 9px;
	margin-bottom: 20px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
`;

export const RadioContainer = styled.View`
	padding: 2px 5px 2px 2px;
	background-color: #f0f3f5;
	border-radius: 3px;
	border: 1px solid #e1e5e9;
	margin-right: 9px;
	margin-bottom: 20px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
`;

export const CheckBoxNotChecked = styled.View`
	width: 24px;
	height: 24px;
	border-radius: 3px;
	border: 2px solid #e1e5e9;
	background-color: #fff;
	margin-right: 5px;
`;

export const RadioNotChecked = styled.View`
	width: 24px;
	height: 24px;
	border-radius: 25px;
	border: 2px solid #e1e5e9;
	background-color: #fff;
	margin-right: 5px;
`;

export const CheckBoxChecked = styled.View`
	width: 24px;
	height: 24px;
	border-radius: 3px;
	border: 2px solid #fff;
	background-color: #b5e400;
	margin-right: 5px;
`;
export const RadioChecked = styled.View`
	width: 24px;
	height: 24px;
	border-radius: 25px;
	border: 2px solid #fff;
	background-color: #b5e400;
	margin-right: 5px;
`;
export const CheckBoxHeaderText = styled.Text`
	font-size: 12px;
	color: #081f34;
`;

export const InputHeaderText = styled.Text`
	font-size: 12px;
	color: #081f34;
`;

export const CheckText = styled.Text`
	color: #081f34;
	font-size: 12px;
`;

export const FreeInputContainer = styled.View`
	padding-bottom: 19px;
`;

export const PageNumberContainer = styled.View`
	position: absolute;
	left: 0;
	bottom: 20px;
	width: 100%;
	text-align: center;
`;

export const PageNumber = styled.Text`
	font-size: 12px;
	color: #88929c;
`;
