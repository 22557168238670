import { Form, Radio, RadioGroup } from 'informed';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import GeneralConstants from '../../constants/general.constants';
import { fetchUserRoleStart } from '../../redux/user/user.actions';
import { selectCurrentUser, selectCurrentUserRoles } from '../../redux/user/user.selectors';

class UserRoles extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			radio_btn_value: this.props.currentUserActiveRole.label
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		const { currentUser } = this.props;
		this.props.fetchUserRoleStart(currentUser.id);
	}

	handleChange(ev) {
		return this.setState({ radio_btn_value: ev.target.value });
	}

	render() {
		const { currentUserRoles, currentUserActiveRole, onSubmit } = this.props;

		return (
			<Form className='user-role-list' onSubmit={onSubmit}>
				<RadioGroup field='role' initialValue={currentUserActiveRole.label}>
					{currentUserRoles.map((role) => {
						const className = `${
							this.state.radio_btn_value == role.label ? 'btn-switch btn-switch--active' : 'btn-switch'
						}`;
						return (
							<label className={className} key={role.id}>
								<span className='role-option-title'>{role.name}</span>
								<div className='btn-switch-checkbox'>
									<Radio value={role.label} onChange={this.handleChange} />
									<span data-on={GeneralConstants['on']} data-off={GeneralConstants['off']} />
								</div>
							</label>
						);
					})}
				</RadioGroup>
				<button className='btn large light-green right save--dark' type='submit'>
					{GeneralConstants['close_and_save']}
				</button>
			</Form>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	currentUserRoles: selectCurrentUserRoles
});

const mapDispatchToProps = (dispatch) => ({
	fetchUserRoleStart: (id) => dispatch(fetchUserRoleStart(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
