import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { history } from '../../helpers';
import Calendar from '../../pages/calendar/calendar.component';
import Dashboard from '../../pages/dashboard/dashboard.component';
import LogIn from '../../pages/login/login.component';
import Map from '../../pages/map/map.component';
import MyDay from '../../pages/my-day/my-day.component';
import NotFound from '../../pages/not-found/not-found.component';
import ProjectActive from '../../pages/project-active/project-active.component';
import ProjectAddOrEdit from '../../pages/project-add-or-edit/project-add-or-edit.component';
import ProjectArchive from '../../pages/project-archive/project-archive.component';
import ProjectLog from '../../pages/project-log/project-log.component';
import TestPdf from '../../pages/test/testpdf';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import Header from '../header/header.component';

class ProjectRoutes extends React.Component {
	render() {
		const { currentUser } = this.props;
		return (
			<div className='dashboard-container-wrapper'>
				<Router history={history}>
					<Header />
					<Switch>
						<Route path='/' exact component={Dashboard} />
						<Route path='/login' render={() => (currentUser ? <Redirect to='/' /> : <LogIn />)} />
						<Route path='/project-add' exact component={ProjectAddOrEdit} />
						<Route path='/project-active' exact component={ProjectActive} />
						<Route path='/project-active/:projectId' component={ProjectAddOrEdit} />
						<Route path='/project-archive' exact component={ProjectArchive} />
						<Route path='/project-archive/:projectId' component={ProjectAddOrEdit} />
						<Route path='/project-log' exact component={ProjectLog} />
						<Route path='/my-day' exact component={MyDay} />
						<Route path='/calendar' exact component={Calendar} />
						<Route path='/map' exact component={Map} />
						<Route path='/test' exact component={TestPdf} />
						<Route component={NotFound} />
					</Switch>
				</Router>
			</div>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser
});

export default connect(mapStateToProps)(ProjectRoutes);
