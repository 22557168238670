import { Document } from '@react-pdf/renderer';
import * as React from 'react';

import PdfExtraVarious from './PdfExtraVarious.component';
import PdfMultiForm from './PdfMultiform.component';
import {
	CheckText,
	FormDataContainer,
	FormDataContainerWrapper,
	FormDataContent,
	FormDataContentContainer,
	FormDataTitle,
	FormDataTitleContainer,
	ImgContainer,
	InputHeader,
	InputHeaderText,
	InputWrapper,
	PageContainer,
	PromoImg,
	RadioChecked,
	RadioContainer,
	RadioNotChecked,
	RadioWrapper
} from './styles';

// Checks brand and model
const hasGreenFieldProductValues = (productArray) => {
	const filterBrand = productArray.list.items.value?.filter((product) => product.brand !== '__empty__');

	const filterModel = productArray.list.items.value?.filter((product) => product.model !== '');
	return !!(filterBrand.length || filterModel.length);
};

const hasGreenFieldExtraVarious = (productArray) => {
	const filterAttr = productArray.list.items.value?.filter(
		(product) => product.diverse !== '' && product.EL_pillar !== ''
	);
	return !!filterAttr?.length;
};

const PdfGreenCard = ({ products }) => {
	const formatDateToDayAndMonthAndYear = (date) => {
		const formatDate = date.split('T')[0];
		return formatDate
			? `${formatDate[8]}${formatDate[9]}.${formatDate[5]}${formatDate[6]}.${formatDate[0]}${formatDate[1]}${formatDate[2]}${formatDate[3]}`
			: '-';
	};

	const [productCopy] = React.useState(products);

	return (
		<Document>
			<PageContainer size='A4'>
				<ImgContainer>
					<PromoImg source='../logo.png' />
				</ImgContainer>
				{hasGreenFieldProductValues(productCopy.oven) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.oven.label}</InputHeaderText>
						</InputHeader>
						<PdfMultiForm group={productCopy.oven} />
					</FormDataContainer>
				) : null}
				{hasGreenFieldProductValues(productCopy.micro) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.micro.label}</InputHeaderText>
						</InputHeader>
						<PdfMultiForm group={productCopy.micro} />
					</FormDataContainer>
				) : null}
				{hasGreenFieldProductValues(productCopy.coffee_machine) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.coffee_machine.label}</InputHeaderText>
						</InputHeader>
						<PdfMultiForm group={productCopy.coffee_machine} />
					</FormDataContainer>
				) : null}
				{hasGreenFieldProductValues(productCopy.top) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.top.label}</InputHeaderText>
						</InputHeader>
						<PdfMultiForm group={productCopy.top} />
					</FormDataContainer>
				) : null}
				{hasGreenFieldProductValues(productCopy.dishwasher) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.dishwasher.label}</InputHeaderText>
						</InputHeader>
						<PdfMultiForm group={productCopy.dishwasher} />
					</FormDataContainer>
				) : null}
				{hasGreenFieldProductValues(productCopy.fridge) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.fridge.label}</InputHeaderText>
						</InputHeader>
						<PdfMultiForm group={productCopy.fridge} />
					</FormDataContainer>
				) : null}
				{hasGreenFieldProductValues(productCopy.freezer) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.freezer.label}</InputHeaderText>
						</InputHeader>
						<PdfMultiForm group={productCopy.freezer} />
					</FormDataContainer>
				) : null}
				{hasGreenFieldProductValues(productCopy.ventilator) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.ventilator.label}</InputHeaderText>
						</InputHeader>
						<PdfMultiForm group={productCopy.ventilator} />
					</FormDataContainer>
				) : null}
				{hasGreenFieldExtraVarious(productCopy.various_extras_list) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.various_extras_list.label}</InputHeaderText>
						</InputHeader>
						<PdfExtraVarious group={productCopy.various_extras_list} />
					</FormDataContainer>
				) : null}
				{hasGreenFieldExtraVarious(productCopy.various_extras_list_2) ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.various_extras_list_2.label}</InputHeaderText>
						</InputHeader>
						<PdfExtraVarious group={productCopy.various_extras_list_2} />
					</FormDataContainer>
				) : null}
				<FormDataContainer>
					{products.various_extras_3.quooker.value ? (
						<>
							<InputHeader>
								<InputHeaderText>{products.various_extras_3.label}</InputHeaderText>
							</InputHeader>
							<InputWrapper>
								<FormDataContainerWrapper>
									<FormDataTitleContainer>
										<FormDataTitle>{products.various_extras_3.quooker.label}</FormDataTitle>
									</FormDataTitleContainer>
									<FormDataContentContainer>
										<FormDataContent>{products.various_extras_3.quooker.value}</FormDataContent>
									</FormDataContentContainer>
								</FormDataContainerWrapper>
								<FormDataContainerWrapper>
									<FormDataTitleContainer>
										<FormDataTitle>{products.various_extras_3.delivery_date.label}</FormDataTitle>
									</FormDataTitleContainer>
									<FormDataContentContainer>
										<FormDataContent>
											{products.various_extras_3.delivery_date.value
												? formatDateToDayAndMonthAndYear(
														products.various_extras_3.delivery_date.value
												  )
												: null}
										</FormDataContent>
									</FormDataContentContainer>
								</FormDataContainerWrapper>
								<FormDataContainerWrapper>
									<FormDataTitleContainer>
										<FormDataTitle>{products.various_extras_3.quantity.label}</FormDataTitle>
									</FormDataTitleContainer>
									<FormDataContentContainer>
										<FormDataContent>{products.various_extras_3.quantity.value}</FormDataContent>
									</FormDataContentContainer>
								</FormDataContainerWrapper>
							</InputWrapper>
						</>
					) : null}
					<InputHeader>
						<InputHeaderText>{products.recycling.label}</InputHeaderText>
					</InputHeader>
					<RadioWrapper>
						{products.recycling.recyclingmode.options.map((option) => {
							if (option.sys_name === products.recycling.recyclingmode.value) {
								return (
									<RadioContainer key={option.sys_name}>
										<RadioChecked />
										<CheckText>{option.label}</CheckText>
									</RadioContainer>
								);
							}
							return (
								<RadioContainer key={option.sys_name}>
									<RadioNotChecked />
									<CheckText>{option.label}</CheckText>
								</RadioContainer>
							);
						})}
					</RadioWrapper>
				</FormDataContainer>
				{products.wood_panels.standard_quantity.value ||
				products.wood_panels.quantity_70.value ||
				products.wood_panels.end_list_quantity.value ||
				products.wood_panels.corner_list_quantity.value ? (
					<FormDataContainer>
						<InputHeader>
							<InputHeaderText>{products.wood_panels.label}</InputHeaderText>
						</InputHeader>
						<InputWrapper>
							{products.wood_panels.standard_quantity.value ? (
								<FormDataContainerWrapper>
									<FormDataTitleContainer>
										<FormDataTitle>{products.wood_panels.standard_quantity.label}</FormDataTitle>
									</FormDataTitleContainer>
									<FormDataContentContainer>
										<FormDataContent>
											{products.wood_panels.standard_quantity.value}
										</FormDataContent>
									</FormDataContentContainer>
								</FormDataContainerWrapper>
							) : null}
							{products.wood_panels.quantity_70.value ? (
								<FormDataContainerWrapper>
									<FormDataTitleContainer>
										<FormDataTitle>{products.wood_panels.quantity_70.label}</FormDataTitle>
									</FormDataTitleContainer>
									<FormDataContentContainer>
										<FormDataContent>{products.wood_panels.quantity_70.value}</FormDataContent>
									</FormDataContentContainer>
								</FormDataContainerWrapper>
							) : null}
							{products.wood_panels.end_list_quantity.value ? (
								<FormDataContainerWrapper>
									<FormDataTitleContainer>
										<FormDataTitle>{products.wood_panels.end_list_quantity.label}</FormDataTitle>
									</FormDataTitleContainer>
									<FormDataContentContainer>
										<FormDataContent>
											{products.wood_panels.end_list_quantity.value}
										</FormDataContent>
									</FormDataContentContainer>
								</FormDataContainerWrapper>
							) : null}
							{products.wood_panels.corner_list_quantity.value ? (
								<FormDataContainerWrapper>
									<FormDataTitleContainer>
										<FormDataTitle>{products.wood_panels.corner_list_quantity.label}</FormDataTitle>
									</FormDataTitleContainer>
									<FormDataContentContainer>
										<FormDataContent>
											{products.wood_panels.corner_list_quantity.value}
										</FormDataContent>
									</FormDataContentContainer>
								</FormDataContainerWrapper>
							) : null}
						</InputWrapper>
					</FormDataContainer>
				) : null}
			</PageContainer>
		</Document>
	);
};

export default PdfGreenCard;
