import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import ErrorMessage from '../../components/error/error.component';
import LoadMore from '../../components/load-more/load-more.component';
import ProjectNavigation from '../../components/project-navigation/project-navigation.component';
import Spinner from '../../components/spinner/spinner.component';
import GeneralConstants from '../../constants/general.constants';
import NotFound from '../not-found/not-found.component';

const api = window.api;

class ProjectLog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			limit: 20,
			offset: 0,
			sort: '-',
			sortBy: 'done_date',
			logs: [],
			error: null,
			errorMessage: '',
			logCount: 0,
			isFetching: true
		};
	}

	async componentDidMount() {
		const { limit, offset, sort, sortBy } = this.state;
		try {
			const logPromise = api.timetracks.get(
				`projectlog?&limit=${limit}&offset=${offset}&sort=${sort}${sortBy},${sort}${sortBy}`
			);

			const logs = await logPromise;

			await Promise.all([this.setInitialState(logs)]);
		} catch (error) {
			this.setServerError(error);
		}
	}

	dateFormat = (time) => {
		const d = new Date(time);
		return d
			.toLocaleDateString('en-GB')
			.replace(`, `, '-')
			.replace(`/`, '.')
			.replace(`/`, '.')
			.replace(/:[^:]*$/, '');
	};

	timeToFormat = (time) => {
		const convertToStringTime = time.toString();

		const timeObj = convertToStringTime.split(':');

		const hours = timeObj[0] < 10 ? `0${timeObj[0]}` : `${timeObj[0]}`;

		const mins = timeObj[1] < 10 ? `0${timeObj[1]}` : `${timeObj[1]}`;
		return `${hours}:${mins}`;
	};

	setInitialState = (logs) => {
		this.setState((prevState) => ({
			...prevState,
			logs: logs.rows,
			logCount: logs.total_count,
			offset: prevState.offset + 20,
			isFetching: false,
			error: false
		}));
	};

	fetchLogs = () => {
		const { limit, offset, sort, sortBy } = this.state;

		api.timetracks
			.get(`projectlog?sort=${sort}${sortBy},${sort}done_date&limit=${limit}&offset=${offset}`)
			.then((res) => this.setLogs(res.rows, sort, sortBy, offset))
			.catch((err) => this.setServerError(err));
	};

	sortLogs = (sortByTh) => {
		const { limit, sort, sortBy, logCount } = this.state;

		const offset = 20;

		let sortDirection = sort;

		if (sortByTh === sortBy) {
			if (sort === '-') {
				sortDirection = '';
			} else {
				sortDirection = '-';
			}
		} else {
			sortDirection = '-';
		}

		api.timetracks
			.get(`projectlog?&limit=${limit}&offset=0&sort=${sortDirection}${sortByTh},${sortDirection}done_date`)
			.then((res) => this.setSearchedLogs(res.rows, logCount, sortDirection, sortByTh, offset))
			.catch((err) => this.setServerError(err));
	};

	setSearchedLogs = (logs, logCount, sort, sortBy, setOffset) => {
		this.setState((prevState) => ({
			...prevState,
			logs,
			logCount,
			sort,
			sortBy,
			error: null,
			offset: setOffset,
			isFetching: false
		}));
	};

	setLogs = (logs, sort, sortBy, offset) => {
		this.setState((prevState) => ({
			...prevState,
			logs: [...prevState.logs, ...logs],
			sort,
			sortBy,
			offset: offset + 20,
			error: null,
			isFetching: false
		}));
	};

	setServerError = (error) => {
		this.setState((prevState) => ({
			...prevState,
			isFetching: false,
			error,
			errorMessage: true
		}));
		setTimeout(() => this.setState({ errorMessage: false }), 2000);
	};

	render() {
		const { isFetching, errorMessage, logs, logCount, offset, sort, sortBy } = this.state;
		return (
			<>
				<ProjectNavigation />
				<div className='project-data-container'>
					<CSSTransition
						in={isFetching}
						appear
						timeout={{
							appear: 300,
							exit: 150
						}}
					>
						<Spinner />
					</CSSTransition>
					{this.state.error && this.state.error.id === 0 && this.state.error.http_code === 404 ? (
						<NotFound />
					) : (
						errorMessage && (
							<CSSTransition
								in={errorMessage}
								appear
								timeout={{
									appear: 3000,
									exit: 0
								}}
							>
								<ErrorMessage
									errorID={
										this.state.error && this.state.error.id
											? this.state.error.id
											: this.state.error && this.state.error.status
											? this.state.error.status
											: ''
									}
								/>
							</CSSTransition>
						)
					)}
					<div className='title-container'>
						<h1>{GeneralConstants['project_log']}</h1>
					</div>
					<div className='project-log-list'>
						<InfiniteScroll
							dataLength={logs.length}
							next={this.fetchLogs}
							hasMore={offset < logCount}
							loader={<LoadMore title={GeneralConstants['load_more_logs']} />}
						>
							<table className='project-log'>
								<thead>
									<tr>
										<th
											onClick={() => this.sortLogs('title')}
											className={
												sort === '' && sortBy === 'title'
													? 'desc'
													: sort === '-' && sortBy === 'title'
													? 'asc'
													: 'no-order'
											}
										>
											{GeneralConstants['project_title']}
										</th>
										<th className='no-sorting'>{GeneralConstants['project_nr']}</th>
										<th
											onClick={() => this.sortLogs('name')}
											className={
												sort === '' && sortBy === 'name'
													? 'desc'
													: sort === '-' && sortBy === 'name'
													? 'asc'
													: 'no-order'
											}
										>
											{GeneralConstants['user']}
										</th>
										<th
											onClick={() => this.sortLogs('mins_total')}
											className={
												sort === '' && sortBy === 'mins_total'
													? 'desc'
													: sort === '-' && sortBy === 'mins_total'
													? 'asc'
													: 'no-order'
											}
										>
											{GeneralConstants['hours_tracked']}
										</th>
										<th
											onClick={() => this.sortLogs('done_date')}
											className={
												sort === '' && sortBy === 'done_date'
													? 'desc'
													: sort === '-' && sortBy === 'done_date'
													? 'asc'
													: 'no-order'
											}
										>
											{GeneralConstants['edit_date']}
										</th>
									</tr>
								</thead>
								<tbody>
									{logs.map((project, i) => (
										<tr key={i}>
											<td>
												<Link to={`project-active/${project.projectID}`}>{project.title}</Link>
											</td>
											<td>{project.projectID}</td>
											<td>{project.name}</td>
											<td>{this.timeToFormat(project.time_total)}</td>
											<td>{this.dateFormat(project.done_date)}</td>
										</tr>
									))}
								</tbody>
							</table>
						</InfiniteScroll>
					</div>
				</div>
			</>
		);
	}
}

export default ProjectLog;
