import * as React from 'react';
import { Link } from 'react-router-dom';

import GeneralConstants from '../../constants/general.constants';

const Dashboard = () => {
	return (
		<div className='dashboard-container'>
			<Link to='/project-add' className='dashboard-item dashboard-item--add-project'>
				<span>{GeneralConstants['add_project']}</span>
			</Link>
			<Link to='/project-active' className='dashboard-item dashboard-item--active-projects'>
				<span>{GeneralConstants['active_projects']}</span>
			</Link>
			<Link to='/project-archive' className='dashboard-item dashboard-item--project-archive'>
				<span>{GeneralConstants['archive_projects']}</span>
			</Link>
			<Link to='/my-day' className='dashboard-item dashboard-item--my-day'>
				<span>{GeneralConstants['my_day']}</span>
			</Link>
			<Link to='/calendar' className='dashboard-item dashboard-item--calendar'>
				<span>{GeneralConstants['calendar']}</span>
			</Link>
			<Link to='/map' className='dashboard-item dashboard-item--map'>
				<span>{GeneralConstants['map']}</span>
			</Link>
			<Link to='/project-log' className='dashboard-item dashboard-item--project-log'>
				<span>{GeneralConstants['project_log']}</span>
			</Link>
		</div>
	);
};

export default Dashboard;
