import { Option, Select } from 'informed';
import * as React from 'react';

import ErrorConstants from '../../../constants/error.constants';

const ProjectFormSelectInput = ({
	optionValues,
	groupOptionValues,
	activeFormData,
	label,
	name,
	defaultOption,
	serverError,
	serviceOptionValues
}) => {
	const groupOptionValueArray =
		groupOptionValues && Object.keys(groupOptionValues) ? Object.keys(groupOptionValues) : [];
	return (
		<label className='grid-item'>
			<span className='input-description'>{label}</span>
			<Select field={name} initialValue={activeFormData} className={`${serverError ? 'error-field' : ''}`}>
				<Option value='' disabled>
					{defaultOption}
				</Option>
				{optionValues &&
					optionValues.map((option) => (
						<Option value={option.id} key={option.id}>
							{option.title}
						</Option>
					))}

				{groupOptionValueArray &&
					groupOptionValueArray.map((groupOptionValue) => (
						<Option value={groupOptionValue} key={groupOptionValue}>
							{groupOptionValues[groupOptionValue]}
						</Option>
					))}
				{serviceOptionValues &&
					serviceOptionValues.map((serviceOption) => (
						<Option value={serviceOption.sys_name} key={serviceOption.sys_name}>
							{serviceOption.name}
						</Option>
					))}
			</Select>
			{serverError && <span className='error-inline'>{ErrorConstants[serverError]}</span>}
		</label>
	);
};

export default ProjectFormSelectInput;
