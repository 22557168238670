import * as React from 'react';
import { NavLink } from 'react-router-dom';

import GeneralConstants from '../../constants/general.constants';

const ProjectNavigation = () => (
	<nav className='project-nav'>
		<NavLink to='/' className='project-nav--home' />
		<NavLink to='/project-add' className='project-nav--add-project' activeClassName='project-nav--active'>
			{GeneralConstants['add_project']}
		</NavLink>
		<NavLink to='/project-active' className='project-nav--active-project' activeClassName='project-nav--active'>
			{GeneralConstants['active_projects']}
		</NavLink>
		<NavLink to='/project-archive' className='project-nav--archive-project' activeClassName='project-nav--active'>
			{GeneralConstants['archive_projects']}
		</NavLink>
		<NavLink to='/calendar' className='project-nav--calender' activeClassName='project-nav--active'>
			{GeneralConstants['calendar']}
		</NavLink>
	</nav>
);

export default ProjectNavigation;
