import { Text } from 'informed';
import * as React from 'react';

import GeneralConstants from '../../../constants/general.constants';
import ProjectFormTextInput from '../project-form-text-input/project-form-text-input.component';
import ProjectMultiformDates from '../project-multiform/project-multiform-dates.component';

const ProjectGreenCardExtras = (props) => {
	const [toggleList, setToggleList] = React.useState(!!props.activeFormData);

	return (
		<fieldset className='options-container'>
			<span className='field-description'>{props.extraLabel}</span>
			{!toggleList ? (
				<div className='btn-container btn-container--right btn-container--border'>
					<button type='button' onClick={() => setToggleList(true)} className='btn small light add'>
						{`${GeneralConstants?.add} ${props.extraLabel}`}
					</button>
				</div>
			) : null}
			<fieldset className={`local-grid local-grid--col-2 ${toggleList ? 'option-list' : 'option-list--hidden'}`}>
				<ProjectMultiformDates
					dates={props.dates}
					checkBoxPath={props.checkBoxPath}
					dateName={props.dateName}
					activeFormDateData={props.activeFormDateData}
					todayDate={props.todayDate}
				/>
				<ProjectFormTextInput
					name={props.name}
					type='text'
					label={props.label}
					activeFormData={props.activeFormData}
				/>
				<label className='grid-item'>
					<span className='input-description'>{props.various.quantity.label}</span>
					<Text
						type='number'
						field={props.variousFieldQuantityName}
						min='1'
						initialValue={props.various && props.various.quantity.value ? props.various.quantity.value : 1}
						allowEmptyString
					/>
				</label>
				<label className='grid-item'>
					<span className='input-description'>{props.various.delivery_date.label}</span>
					<Text
						type='date'
						field={props.variousFieldDateName}
						allowEmptyString
						initialValue={
							props.various && props.various.delivery_date.value ? props.various.delivery_date.value : ''
						}
					/>
				</label>
			</fieldset>
		</fieldset>
	);
};

export default ProjectGreenCardExtras;
