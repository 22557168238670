import * as React from 'react';
import { Link } from 'react-router-dom';

import ProjectNavigation from '../../components/project-navigation/project-navigation.component';
import GeneralConstants from '../../constants/general.constants';

const NotFound = () => (
	<>
		<ProjectNavigation />
		<div className='not-found-container'>
			<div className='not-found__image' />
			<div className='not-found__data'>
				<h1 className='not-found__data-header'>{GeneralConstants['not_found_header']}</h1>
				<p className='not-found__data-info'>{GeneralConstants['not_found_info']}</p>
				<Link to='/' className='btn home'>
					{GeneralConstants['go_home']}
				</Link>
			</div>
		</div>
	</>
);

export default NotFound;
