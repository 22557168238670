import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';
import {
	FormDataContainerWrapper,
	FormDataContent,
	FormDataContentContainer,
	FormDataTitle,
	FormDataTitleContainer,
	InputWrapper
} from './styles';

const PdfOrderType = ({ group }) => {
	const formatDateToDayAndMonthAndYear = (date) => {
		const formatDate = date.split('T')[0];
		return formatDate
			? `${formatDate[8]}${formatDate[9]}.${formatDate[5]}${formatDate[6]}.${formatDate[0]}${formatDate[1]}${formatDate[2]}${formatDate[3]}`
			: '-';
	};

	return (
		<InputWrapper>
			{group?.list?.items?.value?.map((item) => (
				<React.Fragment key={item.id}>
					{item?.front ? (
						<FormDataContainerWrapper>
							<FormDataTitleContainer>
								<FormDataTitle>{GeneralConstants.front}</FormDataTitle>
							</FormDataTitleContainer>
							<FormDataContentContainer>
								<FormDataContent>{item?.front}</FormDataContent>
							</FormDataContentContainer>
						</FormDataContainerWrapper>
					) : null}
					{item?.dekks ? (
						<FormDataContainerWrapper>
							<FormDataTitleContainer>
								<FormDataTitle>{GeneralConstants.dekks}</FormDataTitle>
							</FormDataTitleContainer>
							<FormDataContentContainer>
								<FormDataContent>{item?.dekks}</FormDataContent>
							</FormDataContentContainer>
						</FormDataContainerWrapper>
					) : null}
					{item?.color_code ? (
						<FormDataContainerWrapper>
							<FormDataTitleContainer>
								<FormDataTitle>{GeneralConstants.color_code}</FormDataTitle>
							</FormDataTitleContainer>
							<FormDataContentContainer>
								<FormDataContent>{item?.color_code}</FormDataContent>
							</FormDataContentContainer>
						</FormDataContainerWrapper>
					) : null}
					{item?.moldings_lists ? (
						<FormDataContainerWrapper>
							<FormDataTitleContainer>
								<FormDataTitle>{GeneralConstants.moldings_lists}</FormDataTitle>
							</FormDataTitleContainer>
							<FormDataContentContainer>
								<FormDataContent>{item?.moldings_lists}</FormDataContent>
							</FormDataContentContainer>
						</FormDataContainerWrapper>
					) : null}
					{item.front.length || item.dekks.length || item.color_code.length || item.moldings_lists.length ? (
						<FormDataContainerWrapper>
							<FormDataTitleContainer>
								<FormDataTitle>{GeneralConstants.planned_week}</FormDataTitle>
							</FormDataTitleContainer>
							<FormDataContentContainer>
								<FormDataContent>
									{item?.planned_week ? formatDateToDayAndMonthAndYear(item?.planned_week) : null}
								</FormDataContent>
							</FormDataContentContainer>
						</FormDataContainerWrapper>
					) : null}
				</React.Fragment>
			))}
		</InputWrapper>
	);
};

export default PdfOrderType;
