import * as React from 'react';
import { useGeolocated } from 'react-geolocated';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import ProjectNavigation from '../../components/project-navigation/project-navigation.component';
import Spinner from '../../components/spinner/spinner.component';
import GeneralConstants from '../../constants/general.constants';
import MyMapComponent from './react.map.component';

function addDays(date, days) {
	const copy = new Date(Number(date));
	copy.setDate(date.getDate() + days);
	return copy;
}

const Map = () => {
	const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
		positionOptions: {
			enableHighAccuracy: true,
			maximumAge: 0,
			timeout: Infinity
		},
		watchPosition: true,
		userDecisionTimeout: 5000
	});

	const [pageView, setPageView] = React.useState(0);

	const pageDaysFromNow = 7 * pageView;

	const curr = new Date(); // get current date

	const pageDate = addDays(curr, pageDaysFromNow);

	const sunday = pageDate.getDate() - pageDate.getDay(); // get Last Sunday

	const monday = new Date(pageDate.setDate(sunday + 1)); // get current week first day

	const lastSunday = new Date(pageDate.setDate(sunday + 7)); // get current week last day

	const visualStartDate = `${monday
		.getDate()
		.toString()
		.padStart(2, 0)}.${(monday.getMonth() + 1).toString().padStart(2, 0)}.${monday.getFullYear().toString()}`;

	const visualEndDate = `${lastSunday
		.getDate()
		.toString()
		.padStart(2, 0)}.${(lastSunday.getMonth() + 1)
		.toString()
		.padStart(2, 0)}.${lastSunday.getFullYear().toString()}`;

	const visualWeek = `${visualStartDate} - ${visualEndDate}`;

	return (
		<>
			<ProjectNavigation />
			<div className='project-data-container'>
				<div className='title-container'>
					<Link to='/my-day'>{GeneralConstants['my_day']}</Link>
					<Link to='/calendar'>{GeneralConstants['calendar']}</Link>
					<Link to='/map' className='active'>
						{GeneralConstants['map']}
					</Link>
				</div>
				<div className='toggle-map-btn-container'>
					<button
						type='button'
						className={`btn main-green main-green--light left ${pageView === 0 ? 'disabled' : ''}`}
						onClick={() => setPageView(pageView > 0 ? pageView - 1 : 0)}
					>
						{GeneralConstants['prev']}
					</button>
					<h2>{visualWeek}</h2>
					<button
						type='button'
						className={`btn main-green main-green--light right ${pageView === 3 ? 'disabled' : ''}`}
						onClick={() => setPageView(pageView < 3 ? pageView + 1 : 3)}
					>
						{GeneralConstants['next']}
					</button>
				</div>
				{coords ? (
					<div className='project-map-list'>
						<div className='map-container'>
							<CSSTransition
								in={pageView === 0}
								appear
								timeout={{
									appear: 3000,
									exit: 1000000000
								}}
							>
								<div className='map-and-list-container'>
									<MyMapComponent
										isMarkerShown
										lat={coords.latitude}
										lng={coords.longitude}
										page={0}
									/>
								</div>
							</CSSTransition>
							<CSSTransition
								in={pageView === 1}
								appear
								timeout={{
									appear: 3000,
									exit: 1000000000
								}}
							>
								<div className='map-and-list-container'>
									<MyMapComponent
										isMarkerShown
										lat={coords.latitude}
										lng={coords.longitude}
										page={1}
									/>
								</div>
							</CSSTransition>
							<CSSTransition
								in={pageView === 2}
								appear
								timeout={{
									appear: 3000,
									exit: 1000000000
								}}
							>
								<div className='map-and-list-container'>
									<MyMapComponent
										isMarkerShown
										lat={coords.latitude}
										lng={coords.longitude}
										page={2}
									/>
								</div>
							</CSSTransition>
							<CSSTransition
								in={pageView === 3}
								appear
								timeout={{
									appear: 3000,
									exit: 1000000000
								}}
							>
								<div className='map-and-list-container'>
									<MyMapComponent
										isMarkerShown
										lat={coords.latitude}
										lng={coords.longitude}
										page={3}
									/>
								</div>
							</CSSTransition>
						</div>
					</div>
				) : (
					<Spinner />
				)}
			</div>
		</>
	);
};

export default Map;
