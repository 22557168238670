import * as React from 'react';
import { useForm } from 'react-hook-form';

import GeneralConstants from '../../constants/general.constants';
import {
	getMyLocalStorageProjects,
	getMyLocalStorageProjectsComplaints,
	getMyLocalStorageProjectsKw
} from '../../utils/localStorageHelper';

const ProjectSearch = ({
	onSubmit,
	defaultValues = {
		search_project: getMyLocalStorageProjectsKw(),
		show_my_projects: getMyLocalStorageProjects(),
		show_projects_complaints: getMyLocalStorageProjectsComplaints()
	}
}) => {
	const { register, handleSubmit } = useForm({
		defaultValues
	});

	return (
		<form className='project-search-container' onSubmit={handleSubmit(onSubmit)}>
			<label className='width-100'>
				<input
					type='search'
					placeholder={GeneralConstants['search']}
					className='search'
					{...register('search_project')}
				/>
			</label>
			<label>
				<input type='checkbox' {...register('show_my_projects')} />
				<span>{GeneralConstants['show_my_projects']}</span>
			</label>
			<label style={{ marginLeft: '20px' }}>
				<input type='checkbox' {...register('show_projects_complaints')} />
				<span>{GeneralConstants['show_projects_complaints']}</span>
			</label>
			<div className='btn-container'>
				<button type='submit' className='btn medium green-search'>
					{GeneralConstants['search']}
				</button>
			</div>
		</form>
	);
};

export default ProjectSearch;
