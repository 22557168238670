// eslint-disable-next-line
import 'react-datepicker/dist/react-datepicker.css';
// eslint-disable-next-line
import FullCalendar from '@fullcalendar/react';
// eslint-disable-next-line
import nnLocale from '@fullcalendar/core/locales/nn';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import _ from 'lodash';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';

// eslint-disable-next-line sort-imports
import ErrorConstants from '../../constants/error.constants';
import GeneralConstants from '../../constants/general.constants';

const setCalenderEvents = ({
	project,
	roleDescription,
	clientList,
	startDate,
	endDate,
	serverUsers = [],
	projectCategory = []
}) => {
	const filterRelatedUsers = (category) =>
		serverUsers
			.filter((user) => category.includes(user.id))
			.map((detailInfo) => {
				return ` ${detailInfo.firstname} ${detailInfo.lastname}`;
			});

	const mapUsersByName = filterRelatedUsers(projectCategory);

	return {
		title: `${project.title} | ${project.id} ${
			clientList[project.client_id] ? clientList[project.client_id]['title'] : ''
		} | ${roleDescription} ${mapUsersByName}`,
		start: `${startDate}`,
		end: `${endDate}`,
		id: `${project.id}`,
		classNames: [
			`${project.status}`,
			`${project.category}`,
			`${project.is_knapphus_brand === 1 ? 'knapphus' : ''}`
		],
		url: `${project.id}`
	};
};

const ProjectCalendar = ({
	handleCalendarToggle,
	activeFormData,
	handleCalendarSubmit,
	calendarDates,
	calendarProjectList,
	serverClientList,
	serverUserRoles,
	error
}) => {
	const clientList = _.mapKeys(serverClientList, function (value) {
		return value.id;
	});

	const { title, id, client_id, is_knapphus_brand } = activeFormData;

	const {
		end_assembly_datetime,
		start_assembly_datetime,
		end_production_datetime,
		start_production_datetime,
		end_transport_datetime,
		start_transport_datetime,
		status,
		category
	} = calendarDates;

	const {
		end_assembly_datetime: project_end_assembly_datetime,
		start_assembly_datetime: project_start_assembly_datetime,
		end_production_datetime: project_end_production_datetime,
		start_production_datetime: project_start_production_datetime,
		end_transport_datetime: project_end_transport_datetime,
		start_transport_datetime: project_start_transport_datetime
	} = activeFormData;

	const handleEventPositioned = (info) => {
		function hasSomeParentTheClass(element, classname) {
			if (element.className?.split(' ').indexOf(classname) >= 0) return true;
			return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
		}
		const hasParent = hasSomeParentTheClass(info.el, 'fc-daygrid-body');
		if (!hasParent) {
			return;
		}

		info.el.setAttribute('data-tip', info.el.innerText);
		ReactTooltip.rebuild();
	};

	const calendarProjectAssemblyListData = calendarProjectList
		.filter((project) => project.start_assembly_datetime && project.end_assembly_datetime && project.id !== id)
		.map((project) =>
			setCalenderEvents({
				project,
				roleDescription: GeneralConstants?.assembly,
				clientList,
				startDate: project.start_assembly_datetime,
				endDate: project.end_assembly_datetime,
				serverUsers: serverUserRoles,
				projectCategory: project.user_ids_assembly
			})
		);

	const calendarProjectTransportListData = calendarProjectList
		.filter((project) => project.start_transport_datetime && project.end_transport_datetime && project.id !== id)
		.map((project) =>
			setCalenderEvents({
				project,
				roleDescription: GeneralConstants?.transport,
				clientList,
				startDate: project.start_transport_datetime,
				endDate: project.end_transport_datetime,
				serverUsers: serverUserRoles,
				projectCategory: project.user_ids_transport
			})
		);

	const calendarProjectProductionListData = calendarProjectList
		.filter((project) => project.start_production_datetime && project.end_production_datetime && project.id !== id)
		.map((project) =>
			setCalenderEvents({
				project,
				roleDescription: GeneralConstants?.production,
				clientList,
				startDate: project.start_production_datetime,
				endDate: project.end_production_datetime,
				serverUsers: serverUserRoles,
				projectCategory: project.user_ids_production
			})
		);

	const [startTransportDate, setStartTransportDate] = React.useState(
		start_transport_datetime ? new Date(start_transport_datetime) : null
	);

	const [endTransportDate, setEndTransportDate] = React.useState(
		end_transport_datetime ? new Date(end_transport_datetime) : null
	);

	const [startProductionDate, setStartProductionDate] = React.useState(
		start_production_datetime ? new Date(start_production_datetime) : null
	);

	const [endProductionDate, setEndProductionDate] = React.useState(
		end_production_datetime ? new Date(end_production_datetime) : null
	);

	const [startAssemblyDate, setStartAssemblyDate] = React.useState(
		start_assembly_datetime ? new Date(start_assembly_datetime) : null
	);

	const [endAssemblyDate, setEndAssemblyDate] = React.useState(
		end_assembly_datetime ? new Date(end_assembly_datetime) : null
	);

	const formData = {
		start_transport_datetime: startTransportDate,
		end_transport_datetime: endTransportDate,
		start_production_datetime: startProductionDate,
		end_production_datetime: endProductionDate,
		start_assembly_datetime: startAssemblyDate,
		end_assembly_datetime: endAssemblyDate
	};

	return (
		<div className='calendar-container-overlay'>
			<div className='calendar-container' id='external-events'>
				<div className='calendar-container-data'>
					<div className='section-container'>
						<form
							onSubmit={(values) => {
								values.preventDefault();
								handleCalendarSubmit(formData);
							}}
						>
							<div className='local-grid local-grid--col-2'>
								{start_transport_datetime !== undefined ? (
									<>
										<label className='grid-item'>
											<span className='input-description'>
												{GeneralConstants['select_transport_start_time']}
											</span>
											<DatePicker
												selected={startTransportDate}
												onChange={(date) => setStartTransportDate(date)}
												name='start_transport_datetime'
												withPortal
												showTimeSelect
												timeFormat='HH:mm'
												timeIntervals={15}
												timeCaption='time'
												dateFormat='MMMM d, yyyy HH:mm'
											/>
											{error && error.body && error.body.start_transport_datetime ? (
												<span className='error-inline'>
													{ErrorConstants[error.body.start_transport_datetime]}
												</span>
											) : null}
										</label>
										<label className='grid-item'>
											<span className='input-description'>
												{GeneralConstants['select_transport_end_time']}
											</span>
											<DatePicker
												selected={endTransportDate}
												onChange={(date) => setEndTransportDate(date)}
												name='end_transport_datetime'
												withPortal
												showTimeSelect
												timeFormat='HH:mm'
												timeIntervals={15}
												timeCaption='time'
												dateFormat='MMMM d, yyyy HH:mm'
											/>

											{error && error.body && error.body.end_transport_datetime ? (
												<span className='error-inline'>
													{ErrorConstants[error.body.end_transport_datetime]}
												</span>
											) : null}
										</label>
									</>
								) : null}
								{start_production_datetime !== undefined ? (
									<>
										<label className='grid-item'>
											<span className='input-description'>
												{GeneralConstants['select_production_start_time']}
											</span>
											<DatePicker
												selected={startProductionDate}
												onChange={(date) => setStartProductionDate(date)}
												name='start_production_datetime'
												withPortal
												showTimeSelect
												timeFormat='HH:mm'
												timeIntervals={15}
												timeCaption='time'
												dateFormat='MMMM d, yyyy HH:mm'
											/>

											{error && error.body && error.body.start_production_datetime ? (
												<span className='error-inline'>
													{ErrorConstants[error.body.start_production_datetime]}
												</span>
											) : null}
										</label>
										<label className='grid-item'>
											<span className='input-description'>
												{GeneralConstants['select_production_end_time']}
											</span>
											<DatePicker
												selected={endProductionDate}
												onChange={(date) => setEndProductionDate(date)}
												name='end_production_datetime'
												withPortal
												showTimeSelect
												timeFormat='HH:mm'
												timeIntervals={15}
												timeCaption='time'
												dateFormat='MMMM d, yyyy HH:mm'
											/>

											{error && error.body && error.body.end_production_datetime ? (
												<span className='error-inline'>
													{ErrorConstants[error.body.end_production_datetime]}
												</span>
											) : null}
										</label>
									</>
								) : null}
								{start_assembly_datetime !== undefined ? (
									<>
										<label className='grid-item'>
											<span className='input-description'>
												{GeneralConstants['select_assembly_start_time']}
											</span>
											<DatePicker
												selected={startAssemblyDate}
												onChange={(date) => setStartAssemblyDate(date)}
												name='start_assembly_datetime'
												withPortal
												showTimeSelect
												timeFormat='HH:mm'
												timeIntervals={15}
												timeCaption='time'
												dateFormat='MMMM d, yyyy HH:mm'
											/>

											{error && error.body && error.body.start_assembly_datetime ? (
												<span className='error-inline'>
													{ErrorConstants[error.body.start_assembly_datetime]}
												</span>
											) : null}
										</label>
										<label className='grid-item'>
											<span className='input-description'>
												{GeneralConstants['select_assembly_end_time']}
											</span>
											<DatePicker
												selected={endAssemblyDate}
												onChange={(date) => setEndAssemblyDate(date)}
												name='end_assembly_datetime'
												withPortal
												showTimeSelect
												timeFormat='HH:mm'
												timeIntervals={15}
												timeCaption='time'
												dateFormat='MMMM d, yyyy HH:mm'
											/>

											{error && error.body && error.body.end_assembly_datetime ? (
												<span className='error-inline'>
													{ErrorConstants[error.body.end_assembly_datetime]}
												</span>
											) : null}
										</label>
									</>
								) : null}
							</div>
							<div className='btn-container btn-container--padding-clear btn-container--center'>
								{status == 'archived' ? (
									<button className='btn dark' onClick={(ev) => ev.preventDefault()}>
										{GeneralConstants['save']}
									</button>
								) : (
									<button className='btn main-green'>{GeneralConstants['save']}</button>
								)}
							</div>
						</form>
					</div>
					<ReactTooltip />
					<FullCalendar
						initialView='dayGridMonth'
						plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
						weekends={false}
						headerToolbar={{
							left: 'title',
							center: 'dayGridMonth,listWeek listDay',
							right: 'prev,next today'
						}}
						views={{
							listDay: { buttonText: `${GeneralConstants?.list_day}` },
							listWeek: { buttonText: `${GeneralConstants?.list_week}` }
						}}
						allDaySlot
						firstDay={1}
						nowIndicator
						slotLabelFormat={{
							hour: '2-digit',
							minute: '2-digit',
							meridiem: false,
							hour12: false
						}}
						eventTimeFormat={{
							hour: '2-digit',
							minute: '2-digit',
							meridiem: false,
							hour12: false
						}}
						selectable
						locale={nnLocale}
						weekNumbers
						weekText=''
						slotEventOverlap={false}
						events={[
							...calendarProjectAssemblyListData,
							...calendarProjectTransportListData,
							...calendarProjectProductionListData,
							{
								title: `${title} | ${id} ${clientList[client_id]['title']} | ${GeneralConstants['assembly']}`,
								start: `${project_start_assembly_datetime}`,
								end: `${project_end_assembly_datetime}`,
								id,
								classNames: [`${status}`, `${category}`, `${is_knapphus_brand == 1 ? 'knapphus' : ''}`]
							},
							{
								title: `${title} | ${id} ${clientList[client_id]['title']} | ${GeneralConstants['production']}`,
								start: `${project_start_production_datetime}`,
								end: `${project_end_production_datetime}`,
								id,
								classNames: [`${status}`, `${category}`, `${is_knapphus_brand == 1 ? 'knapphus' : ''}`]
							},
							{
								title: `${title} | ${id} ${clientList[client_id]['title']} | ${GeneralConstants['transport']}`,
								start: `${project_start_transport_datetime}`,
								end: `${project_end_transport_datetime}`,
								id,
								classNames: [`${status}`, `${category}`, `${is_knapphus_brand == 1 ? 'knapphus' : ''}`]
							}
						]}
						eventMouseEnter={handleEventPositioned}
					/>
					<a
						href=''
						className='overlay-calendar-data-container-trigger'
						onClick={(ev) => handleCalendarToggle(ev)}
					>
						{GeneralConstants['close']}
					</a>
				</div>
			</div>
		</div>
	);
};

export default ProjectCalendar;
