import * as React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import GeneralConstants from '../../../constants/general.constants';

class LocationSearchInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.id,
			google_address: this.props.google_address || '',
			lat: '',
			lng: '',
			disabled: false,
			typing: true
		};
	}

	handleChange = (google_address) => {
		this.setState({ google_address });
	};

	handleSelect = (google_address) => {
		geocodeByAddress(google_address)
			.then((results) => getLatLng(results[0]))
			.then(({ lat, lng }) =>
				this.setState(
					(prevState) => ({
						...prevState,
						lat,
						lng,
						google_address,
						disabled: true,
						typing: false,
						iteration: this.props.iteration
					}),
					() => this.props.getAddressData(this.state)
				)
			)
			// eslint-disable-next-line no-console
			.catch((error) => console.error('Error', error));
	};

	render() {
		return (
			<PlacesAutocomplete
				value={this.state.google_address}
				onChange={this.handleChange}
				onSelect={this.handleSelect}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
					return (
						<div>
							{this.state.disabled || this.state.id ? (
								<div className='look-like-regular-input'>{this.state.google_address}</div>
							) : (
								<input
									{...getInputProps({
										placeholder: GeneralConstants['search'],
										className: 'location-search-input'
									})}
								/>
							)}

							<div className='autocomplete-dropdown-container'>
								{loading && <span>Loading...</span>}
								{suggestions.map((suggestion) => {
									const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

									const style = suggestion.active
										? { backgroundColor: '#fafafa', cursor: 'pointer' }
										: { backgroundColor: '#ffffff', cursor: 'pointer' };
									return (
										<div
											{...getSuggestionItemProps(suggestion, {
												className,
												style
											})}
										>
											<span>{suggestion.description}</span>
										</div>
									);
								})}
							</div>
						</div>
					);
				}}
			</PlacesAutocomplete>
		);
	}
}

export default LocationSearchInput;
