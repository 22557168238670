import { Checkbox } from 'informed';
import * as React from 'react';

const ProjectFormCheckBox = ({ activeFormData, label, name }) => (
	<label className='checkbox-wrapper'>
		<Checkbox field={name} initialValue={activeFormData} />
		<span className='small'>{label}</span>
	</label>
);

export default ProjectFormCheckBox;
