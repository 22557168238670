import * as React from 'react';

import GeneralConstants from '../../constants/general.constants';

const ErrorMessage = ({ errorID, errorMessage, onSubmit }) => (
	<div className='error-container'>
		<div className='error-container__wrapper'>
			<div className='error-message-round-container'>
				{errorMessage ? (
					<div>
						{errorMessage?.name}
						<br />
						{errorMessage?.time_ago}
					</div>
				) : (
					GeneralConstants[errorID]
				)}
			</div>
			
			{onSubmit && (
				<div className='btn-container btn-container--center'>
					<button className='btn override' type='button'>
						{GeneralConstants['override']}
					</button>
					<a href='/' className='btn reload' onClick={() => window.location.reload()}>
						{GeneralConstants['reload']}
					</a>
				</div>
			)}
		</div>
	</div>
);

export default ErrorMessage;
