import { useFieldState } from 'informed';
import * as React from 'react';

import DemolitionAndServicesData from './demolition-and-services-data/demolition-and-services-data.component';

const DemolitionAndServices = ({
	serviceList,
	servicePersonList,
	serverError,
	serviceTypeAndMaterial,
	showServices,
	handleShowComments,
	group,
	comments
}) => {
	// eslint-disable-next-line camelcase
	const { value: demolition_and_services } = useFieldState('demolition_and_services');

	return (
		<DemolitionAndServicesData
			serviceList={serviceList}
			servicePersonList={servicePersonList}
			serverError={serverError}
			serviceTypeAndMaterial={serviceTypeAndMaterial}
			showServices={showServices}
			demolition_and_services={demolition_and_services}
			handleShowComments={handleShowComments}
			group={group}
			comments={comments}
		/>
	);
};

export default DemolitionAndServices;
